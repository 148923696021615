import { RRule } from 'rrule'
import moment from 'moment'

function convertDateToUTC(date) {
  if (!date) return null

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

/**
 *
 * Note: RRule library stores options in arrays
 * freq = [RRule.YEARLY, RRule.MONTHLY, RRule.WEEKLY, RRule.DAILY ]
 * byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU]
 *
 * those get returned as array index - ex: freq of RRule.DAILY returns 3
 *
 */
const rruleParser = {
  parseRRuleToVCheckFields(rruleString) {
    if (!rruleString) {
      return null
    }

    // object to return (sets all fields except endTime, which is handled by component)
    const vcheckFields = {
      startDate: null,
      startTime: null,
      repeat: null, // Does Not Repeat, Daily, Custom
      repeatEvery: null,
      frequency: null, // Day(s), Week(s), Month(s)
      endsOn: null, // Never, Specific Date, Number of Occurrences
      endDate: null,
      occurrences: null,
      weekdays: [], // ['M', 'TU', 'W', 'TH', 'F', 'SA', 'SU']
    }

    const rrule = RRule.fromString(rruleString)
    const rruleObject = rrule.origOptions

    /**
     * Set startDate and startTime
     *
     * `rruleObject.dtstart` comes back in the user's timezone. If we store, say, 8 AM, and access
     * the date from the RRule here while in Denver, it comes back as 2:00 AM MT, which is not what
     * we want. We must change the timezone of the date back while maintaining the time.
     *
     * The full example flow:
     * - Store 8 AM as our "naive" time (the API treats it as naive, but we store as UTC)
     * - Retrieve the datetime from the built RRule in our current timezone -> 2 AM MT
     * - Convert timezone to UTC -> 8 AM UTC
     * - Display the converted time naively -> 08:00
     */
    vcheckFields.startDate = moment(convertDateToUTC(rruleObject.dtstart)).format('YYYY-MM-DD')
    vcheckFields.startTime = moment(convertDateToUTC(rruleObject.dtstart)).format('HH:mm')

    // check for specific Does Not Repeat form and return
    if (rruleString.split('RRULE:').pop() === 'FREQ=DAILY;COUNT=1') {
      vcheckFields.repeat = 'Does Not Repeat'
      return vcheckFields
    }

    // if not Does Not Repeat (Daily or Custom), break down in to field values

    // check for Daily repeat
    if (rruleObject.freq === 3 && (!rruleObject.interval || rruleObject.interval === 1)) {
      vcheckFields.repeat = 'Daily'

      // set end behaviour
      vcheckFields.endsOn = this.calculateEndsOn(rruleObject)
      if (vcheckFields.endsOn === 'Specific Date') {
        vcheckFields.endDate = moment(rruleObject.until).format('YYYY-MM-DD')
      } else if (vcheckFields.endsOn === 'Number of Occurrences') {
        vcheckFields.occurrences = rruleObject.count
      }
      return vcheckFields
    }

    // else, Custom Repeat
    vcheckFields.repeat = 'Custom'

    // repeat every
    vcheckFields.repeatEvery = rruleObject.interval || 1

    // frequency (Day(s), Week(s), Month(s))
    vcheckFields.frequency = this.calculateFrequency(rruleObject)
    if (vcheckFields.frequency === 'Week(s)') {
      // weekdays ([M, Tu, W, Th, F, Sa, Su])
      vcheckFields.weekdays = this.calculateWeekdays(rruleObject.byweekday)
    }
    // set end behaviour
    vcheckFields.endsOn = this.calculateEndsOn(rruleObject)
    if (vcheckFields.endsOn === 'Specific Date') {
      vcheckFields.endDate = moment(rruleObject.until).format('YYYY-MM-DD')
    } else if (vcheckFields.endsOn === 'Number of Occurrences') {
      vcheckFields.occurrences = rruleObject.count
    }
    return vcheckFields
  },

  calculateEndsOn(rruleObject) {
    if (!rruleObject) {
      return null
    }

    if (rruleObject.until) {
      return 'Specific Date'
    }
    if (rruleObject.count) {
      return 'Number of Occurrences'
    }
    return 'Never'
  },

  calculateFrequency(rruleObject) {
    if (!rruleObject) {
      return null
    }
    if (rruleObject.freq === 1) {
      return 'Month(s)'
    }
    if (rruleObject.freq === 2) {
      return 'Week(s)'
    }
    return 'Day(s)'
  },

  calculateWeekdays(weekdayArray) {
    if (!weekdayArray) {
      return null
    }
    const weekdays = []
    weekdayArray.forEach(day => {
      if (day.weekday === 0) {
        weekdays.push('M')
      }
      if (day.weekday === 1) {
        weekdays.push('Tu')
      }
      if (day.weekday === 2) {
        weekdays.push('W')
      }
      if (day.weekday === 3) {
        weekdays.push('Th')
      }
      if (day.weekday === 4) {
        weekdays.push('F')
      }
      if (day.weekday === 5) {
        weekdays.push('Sa')
      }
      if (day.weekday === 6) {
        weekdays.push('Su')
      }
    })

    return weekdays
  },
}

export default rruleParser
