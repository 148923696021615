/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Box } from '../Box'

// Stores
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils, Services & Messages
import colors from '../../utils/colors'

/**
 * Unread Message Banner
 *
 * This is a component that will display the current amount of unread
 * chat messages for a specific primary agent
 *
 */
const UnreadMessagesBanner = ({ currentLocation }) => {
  const { getAgentUnreadChatMessageCount, distributorClientSelectedAgencyId } = useContext(
    ClientWizardStoreContext,
  )
  const { user, isDistributor } = useContext(UserStoreContext)

  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  const whitelistedPages = ['home', 'clients']

  useEffect(() => {
    const getCount = async () => {
      const count = await getAgentUnreadChatMessageCount(
        !isDistributor ? user.agencyId : distributorClientSelectedAgencyId,
      )
      setUnreadMessageCount(count)
    }
    const path = currentLocation.pathname.split('/')[1]
    if (currentLocation.pathname.includes('message-log')) {
      setTimeout(() => {
        getCount()
      }, 1000)
      return
    }
    if (whitelistedPages.includes(path)) {
      getCount()
    } else {
      setUnreadMessageCount(0)
    }
  }, [currentLocation])

  return (
    <>
      {unreadMessageCount ? (
        <>
          <StyledBanner
            style={{
              textAlign: 'center',
              backgroundColor: colors.gold,
              color: colors.darkerGreyBlue,
              position: 'absolute',
              width: 'calc(100% - 280px)',
              zIndex: 100,
              height: '65px',
            }}
            pad={{
              vertical: 'medium',
              horizontal: 'large',
            }}
            direction="row"
            justify="center"
            align="center"
          >
            <span>
              YOU HAVE {`${unreadMessageCount}`} UN-VIEWED CLIENT{' '}
              {unreadMessageCount === 1 ? 'MESSAGE' : 'MESSAGES'}. VISIT THE{' '}
              <a href="/clients">CLIENTS</a> PAGE TO SEE WHO YOU HAVE{' '}
              {unreadMessageCount === 1 ? 'AN UN-VIEWED MESSAGE' : 'UN-VIEWED MESSAGES'} FROM.
            </span>
          </StyledBanner>
          <Box pad="medium" />
        </>
      ) : null}
    </>
  )
}

const StyledBanner = styled(Box)``

export default UnreadMessagesBanner
