/* eslint-disable import/no-cycle */
import { observable, action } from 'mobx'
import { createContext } from 'react'

import { BaseStore } from './BaseStore'
import {
  getClientsByAgency as getClientsByAgencyService,
  getAgentsByAgency as getAgentsByAgencyService,
  postAgentToAgency as postAgentToAgencyService,
  updateAgentInAgency as updateAgentInAgencyService,
  getAgentHomeEvents as getAgentHomeEventsService,
  updateClientVCheckEventById as updateClientVCheckEventByIdService,
  getAgencyById as getAgencyByIdService,
  updateClientInAgency as updateClientInAgencyService,
  getSupervisionTypes as getSupervisionTypesService,
  getTimezones as getTimezonesService,
  getClientStatasticDetailByAgencyId as getClientStatasticDetailByAgencyIdService,
} from '../services/agency.service'

const INITIAL_STATE = {
  clients: [],
  agents: [],
  distributors: [],
  supervisionTypes: [],
  homeEvents: [],
  clientStatus: [],
}

export class AgencyStore extends BaseStore {
  @observable clients

  @observable agents

  @observable supervisionTypes

  @observable homeEvents

  @observable clientStatus

  @action.bound
  async getAgencyById(agencyId, setError, setLoading) {
    const data = await getAgencyByIdService(agencyId, setError, setLoading)

    return data
  }

  @action.bound
  async getClientsByAgency(
    agencyId,
    setError,
    setLoading,
    page = 1,
    limit = -1,
    searchText = '',
    status = '',
    agentId = '',
    customizeLoading = false,
  ) {
    const response = await getClientsByAgencyService(
      agencyId,
      setError,
      setLoading,
      page,
      limit,
      searchText,
      status,
      agentId,
      customizeLoading,
    )
    if (response) {
      this.clients = response.results
    }
    return response
  }

  @action.bound
  async getClientStatasticDetailByAgencyId(
    agencyId,
    setError,
    setLoading,
    limit = -1,
    currentPage = 1,
    searchText = '',
  ) {
    const response = await getClientStatasticDetailByAgencyIdService(
      agencyId,
      setError,
      setLoading,
      limit,
      currentPage,
      searchText,
    )
    if (response) {
      this.clientStatus = response
    }
    return response
  }

  @action.bound
  async getAgentsByAgency(
    agencyId,
    setError,
    setLoading,
    currentPage = 1,
    rowsPerPage = -1,
    status = '',
    queryFilter = '',
    queryparameter,
  ) {
    const response = await getAgentsByAgencyService(
      agencyId,
      setError,
      setLoading,
      currentPage,
      rowsPerPage,
      status,
      queryFilter,
      queryparameter,
    )
    if (response) {
      this.agents = response.results
    }
    return response
  }

  @action.bound
  async getAgentHomeEvents(agencyId, agentId, setError, setLoading, page = 1, limit = -1) {
    const events = await getAgentHomeEventsService(
      agencyId,
      agentId,
      setError,
      setLoading,
      page,
      limit,
    )
    if (events) {
      this.homeEvents = events.results
    }
    return events
  }

  @action.bound
  async updateHomeEventByClientId(
    agencyId,
    clientId,
    vcheckId,
    vcheckReview,
    setError,
    setLoading,
  ) {
    const updatedVCheck = await updateClientVCheckEventByIdService(
      agencyId,
      clientId,
      vcheckId,
      vcheckReview,
      setError,
      setLoading,
    )
    if (updatedVCheck) {
      return updatedVCheck
    }
    return null
  }

  @action.bound
  async postAgentToAgency(agencyId, agent, setLoading, setError) {
    const addedAgent = await postAgentToAgencyService(agencyId, agent, setLoading, setError)
    if (addedAgent) {
      this.agents = [...this.agents, addedAgent]
      return false // sets error bool to false
    }
    return true // sets error bool to true if no addedAgent
  }

  @action.bound
  async updateAgentInAgency(agencyId, agent, setLoading, setError) {
    const updatedAgent = await updateAgentInAgencyService(agencyId, agent, setLoading, setError)
    if (updatedAgent) {
      const filteredAgents = this.agents.filter(a => a.id !== agent.id)
      this.agents = [...filteredAgents, updatedAgent]
      return false // sets error bool to false
    }
    return true // sets error bool to true if no updatedAgent
  }

  // EXTRAS

  @action.bound
  async sendOnDemandVCheckToClient(agencyId, clientId, setLoading, setError, setSuccess) {
    const patchBody = {
      id: clientId,
      send_on_demand_vcheck: true,
    }
    const requestSuccess = await updateClientInAgencyService(
      agencyId,
      patchBody,
      setLoading,
      setError,
    )
    if (requestSuccess) {
      setSuccess('VCheck request sent.')
    }
  }

  @action.bound
  async sendInstallLinkToClient(
    agencyId,
    clientId,
    setLoading,
    setError,
    sendInstallLink,
    setSuccess,
  ) {
    const patchBody = {
      id: clientId,
      send_install_link: sendInstallLink,
    }
    const requestSuccess = await updateClientInAgencyService(
      agencyId,
      patchBody,
      setLoading,
      setError,
    )
    if (requestSuccess && setSuccess) {
      setSuccess('Installation link sent via text message.')
    }
    return requestSuccess
  }

  @action.bound
  async activateClient(agencyId, clientId, isEditing, setLoading, setError) {
    const patchBody = {
      id: clientId,
      activate: true,
    }
    if (isEditing) {
      delete patchBody.activate
    }
    return updateClientInAgencyService(agencyId, patchBody, setLoading, setError)
  }

  @action.bound
  async deactivateClient(agencyId, clientId, setLoading, setError) {
    const patchBody = {
      id: clientId,
      deactivate: true,
    }
    return updateClientInAgencyService(agencyId, patchBody, setLoading, setError)
  }

  @action.bound
  async reactivateClient(agencyId, clientId, setLoading, setError) {
    const patchBody = {
      id: clientId,
      reactivate: true,
    }
    return updateClientInAgencyService(agencyId, patchBody, setLoading, setError)
  }

  @action.bound
  async getSupervisionTypes(setError, setLoading) {
    const supervisionTypeObjects = await getSupervisionTypesService(setError, setLoading)
    // convert from array of objects to array of strings
    const supervisionTypeStrings = []
    supervisionTypeObjects.forEach(type => {
      supervisionTypeStrings.push(Object.values(type)[0])
    })
    this.supervisionTypes = supervisionTypeStrings
    return supervisionTypeStrings
  }

  @action.bound
  async getTimezones(setError, setLoading) {
    const timezonesArray = await getTimezonesService(setError, setLoading)
    return timezonesArray
  }

  formatAddress(address) {
    if (address.address_1 && address.city && address.state && address.zip_code) {
      const formattedAddress = address.address_1.concat(
        ', ',
        address.city,
        ', ',
        address.state,
        ', ',
        address.country,
        ' ',
        address.zip_code,
      )
      return formattedAddress
    }
    return null
  }
}

export const agency = new AgencyStore(INITIAL_STATE)
export const AgencyStoreContext = createContext(agency)
