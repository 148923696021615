/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState, useReducer } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import { isEmail } from 'validator'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
// Components
import styled from 'styled-components'
import { Box } from '../Box'
import { ChangePasswordSidebar } from '../ChangePasswordSidebar'
import { Checkbox } from '../Checkbox'
import { FormField } from '../Form'
import { Header } from '../Header'
import { Message } from '../Message'
import { Select } from '../Select'
import { Text } from '../Text'
import { TextInput } from '../TextInput'
import { WizardSidebar } from '../WizardSidebar'

// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { UserStoreContext } from '../../stores/UserStore'
import { DistributorStoreContext } from '../../stores/DistributorStore'

// Utils, Services & Messages
import messages from './ProfileSidebar.messages'
import useFlashMessage from '../../hooks/FlashMessage'

const sidebarInitialState = {
  first_name: '',
  last_name: '',
  title: '',
  email: '',
  phone: '',
}

const roleOptions = ['User', 'Admin']

/**
 * ProfileSidebar
 *
 * Sidebar component with its own url so it can be displayed from anywhere in the app.
 * Returns WizardSidebar component after get request to retrieve user data
 *
 * Displays user profile as sidebar with same content as sidebar on AgentPage
 * - first name
 * - last name
 * - title
 * - role type
 * - phone
 * - email
 *
 */
const ProfileSidebar = observer(
  withRouter(({ history, intl }) => {
    const agencyStore = useContext(AgencyStoreContext)
    const distributorStore = useContext(DistributorStoreContext)
    const { user, isDistributor } = useContext(UserStoreContext)

    const { message: error, showMessage: showError } = useFlashMessage(null)
    const [loading, setLoading] = useState(null)

    const [activeAgent, setActiveAgent] = useState({})
    const [displayPhone, setDisplayPhone] = useState()
    const [updateSuccess, setUpdateSuccess] = useState(false)

    useEffect(() => {
      async function setData() {
        if (!history.location.state) {
          history.replace({
            state: {
              background: {
                pathname: '/clients',
              },
            },
          })
        }
        if (agencyStore.agents.length < 1) {
          await agencyStore.getAgentsByAgency(user.agencyId, showError, setLoading)
        }
        agencyStore.agents.forEach(agent => {
          if (agent.user.id === user.id) {
            setActiveAgent(agent)
            setDisplayPhone(agent.user.phone)
            dispatch({
              type: 'update',
              data: {
                id: agent.id,
                userId: agent.user.id,
                first_name: agent.user.first_name,
                last_name: agent.user.last_name,
                title: agent.user.title,
                role_type: agent.is_admin ? 'Admin' : 'User',
                email: agent.user.email,
                phone: agent.user.phone || '',
                zone_sms: agent.notifications[0] ? agent.notifications[0].settings.sms : false,
                zone_email: agent.notifications[0] ? agent.notifications[0].settings.email : false,
                vcheck_sms: agent.notifications[1] ? agent.notifications[1].settings.sms : false,
                vcheck_email: agent.notifications[1]
                  ? agent.notifications[1].settings.email
                  : false,
                app_sms: agent.notifications[2] ? agent.notifications[2].settings.sms : false,
                app_email: agent.notifications[2] ? agent.notifications[2].settings.email : false,

                completed_vcheck_sms: agent.notifications[3]
                  ? agent.notifications[3].settings.sms
                  : false,
                completed_vcheck_email: agent.notifications[3]
                  ? agent.notifications[3].settings.email
                  : false,
                no_activity_sms: agent.notifications[4]
                  ? agent.notifications[4].settings.sms
                  : false,
                no_activity_email: agent.notifications[4]
                  ? agent.notifications[4].settings.email
                  : false,
                facial_recognition_sms: false,
                facial_recognition_email: agent.notifications[5] ? agent.notifications[5].settings.email : false,   
              },
            })
          }
        })
      }
      async function setDataforUser() {
        const userDetails = await distributorStore.getDistributorListByDistributorId(
          user.distributorId,
          showError,
          setLoading,
        )

        if (userDetails && userDetails.length > 0) {
          userDetails.forEach(item => {
            if (item.user.id === user.id) {
              setDisplayPhone(item.user.phone)

              dispatch({
                type: 'update',
                data: {
                  id: item.user.distributor_id,
                  userId: item.id,
                  first_name: item.user.first_name,
                  last_name: item.user.last_name,
                  title: item.user.title,
                  email: item.user.email,
                  phone: item.user.phone || '',
                  is_admin: item.user.is_agent_admin,
                },
              })
            }
          })
        }
      }
      if (isDistributor) {
        setDataforUser()
      } else {
        setData()
      }
    }, [updateSuccess])

    const [sidebarState, dispatch] = useReducer(sidebarReducer, sidebarInitialState)
    const [showChangePassword, setShowChangePassword] = useState(false)

    function sidebarReducer(state, action) {
      if (action.type !== 'reset') {
        if (action.type === 'update') {
          return {
            ...state,
            ...action.data,
          }
        }
        return {
          ...state,
          [action.fieldName]: action.data,
        }
      }

      return sidebarInitialState
    }

    // Sidebar functions
    const onClose = () => {
      // go back to previous/background page
      if (history.action === 'REPLACE') {
        history.push({
          pathname: '/clients',
        })
      } else {
        history.goBack()
      }
    }

    const onPasswordClose = () => {
      setShowChangePassword(false)
    }

    const isFormValid =
      !!sidebarState.first_name &&
      !!sidebarState.last_name &&
      !!sidebarState.email &&
      !!sidebarState.phone

    const stringValidation = {
      regexp: /[A-Za-z]/,
      message: 'Please enter a valid name',
    }

    // Sidebar submit function
    const onSubmit = async () => {
      // build notification settings
      const notificationSettings = [
        {
          type: 'zone',
          settings: { sms: sidebarState.zone_sms, email: sidebarState.zone_email },
          description: 'Zone and Location Alerts',
        },
        {
          type: 'vcheck',
          settings: { sms: sidebarState.vcheck_sms, email: sidebarState.vcheck_email },
          description: 'Missed VChecks',
        },
        {
          type: 'app',
          settings: { sms: sidebarState.app_sms, email: sidebarState.app_email },
          description: 'Application Errors',
        },
        {
          type: 'vcheck_completed',
          settings: {
            sms: sidebarState.completed_vcheck_sms,
            email: sidebarState.completed_vcheck_email,
          },
          description: 'Completed VChecks',
        },
        {
          type: 'no_activity',
          settings: {
            sms: sidebarState.no_activity_sms,
            email: sidebarState.no_activity_email,
          },
          description: 'No GPS Alerts',
        },
        {
          type: 'facial_recognition',
          settings: {
            sms: false,
            email: sidebarState.facial_recognition_email,
          },
          description: 'Facial Recognition',
        },
      ]

      // format phone
      const formattedPhone = sidebarState.phone.replace(/\s/g, '')

      // build agent object
      const agentToPost = {
        id: sidebarState.id,
        user: {
          id: sidebarState.userId,
          first_name: sidebarState.first_name,
          last_name: sidebarState.last_name,
          title: sidebarState.title,
          email: sidebarState.email,
          phone: formattedPhone,
        },
        is_admin: sidebarState.role_type === 'Admin',
        notifications: notificationSettings,
      }

      const userToPost = {
        user: {
          first_name: sidebarState.first_name,
          last_name: sidebarState.last_name,
          title: sidebarState.title,
          email: sidebarState.email,
          phone: formattedPhone,
        },
        is_admin: sidebarState.is_admin,
      }

      // check if agent is existing, if so PATCH instead of POST
      let requestError
      if (sidebarState.id) {
        // send PATCH
        requestError = isDistributor
          ? await distributorStore.updateUserProfileByDistributorId(
            user.distributorId,
            userToPost,
            sidebarState.userId,
            setLoading,
            showError,
          )
          : await agencyStore.updateAgentInAgency(
            user.agencyId,
            agentToPost,
            setLoading,
            showError,
          )
      } else {
        // send POST
        requestError = await agencyStore.postAgentToAgency(
          user.agencyId,
          agentToPost,
          setLoading,
          showError,
        )
      }

      // will be false if no caught errors above
      if (!requestError) {
        setUpdateSuccess(!updateSuccess)
        onClose()
      }
    }

    // Build Sidebar header & content
    const sideBarHeader = <FormattedMessage {...messages.sidebarHeader} />

    const sidebarContent = (
      <Box flex={false} direction="column" justify="between">
        {user.id === sidebarState.userId && (
          <Box direction="row" justify="end">
            <Text
              color="brand"
              onClick={() => setShowChangePassword(true)}
              underline
              size="xsmall"
              weight="bold"
            >
              Change your password
            </Text>
          </Box>
        )}

        <FormField
          plain
          component={TextInput}
          label={intl.formatMessage(messages.agentFirstNameLabel)}
          name="first_name"
          id="first_name"
          value={{ value: sidebarState.first_name }}
          onChange={e => dispatch({ fieldName: 'first_name', data: e.target.value })}
          validate={stringValidation}
          required
        />

        <FormField
          plain
          component={TextInput}
          label={intl.formatMessage(messages.agentLastNameLabel)}
          name="last_name"
          id="last_name"
          value={{ value: sidebarState.last_name }}
          onChange={e => dispatch({ fieldName: 'last_name', data: e.target.value })}
          validate={stringValidation}
          required
        />

        <FormField
          plain
          component={TextInput}
          label={intl.formatMessage(messages.agentTitleLabel)}
          name="title"
          id="title"
          value={{ value: sidebarState.title }}
          onChange={e => dispatch({ fieldName: 'title', data: e.target.value })}
        />

        {!isDistributor && (
          <FormField
            required
            name="role_type"
            htmlFor="role_type"
            label={intl.formatMessage(messages.agentRoleLabel)}
            onChange={e => dispatch({ fieldName: 'role_type', data: e.target.value })}
            value={{ value: sidebarState.role_type }}
          >
            <Select
              disabled={user.id === sidebarState.userId}
              plain
              size="small"
              name="role_type"
              id="role_type"
              options={roleOptions}
              value={sidebarState.role_type}
              onChange={e => dispatch({ fieldName: 'role_type', data: e.option })}
            ></Select>
          </FormField>
        )}

        <FormField
          label={intl.formatMessage(messages.agentPhoneLabel)}
          name="phone"
          id="phone"
          onChange={e => dispatch({ fieldName: 'phone', data: e.target.value })}
          validate={[
            () => {
              if (isValidPhoneNumber(displayPhone)) return true
              return 'Please enter a valid phone number'
            },
          ]}
          value={{ value: displayPhone }}
        >
          <StyledPhoneInput
            name="phone"
            onChange={setDisplayPhone}
            value={displayPhone}
            international
            defaultCountry="US"
          />
        </FormField>

        <FormField
          plain
          component={TextInput}
          label={intl.formatMessage(messages.agentEmailLabel)}
          name="email"
          id="email"
          value={{ value: sidebarState.email }}
          onChange={e => dispatch({ fieldName: 'email', data: e.target.value })}
          required
          validate={[
            () => {
              if (sidebarState.email && !isEmail(sidebarState.email))
                return 'Please enter a valid email address'
              return undefined
            },
          ]}
        />

        {user.id === sidebarState.userId && !isDistributor && (
          <Box direction="column" pad={{ vertical: 'medium' }}>
            <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
              {' '}
              <FormattedMessage {...messages.notificationLabel} />
            </Header>

            <Box direction="row" justify="end" align="center" gap="xsmall">
              <Text size="xsmall">
                <FormattedMessage {...messages.smsLabel} />
              </Text>

              <Text size="xsmall">
                <FormattedMessage {...messages.emailLabel} />
              </Text>
            </Box>

            <Box direction="row" justify="between" align="center" pad={{ vertical: 'xsmall' }}>
              <Text size="xsmall">
                <FormattedMessage {...messages.missedVChecksLabel} />
              </Text>

              <Box direction="row" gap="small">
                <Checkbox
                  id="vcheck_sms"
                  checked={sidebarState.vcheck_sms === true}
                  onChange={e => dispatch({ fieldName: 'vcheck_sms', data: e.target.checked })}
                ></Checkbox>

                <Checkbox
                  id="vcheck_email"
                  checked={sidebarState.vcheck_email === true}
                  onChange={e => dispatch({ fieldName: 'vcheck_email', data: e.target.checked })}
                ></Checkbox>
              </Box>
            </Box>

            <Box direction="row" justify="between" align="center" pad={{ vertical: 'xsmall' }}>
              <Text size="xsmall">
                <FormattedMessage {...messages.completedVChecksLabel} />
              </Text>

              <Box direction="row" gap="small">
                <Checkbox
                  id="completed_vcheck_sms"
                  checked={sidebarState.completed_vcheck_sms === true}
                  onChange={e =>
                    dispatch({ fieldName: 'completed_vcheck_sms', data: e.target.checked })
                  }
                ></Checkbox>

                <Checkbox
                  id="completed_vcheck_email"
                  checked={sidebarState.completed_vcheck_email === true}
                  onChange={e =>
                    dispatch({ fieldName: 'completed_vcheck_email', data: e.target.checked })
                  }
                ></Checkbox>
              </Box>
            </Box>

            <Box direction="row" justify="between" align="center" pad={{ vertical: 'xsmall' }}>
              <Text size="xsmall">
                <FormattedMessage {...messages.zoneAlertLabel} />
              </Text>

              <Box direction="row" gap="small">
                <Checkbox
                  id="zone_sms"
                  checked={sidebarState.zone_sms === true}
                  onChange={e => dispatch({ fieldName: 'zone_sms', data: e.target.checked })}
                ></Checkbox>

                <Checkbox
                  id="zone_email"
                  checked={sidebarState.zone_email === true}
                  onChange={e => dispatch({ fieldName: 'zone_email', data: e.target.checked })}
                ></Checkbox>
              </Box>
            </Box>

            <Box direction="row" justify="between" align="center" pad={{ vertical: 'xsmall' }}>
              <Text size="xsmall">
                <FormattedMessage {...messages.appErrorLabel} />
              </Text>

              <Box direction="row" gap="small">
                <Checkbox
                  id="app_sms"
                  checked={sidebarState.app_sms === true}
                  onChange={e => dispatch({ fieldName: 'app_sms', data: e.target.checked })}
                ></Checkbox>

                <Checkbox
                  id="app_email"
                  checked={sidebarState.app_email === true}
                  onChange={e => dispatch({ fieldName: 'app_email', data: e.target.checked })}
                ></Checkbox>
              </Box>
            </Box>

            <Box direction="row" justify="between" align="center" pad={{ vertical: 'xsmall' }}>
              <Text size="xsmall">
                <FormattedMessage {...messages.noActivityLabel} />
              </Text>

              <Box direction="row" gap="small">
                <Checkbox
                  id="no_activity_sms"
                  checked={sidebarState.no_activity_sms === true}
                  onChange={e =>
                    dispatch({ fieldName: 'no_activity_sms', data: e.target.checked })
                  }
                ></Checkbox>

                <Checkbox
                  id="no_activity_email"
                  checked={sidebarState.no_activity_email === true}
                  onChange={e =>
                    dispatch({ fieldName: 'no_activity_email', data: e.target.checked })
                  }
                ></Checkbox>
              </Box>
            </Box>
            <Box direction="row" justify="between" align="center" pad={{ vertical: 'xsmall' }}>
              <Text size="xsmall">
                <FormattedMessage {...messages.facialNotification} />
              </Text>

              <Box direction="row" gap="small">
                {/* <Checkbox
                  id="no_activity_sms"
                  checked={sidebarState.no_activity_sms === true}
                  onChange={e =>
                    dispatch({ fieldName: 'no_activity_sms', data: e.target.checked })
                  }
                ></Checkbox> */}

                <Checkbox
                  id="facial_recognition_email"
                  checked={sidebarState.facial_recognition_email === true}
                  onChange={e =>
                    dispatch({ fieldName: 'facial_recognition_email', data: e.target.checked })
                  }
                ></Checkbox>
              </Box>
            </Box>
          </Box>
        )}

        <Box>{error && <Message message={error} isError />}</Box>

        {!isDistributor && (
          <Box pad={{ vertical: 'small' }}>
            <Text size="small" weight="bold">
              {intl.formatMessage(messages.noteDescirption)}
            </Text>
          </Box>
        )}
      </Box>
    )

    if (!isDistributor) {
      if (!activeAgent.user) {
        return (
          <Header mini level="5" margin="medium">
            <FormattedMessage {...messages.loading} />
          </Header>
        )
      }
    }

    return (
      <>
        {/* Profile (Primary) */}
        <WizardSidebar
          disabled={loading}
          onClose={onClose}
          isFormValid={isFormValid}
          onSubmit={onSubmit}
          header={sideBarHeader}
          content={sidebarContent}
        />

        {/* Password (Secondary) */}
        {showChangePassword && <ChangePasswordSidebar onPasswordClose={onPasswordClose} />}
      </>
    )
  }),
)

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputCountryIcon {
    margin-left: 10px;
  }
  input {
    border-radius: 10px;
    border-width: 0;
    color: rgb(60, 111, 114);
  }
`

ProfileSidebar.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ProfileSidebar)
