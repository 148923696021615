/* eslint-disable react/prefer-stateless-function */
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'

import FileSaver from 'file-saver'
import { Add, CircleInformation, FormDown, FormUp, Search, Send } from 'grommet-icons'
import XLSX from 'xlsx'
import { map as _map, throttle as _throttle, findIndex } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import 'moment-timezone'

// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { ClientAvatar } from 'components/ClientAvatar'
import { Collapsible } from 'components/Collapsible'
import { DateRangePicker } from 'components/DateRangePicker'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Link } from 'components/Link'
import { Message } from 'components/Message'
import { MessageLogTable } from 'components/MessageLogTable'
import { Text } from 'components/Text'
import { ScheduledChatMessageTable } from 'components/ScheduledChatMessageTable'
import { ChatMessageScheduler } from 'components/ChatMessageScheduler'
import { WizardSidebar } from 'components/WizardSidebar'

import jsPDF from 'jspdf'
import { DropButton } from 'grommet'

// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils, Services & Messages
import messages from './ClientMessageLogPage.messages'
import rruleBuilder from '../../utils/rruleBuilder'
import useFlashMessage from '../../hooks/FlashMessage'
import 'jspdf-autotable'

// Styles
import colors from '../../utils/colors'
import { TextArea } from '../../components/TextArea'
import { FormField } from '../../components/Form'
import { TextInput } from '../../components/TextInput'
import { Notification } from '../../components/Notification'
// import { getScheduledChatMessages } from '../../services/agency.service'

const DOWNLOAD_STATES = {
  idle: 0,
  downloading: 1,
  downloaded: 2,
}

const initialDateRangeState = {
  startDate: moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD'),
  startTime: '00:00',
  endDate: moment().format('YYYY-MM-DD'),
  endTime: '23:59',
}

let perPageCount = 50
let currentPage = 1
const chatMessageSchedulePerPageCount = 5
let chatMessageScheduleCurrentPage = 1

/**
 *
 * ClientMessageLogPage
 *
 * This container holds all components needed for the Client Message Log Page
 * The user navigates to this page when they click on the "Messages" button
 * on the Client Summary Page.
 *
 *
 * Timezone Notes:
 *  - created datetime (passed to table) is received as utc and displayed
 *    to user in client's timezone
 *  - date filters are displayed to the user as timezone naive, but are
 *    converted to utc relative to clients timezone so that filtering
 *    behaves as expected
 *
 */
const ClientMessageLogPage = ({ intl }) => {
  const { getAgencyById } = useContext(AgencyStoreContext)
  const {
    getClientById,
    getAllClientChatMessagesById,
    getAllClientScheduledChatMessagesById,
    postOnDemandChatMessageToClient,
    updateScheduledChatMessageById,
    postScheduledChatMessageToClient,
    deleteScheduledChatMessageById,
    distributorClientSelectedAgencyId,
    clientPrimaryAgent,
  } = useContext(ClientWizardStoreContext)
  const { user, isDistributor } = useContext(UserStoreContext)
  const ClientWizardAvatarURL = useContext(ClientWizardStoreContext)

  const [pageLoadError, showPageLoadError] = useState()
  const [pageLoading, setPageLoading] = useState()
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [loading, setLoading] = useState(null)

  const [client, setClient] = useReducer(
    (state, updatedField) => ({ ...state, ...updatedField }),
    {},
  )
  const [clientChatMessages, setClientChatMessages] = useState([])
  const [agencyName, setAgencyName] = useState('Agency')
  const [downloadState, setDownloadState] = useState(DOWNLOAD_STATES.idle)

  const [dateRange, setDateRange] = useReducer(dateRangeReducer, initialDateRangeState)
  const [filteredStartDate, setFilteredStartDate] = useState()
  const [filteredEndDate, setFilteredEndDate] = useState()

  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false)
  const [dateFilterApplied, setDateFilterApplied] = useState(false)

  const [isInfoContentVisible, setIsInfoContentVisible] = useState(false)
  const [cannedOnDemandMessagePickerOpen, setCannedOnDemandMessagePickerOpen] = useState(false)
  const [
    questionnaireOnDemandMessagePickerOpen,
    setQuestionnaireOnDemandMessagePickerOpen,
  ] = useState(false)

  const [cannedScheduledMessagePickerOpen, setCannedScheduledMessagePickerOpen] = useState(false)
  const [
    questionnaireScheduledMessagePickerOpen,
    setQuestionnaireScheduledMessagePickerOpen,
  ] = useState(false)

  // boolean for showing sidebar
  const [showSidebar, setShowSidebar] = useState()

  const [onDemandChatMessage, setOnDemandChatMessage] = useState('')

  // list of all scheduled chat messages formatted to post to client
  const [scheduledChatMessages, setScheduledChatMessages] = useState()

  const { message: successMessage, showMessage: showSuccessMessage } = useFlashMessage(null)

  const [agencyId, setAgencyId] = useState(
    isDistributor ? distributorClientSelectedAgencyId : user.agencyId,
  )

  const [selectedAgent, setSelectedAgent] = useState(
    isDistributor ? clientPrimaryAgent.user.id : user.id,
  )

  // state of sidebar form for single chat message - cleared on "Save"
  const scheduledChatMessageInitialState = {
    name: '',
    message_content: '',
    startDate: moment(new Date())
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    startTime: '08:00',
    endTime: '08:15',
    repeat: 'Does Not Repeat',
    repeatEvery: '1',
    frequency: 'Week(s)',
    endsOn: 'Never',
    endDate: undefined,
    occurrences: '',
    weekdays: [],
  }
  const [scheduledState, dispatch] = useReducer(
    chatMessageReducer,
    scheduledChatMessageInitialState,
  )

  function chatMessageReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return scheduledChatMessageInitialState
  }

  // Sidebar open/close functions
  const onClose = () => setShowSidebar(false)
  const openModal = () => {
    setShowSidebar(true)
  }

  // Function to pass into ScheduledChatMessageTable
  // that will open and populate sidebar for editing
  const editInSidebar = chatMessage => {
    dispatch({
      type: 'update',
      data: {
        id: chatMessage.id,
        exists: true,
        name: chatMessage.name,
        message_content: chatMessage.message_content,
        startDate: chatMessage.startDate,
        startTime: chatMessage.startTime,
        endTime: chatMessage.endTime,
        repeat: chatMessage.repeat || scheduledChatMessageInitialState.repeat,
        repeatEvery: chatMessage.repeatEvery || scheduledChatMessageInitialState.repeatEvery,
        frequency: chatMessage.frequency || scheduledChatMessageInitialState.frequency,
        endsOn: chatMessage.endsOn || scheduledChatMessageInitialState.endsOn,
        endDate: chatMessage.endDate,
        occurrences: chatMessage.occurrences || scheduledChatMessageInitialState.occurrences,
        weekdays: chatMessage.weekdays || scheduledChatMessageInitialState.weekdays,
      },
    })

    openModal()
  }

  // Sidebar submit function
  const onSubmit = async () => {
    let requestResponse
    if (scheduledState.id) {
      // update existing chat message
      const updatedChatMessage = {
        id: scheduledState.id,
        name: scheduledState.name,
        message_content: scheduledState.message_content,
        schedule: rruleBuilder.rruleToString(scheduledState),
      }

      // Update the schedule in the API
      requestResponse = await updateScheduledChatMessageById(
        agencyId,
        client.id,
        updatedChatMessage,
        setLoading,
        showError,
      )

      if (requestResponse) {
        // Replace the current value of the schedule with the updated version in our store
        const index = findIndex(
          scheduledChatMessages,
          prevZone => prevZone.id === updatedChatMessage.id,
        )
        setScheduledChatMessages(
          Object.assign([], scheduledChatMessages, { [index]: updatedChatMessage }),
        )
      }
    } else {
      // add new chat message
      const newChatMessage = {
        name: scheduledState.name,
        message_content: scheduledState.message_content,
        schedule: rruleBuilder.rruleToString(scheduledState),
      }
      const requestResponse = await postScheduledChatMessageToClient(
        agencyId,
        client.id,
        newChatMessage,
        setLoading,
        showError,
      )
      // Use the API response as the new ChatMessage's data since it includes the ID
      if (requestResponse) {
        setScheduledChatMessages([...scheduledChatMessages, requestResponse])
        handleChatMessageSchedulePageOrCountChange()
        // Object.assign([], scheduledChatMessages, requestResponse),
        // clear sidebar form state
        dispatch({ type: 'reset' })
        onClose()
      }
    }

    if (requestResponse) {
      // clear sidebar form state
      dispatch({ type: 'reset' })
      onClose()
    }
  }

  const onDelete = async () => {
    let requestSuccess = true

    // if it has been previously saved, send request to delete endpoint
    if (scheduledState.id) {
      requestSuccess = await deleteScheduledChatMessageById(
        agencyId,
        client.id,
        scheduledState.id,
        setLoading,
        showError,
      )
    }
    if (requestSuccess) {
      // remove from scheduledChatMessage list (use id if available, otherwise name)
      const filteredSchedules = scheduledChatMessages.filter(chatMessage =>
        chatMessage.id
          ? chatMessage.id !== scheduledState.id
          : chatMessage.name !== scheduledState.name,
      )
      setScheduledChatMessages(filteredSchedules)

      // Refresh the grid and page count
      handleChatMessageSchedulePageOrCountChange()

      // clear sidebar form state
      dispatch({ type: 'reset' })
      onClose()
    }
  }

  function dateRangeReducer(state, action) {
    if (action.type !== 'reset') {
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return initialDateRangeState
  }

  const sendOnDemandChatMessage = async () => {
    // add new chat message
    const sentFromFullName = user.lastName.concat(', ', user.firstName)
    const sentToFullName = client.last_name.concat(', ', client.first_name)
    const newOnDemandChatMessage = {
      source: 'agent',
      sent_from: sentFromFullName,
      sent_to: sentToFullName,
      message_content: onDemandChatMessage,
    }
    const requestResponse = await postOnDemandChatMessageToClient(
      agencyId,
      client.id,
      newOnDemandChatMessage,
      setLoading,
      showError,
    )
    // Use the API response as the new ChatMessage's data since it includes the ID
    if (requestResponse) {
      showSuccessMessage('On-Demand Chat Message has been sent.')
      handlePageOrCountChange()
      setOnDemandChatMessage('')
    }
  }

  useEffect(() => {
    /**
     * @todo Refactor this:
     * - Having synchronous awaits and API calls that share loading/errors is an anti-pattern. They
     *   should each have individual loading/error states and should not wait on each other in a
     *   chain. This often results in long page load times, since each request waits for the
     *   previous response to return
     * - Also, we should never have an `await` outside of a try/catch
     */
    async function setDefaultData() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('id')) {
        const agency = await getAgencyById(agencyId, showPageLoadError, setPageLoading)
        const currentClient = await getClientById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setPageLoading,
        )
        const chatMessages = await getAllClientChatMessagesById(
          agencyId,
          urlParams.get('id'),
          selectedAgent,
          showPageLoadError,
          setPageLoading,
          currentPage,
          (perPageCount = 50),
        )

        // set agency name
        setAgencyName(agency.name)
        setClient(currentClient)

        if (chatMessages) {
          updateChatMessages(chatMessages)
        }
      }

      const getSchChatmessages = await getAllClientScheduledChatMessagesById(
        agencyId,
        urlParams.get('id'),
        showPageLoadError,
        setPageLoading,
        chatMessageScheduleCurrentPage,
        5,
      )

      // Set Schedule chat messages
      setScheduledChatMessages(getSchChatmessages.results)
      setChatMessageScheduleRowCount(getSchChatmessages.count)
    }

    setDefaultData()
  }, [selectedAgent])

  // pagination
  const [rowCount, setRowCount] = useState()

  const handlePageOrCountChange = _throttle(async () => {
    const response = await getAllClientChatMessagesById(
      agencyId,
      client.id,
      selectedAgent,
      showError,
      setLoading,
      currentPage,
      perPageCount,
      filteredStartDate,
      filteredEndDate,
    )
    if (response) {
      updateChatMessages(response)
    }
  }, 500)

  const handleRowsPerPageChange = async perPage => {
    perPageCount = perPage

    handlePageOrCountChange()
  }

  const handlePageChange = async page => {
    currentPage = page

    handlePageOrCountChange()
  }

  // Chatmessage schedule pagination
  const [chatMessageScheduleRowCount, setChatMessageScheduleRowCount] = useState(0)

  const handleChatMessageSchedulePageOrCountChange = _throttle(async () => {
    const response = await getAllClientScheduledChatMessagesById(
      agencyId,
      client.id,
      showError,
      setLoading,
      chatMessageScheduleCurrentPage,
      chatMessageSchedulePerPageCount,
    )
    if (response) {
      setScheduledChatMessages(response.results)
      setChatMessageScheduleRowCount(response.count)
    }
  }, 500)

  const handleChatMessageScheduleRowsPerPageChange = async () => {
    // const chatMessageScheduleRowCount = perPage
    handleChatMessageSchedulePageOrCountChange()
  }

  const handleChatMessageSchedulePageChange = async page => {
    chatMessageScheduleCurrentPage = page

    handleChatMessageSchedulePageOrCountChange()
  }

  const handleDateFilter = async () => {
    // convert to utc and format to send to API
    let startDateTime = dateRange.startTime
      ? moment.tz(dateRange.startDate.concat(' ', dateRange.startTime), client.timezone)
      : moment.tz(dateRange.startDate.concat(' ', '00:00'), client.timezone)

    startDateTime = startDateTime.utc().format('YYYY-MM-DD HH:mm')

    let endDateTime
    if (dateRange.endDate) {
      endDateTime = dateRange.endTime
        ? moment.tz(dateRange.endDate.concat(' ', dateRange.endTime), client.timezone)
        : moment.tz(dateRange.endDate.concat(' ', '23:59'), client.timezone)

      endDateTime = endDateTime.utc().format('YYYY-MM-DD HH:mm')
    }

    setFilteredStartDate(startDateTime)
    setFilteredEndDate(endDateTime)

    const response = await getAllClientChatMessagesById(
      agencyId,
      client.id,
      selectedAgent,
      showError,
      setLoading,
      1,
      10,
      startDateTime, // created_after
      endDateTime, // created_before
    )
    if (response) {
      updateChatMessages(response)
      setDateFilterApplied(true)
    }
  }

  const handleDateFilterClear = async () => {
    const response = await getAllClientChatMessagesById(
      agencyId,
      client.id,
      selectedAgent,
      showError,
      setLoading,
      currentPage,
      perPageCount,
    )
    if (response) {
      updateChatMessages(response)
      setDateFilterApplied(false)
      setDateRange({ type: 'reset' })
      setFilteredStartDate(null)
      setFilteredEndDate(null)
    }
  }

  const updateChatMessages = response => {
    const chatMessages = JSON.parse(JSON.stringify(response.results))
    setClientChatMessages(chatMessages)
    const rowsCount = response.count - (response.results.length - chatMessages.length)
    setRowCount(rowsCount)
  }

  function downloadPDF() {
    setDownloadState(DOWNLOAD_STATES.downloading)

    /* column headers */
    const columnList = [
      { dataKey: 'source', title: 'Source' },
      { dataKey: 'received', title: 'Sent' },
      { dataKey: 'sent_from', title: 'Sent From' },
      { dataKey: 'sent_to', title: 'Sent To' },
      { dataKey: 'message_content', title: 'Message' },
      { dataKey: 'status', title: 'Status' },
    ]

    const formattedChatMessages = []
    clientChatMessages.forEach(chatMessage => {
      const chatMessageRowObject = {
        source: chatMessage.source[0].toUpperCase() + chatMessage.source.slice(1),
        created: chatMessage.created ? convertToClientTimezone(moment(chatMessage.created)) : null,
        received: chatMessage.received
          ? convertToClientTimezone(moment(chatMessage.received))
          : convertToClientTimezone(moment(chatMessage.created)),
        sent_from: chatMessage.sent_from,
        sent_to: chatMessage.sent_to,
        message_content: chatMessage.message_content,
        status: chatMessage.view_status ? 'Viewed' : 'Not Viewed',
      }
      formattedChatMessages.push(chatMessageRowObject)
    })
    const JSPDF = jsPDF
    const pdf = new JSPDF('l', 'px', [1122, 100 * columnList.length])

    pdf.setFontSize(20)
    pdf.setTextColor(1, 3, 2)
    pdf.text('Name', 20, 20)

    /* report summary title styles */
    pdf.setFontSize(14)

    /* report summary details styles */
    pdf.setTextColor(100)

    /* binding chatmessages to pdf */
    pdf.autoTable({
      columns: columnList,
      body: formattedChatMessages,
      margin: { top: 10, right: 20, bottom: 20, left: 20 },
      headStyles: {
        fillColor: '#284b63',
        textColor: '#fff',
        lineWidth: 0.1,
      },
      bodyStyles: {
        lineColor: '#000000',
      },
      styles: { fontSize: 14 },
      theme: 'striped',
    })

    pdf.save(`VCheck24 Message Log - ${client.last_name}, ${client.first_name}`)

    // Flash the download completed message
    setDownloadState(DOWNLOAD_STATES.downloaded)
    window.setTimeout(() => {
      setDownloadState(DOWNLOAD_STATES.idle)
    }, 3000)
  }

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const convertToClientTimezone = time => time.tz(client.timezone).format('MM/DD/YY hh:mm:ss A z')

  function downloadCSV() {
    setDownloadState(DOWNLOAD_STATES.downloading)
    const dataToExport = _map(clientChatMessages, event => ({
      Source: event.source[0].toUpperCase() + event.source.slice(1),
      Sent: event.received
        ? convertToClientTimezone(moment(event.received))
        : convertToClientTimezone(moment(event.created)),
      'Sent From': event.sent_from,
      'Sent To': event.sent_to,
      Message: event.message_content,
      Status: event.view_status ? 'Viewed' : 'Not Viewed',
    }))
    const JSONToSheet = XLSX.utils.json_to_sheet(dataToExport)
    const worksheet = XLSX.utils.sheet_to_csv(JSONToSheet)
    const filename = `VCheck24 Message Log - ${client.last_name}, ${client.first_name}.csv`

    FileSaver.saveAs(new Blob([s2ab(worksheet)], { type: 'application/octet-stream' }), filename)

    // Flash the download completed message
    setDownloadState(DOWNLOAD_STATES.downloaded)
    window.setTimeout(() => {
      setDownloadState(DOWNLOAD_STATES.idle)
    }, 3000)
  }

  const cannedMessages = [
    'OFFICE APPOINTMENT ALERT: THIS IS A REMINDER THAT YOU HAVE A PROBATION APPOINTMENT TOMORROW.',
    'COURT DATE ALERT: THIS IS A REMINDER THAT YOU HAVE COURT TOMORROW.',
    'BRACELET ALERT: YOU MUST CHARGE YOUR MONITORING BRACELET AS SOON AS POSSIBLE. FAILURE TO CHARGE MAY RESULT IN A NOTICE TO YOUR AGENT.',
    'BRACELET ALERT: PLEASE PERFORM A VCHECK SHOWING YOUR ANKLE BRACELET NOW. FAILURE TO DO SO MAY RESULT IN A NOTICE TO YOUR AGENT.',
    'BRACELET ALERT: YOUR BRACELET REQUIRES MAINTENANCE. PLEASE REPORT TO YOUR MONITORING SUPERVISION OFFICE FOR BRACELET MAINTENANCE TODAY. FAILURE TO REPORT MAY RESULT IN A NOTICE TO YOUR AGENT.',
    'MEDICATION ALERT: YOU SHOULD TAKE YOUR PRESCRIBED MEDICATION NOW.',
    'CURFEW ALERT: IT IS TIME TO RETURN HOME NOW. FAILURE TO RETURN HOME BY CURFEW MAY RESULT IN A NOTICE TO YOUR AGENT.',
    'HOME CHECK ALERT: YOU MUST PERFORM A VCHECK PANNING THE CAMERA AROUND YOUR HOME NOW. FAILURE TO DO SO MAY RESULT IN A NOTICE TO YOUR AGENT.',
    'DRUG TEST ALERT: PLEASE REPORT TO THE PROBATION OFFICE FOR A DRUG TEST TODAY. FAILURE TO REPORT MAY RESULT IN A PROGRAM VIOLATION.',
    'DRUG PATCH ALERT: PLEASE PERFORM A VCHECK SHOWING YOUR DRUG PATCH NOW. FAILURE TO DO SO MAY RESULT IN A NOTICE TO YOUR AGENT.',
    'VCHECK24 PAYMENT ALERT: Reminder - A payment is due on your VCheck24 account.  If you have a credit/debit card on file, this payment will be run automatically and no action is needed.',
    'VCHECK24 PAYMENT ALERT: Your VCheck24 payment has declined.  Call 833-824-3257 or visit www.vcheck24.com/client-enrollment to make up your payment.  This must be done before end of day today or a non-compliance report will be sent to your agent.',
  ]

  const questionnaireMessages = [
    'Mandatory Self-Reporting Questionnaire 1. (Either type your responses in order in the message area or copy and paste this questionnaire into the message area and provide responses)\n\nYou must answer each question below:\n\nHave you had contact with law enforcement in the past week?\nHas your address changed in the past week? If so, provide your new address.\nHas any of your emergency contact info changed in the past week? If so, what is the new contact info?\nHas your employment changed in the past week?',
    'Mandatory Self-Reporting Questionnaire 2.  (Either type your responses in order in the message area or copy and paste this questionnaire into the message area and provide responses)\n\nYou must answer each question below:\n\nHave you taken illegal drugs in the past week?  If so, what did you take?\nHave you attended all treatment and/or court ordered classes this past week?\nHave you been in any altercations in the past week?',
    'Mandatory COVID-19 Self-Reporting Questionnaire. (Either type your responses in order in the message area or copy and paste this questionnaire into the message area and provide responses)\n\nYou must answer each question below:\n\nHave you experienced any symptoms of (or been diagnosed with) COVID-19 in the past week?\nHave you come into contact with anyone experiencing symptoms of (or diagnosed with) COVID-19 in the past week?',
  ]

  const isFormValid =
    !!scheduledState.name && !!scheduledState.startDate && !!scheduledState.startTime

  const _renderInfoButton = () => (
    <Box style={{ height: 0 }}>
      <Box direction="row" align="center" gap="vertical" margin={{ top: 'none' }}>
        <Box
          direction="row"
          align="center"
          style={{ 'box-shadow': 'none' }}
          onClick={() => setIsInfoContentVisible(!isInfoContentVisible)}
        >
          <CircleInformation />
        </Box>
      </Box>

      <DropButton
        open={isInfoContentVisible}
        dropAlign={{ top: 'bottom', left: 'left' }}
        style={{ visibility: 'hidden', paddingBottom: '15px' }}
        dropContent={
          <Box
            border={{ color: 'focus', size: 'medium', style: 'solid' }}
            align="start"
            gap="none"
            pad={{ vertical: 'xsmall', horizontal: 'medium' }}
            width="500px"
          >
            <div>
              <p>
                <strong>Message Content:</strong>
              </p>
              <p>
                You may type a customized message or choose from a menu of pre-written messages
              </p>
            </div>
            <div>
              <p>
                <strong>Delivery Options:</strong>
              </p>
              <p>1) You may send the message now (on-demand)</p>
              <p>
                2) Schedule the message to be sent at a future date and time (e.g. court or
                appointment reminders or a prompt to show up for drug testing)
              </p>
              <p>
                3) Set the message to be automatically sent to the client on a recurring basis
                (e.g. self-reporting, yes/no questions)
              </p>
            </div>
          </Box>
        }
      />
    </Box>
  )

  // Build Sidebar header & content
  const sideBarHeader = scheduledState.exists ? (
    <FormattedMessage {...messages.scheduleSidebarHeaderEdit} />
  ) : (
    <FormattedMessage {...messages.scheduleSidebarHeaderAdd} />
  )

  const sidebarContent = (
    <ChatDetailsContainer>
      <Header mini level="5">
        <FormattedMessage {...messages.chatMessageNameHeader} />
      </Header>

      <Box style={{ height: '100%' }}>
        <FormField
          component={TextInput}
          label={intl.formatMessage(messages.chatMessageNameLabel)}
          name="chat_message_name"
          id="chat_message_name"
          onChange={e => dispatch({ fieldName: 'name', data: e.target.value })}
          value={{ value: scheduledState.name }}
          required
        />
      </Box>

      <Box gap="x-small" style={{ height: '100%' }}>
        <FormField
          component={TextArea}
          label={intl.formatMessage(messages.chatMessageContentLabel)}
          name="chat_message_content"
          id="chat_message_content"
          onChange={e => dispatch({ fieldName: 'message_content', data: e.target.value })}
          value={{ value: scheduledState.message_content }}
          required
        />
      </Box>

      <Box gap="x-small" style={{ height: '100%' }}>
        <Box direction="row" align="center" gap="vertical" margin={{ top: 'small' }}>
          {!cannedScheduledMessagePickerOpen ? (
            <Box
              direction="row"
              align="center"
              style={{ 'box-shadow': 'none' }}
              onClick={() => setCannedScheduledMessagePickerOpen(true)}
            >
              <Header mini level="5" margin="none">
                <FormattedMessage {...messages.cannedMessagePickerHeader} />
              </Header>
              <FormDown size="medium" color="placeholder" />
            </Box>
          ) : (
            <Box
              direction="row"
              align="center"
              style={{ 'box-shadow': 'none' }}
              onClick={() => setCannedScheduledMessagePickerOpen(false)}
            >
              <Header mini level="5" margin="none">
                <FormattedMessage {...messages.cannedMessagePickerHeader} />
              </Header>
              <FormUp size="medium" onClick={() => setCannedScheduledMessagePickerOpen(false)} />
            </Box>
          )}
        </Box>
        <DropButton
          open={cannedScheduledMessagePickerOpen}
          dropAlign={{ top: 'bottom', right: 'right' }}
          style={{ visibility: 'hidden' }}
          dropContent={
            <Box
              align="start"
              gap="none"
              style={{ height: '200px', width: '400px', margin: '0 auto' }}
            >
              {cannedMessages.map(message => (
                <StyledCannedMessageButton
                  onClick={() => {
                    dispatch({ fieldName: 'message_content', data: message })
                    setCannedScheduledMessagePickerOpen(false)
                  }}
                >
                  {message}
                </StyledCannedMessageButton>
              ))}
            </Box>
          }
        />
      </Box>

      <Box gap="x-small">
        <Box direction="row" align="center" gap="vertical" margin={{ top: 'xsmall' }}>
          {!questionnaireScheduledMessagePickerOpen ? (
            <Box
              direction="row"
              align="center"
              style={{ 'box-shadow': 'none' }}
              onClick={() => setQuestionnaireScheduledMessagePickerOpen(true)}
            >
              <Header mini level="5" margin="none">
                <FormattedMessage {...messages.questionnaireMessagePickerHeader} />
              </Header>
              <FormDown size="medium" color="placeholder" />
            </Box>
          ) : (
            <Box
              direction="row"
              align="center"
              style={{ 'box-shadow': 'none' }}
              onClick={() => setQuestionnaireScheduledMessagePickerOpen(false)}
            >
              <Header mini level="5" margin="none">
                <FormattedMessage {...messages.questionnaireMessagePickerHeader} />
              </Header>
              <FormUp
                size="medium"
                onClick={() => setQuestionnaireScheduledMessagePickerOpen(false)}
              />
            </Box>
          )}
        </Box>
        <DropButton
          open={questionnaireScheduledMessagePickerOpen}
          dropAlign={{ top: 'bottom', right: 'right' }}
          style={{ visibility: 'hidden' }}
          dropContent={
            <Box
              align="start"
              gap="none"
              style={{ height: '200px', width: '400px', margin: '0 auto' }}
            >
              {questionnaireMessages.map(message => (
                <StyledCannedMessageButton
                  onClick={() => {
                    dispatch({ fieldName: 'message_content', data: message })
                    setQuestionnaireScheduledMessagePickerOpen(false)
                  }}
                >
                  {message}
                </StyledCannedMessageButton>
              ))}
            </Box>
          }
        />
      </Box>

      <Header mini level="5">
        <FormattedMessage {...messages.scheduleHeader} />
      </Header>

      <Text size="12px">
        <FormattedMessage {...messages.scheduleDetails} />
      </Text>

      <ChatMessageScheduler
        state={scheduledState}
        dispatch={dispatch}
        lowerBoundDate={moment(new Date()).format('YYYY-MM-DD')}
        showEndTime={false}
        startLabelText="Send Time"
      ></ChatMessageScheduler>

      <Box>{error && <Message message={error} isError />}</Box>
    </ChatDetailsContainer>
  )

  // If we don't have a client id in our data object, display a loading message
  if (!client.id || pageLoading || pageLoadError) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }

  return (
    <>
      <Grid
        responsive
        rows={['auto', 'auto', 'auto', 'auto']}
        columns={['auto']}
        gap="small"
        areas={[['header'], ['chatMessages'], ['divider'], ['table']]}
      >
        <Box gridArea="header" direction="column" justify="between" gap="small" pad="medium">
          <Box direction="row-responsive">
            <Box
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              direction="row"
              align="center"
              justify="center"
              round
              border={{ color: 'light-3', size: 'xsmall' }}
            >
              <Text size="small">
                <Link to="/clients">Client List</Link>

                {' / '}

                <Link to={`/clients/summary?id=${client.id}`}>
                  {`${client.first_name.concat(' ', client.last_name)}`}
                </Link>

                {' / Messages'}
              </Text>
            </Box>
          </Box>

          <Box direction="row-responsive" justify="start" align="center" gap="small">
            <Box
              background="light-2"
              justify="center"
              align="center"
              pad="none"
              round="full"
              height="150px"
              width="150px"
            >
              <ClientAvatar
                file={ClientWizardAvatarURL.clientAvatarURLValue.avatar_url}
                size="large"
                type={ClientWizardAvatarURL.clientAvatarURLValue.type}
              />
            </Box>

            <Box align="end" gap="small">
              <Text size="medium">{`${agencyName} / ${client.first_name} ${client.last_name}`}</Text>
            </Box>

            {_renderInfoButton()}
          </Box>
        </Box>

        <Grid
          gridArea="chatMessages"
          columns={['1/4', '1/4', '1/2']}
          rows={['auto']}
          areas={[
            { name: 'onDemandChatMessages', start: [0, 0], end: [1, 0] },
            { name: 'chatMessagesDivider', start: [1, 0], end: [2, 0] },
            { name: 'scheduledChatMessages', start: [2, 0], end: [3, 0] },
          ]}
          gap="small"
          direction="row"
          pad="medium"
        >
          <Box gridArea="onDemandChatMessages">
            <Box direction="row-responsive" align="center" justify="left">
              <Header mini level="3" margin={{ top: 'none' }}>
                <FormattedMessage {...messages.onDemandChatMessagesHeader} />
              </Header>
            </Box>

            <Box direction="row-responsive" align="center" justify="between">
              <Text size="xsmall">
                <FormattedMessage {...messages.onDemandChatMessagesDescription} />
              </Text>
            </Box>

            <Box
              direction="row-responsive"
              align="center"
              justify="between"
              margin={{ top: 'medium' }}
            >
              <TextArea
                size="small"
                id="onDemandChatMessageInput"
                value={onDemandChatMessage}
                style={{ height: '150px' }}
                onChange={e => {
                  setOnDemandChatMessage(e.target.value)
                }}
              ></TextArea>
            </Box>

            <Box>
              <Box gap="x-small">
                <Box direction="row" align="center" gap="vertical" margin={{ top: 'medium' }}>
                  {!cannedOnDemandMessagePickerOpen ? (
                    <Box
                      direction="row"
                      align="center"
                      style={{ 'box-shadow': 'none' }}
                      onClick={() => setCannedOnDemandMessagePickerOpen(true)}
                    >
                      <Header mini level="5" margin="none">
                        <FormattedMessage {...messages.cannedMessagePickerHeader} />
                      </Header>
                      <FormDown size="medium" color="placeholder" />
                    </Box>
                  ) : (
                    <Box
                      direction="row"
                      align="center"
                      style={{ 'box-shadow': 'none' }}
                      onClick={() => setCannedOnDemandMessagePickerOpen(false)}
                    >
                      <Header mini level="5" margin="none">
                        <FormattedMessage {...messages.cannedMessagePickerHeader} />
                      </Header>
                      <FormUp
                        size="medium"
                        onClick={() => setCannedOnDemandMessagePickerOpen(false)}
                      />
                    </Box>
                  )}
                </Box>

                <DropButton
                  open={cannedOnDemandMessagePickerOpen}
                  dropAlign={{ top: 'bottom', left: 'left' }}
                  style={{ visibility: 'hidden' }}
                  dropContent={
                    <Box
                      align="start"
                      gap="none"
                      style={{ height: '200px', width: '400px', margin: '0 auto' }}
                    >
                      {cannedMessages.map(message => (
                        <StyledCannedMessageButton
                          onClick={() => {
                            setOnDemandChatMessage(message)
                            setCannedOnDemandMessagePickerOpen(false)
                          }}
                        >
                          {message}
                        </StyledCannedMessageButton>
                      ))}
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Box gap="x-small">
              <Box direction="row" align="center" gap="vertical" margin={{ top: 'xsmall' }}>
                {!questionnaireOnDemandMessagePickerOpen ? (
                  <Box
                    direction="row"
                    align="center"
                    style={{ 'box-shadow': 'none' }}
                    onClick={() => setQuestionnaireOnDemandMessagePickerOpen(true)}
                  >
                    <Header mini level="5" margin="none">
                      <FormattedMessage {...messages.questionnaireMessagePickerHeader} />
                    </Header>
                    <FormDown size="medium" color="placeholder" />
                  </Box>
                ) : (
                  <Box
                    direction="row"
                    align="center"
                    style={{ 'box-shadow': 'none' }}
                    onClick={() => setQuestionnaireOnDemandMessagePickerOpen(false)}
                  >
                    <Header mini level="5" margin="none">
                      <FormattedMessage {...messages.questionnaireMessagePickerHeader} />
                    </Header>
                    <FormUp
                      size="medium"
                      onClick={() => setQuestionnaireOnDemandMessagePickerOpen(false)}
                    />
                  </Box>
                )}
              </Box>

              <DropButton
                open={questionnaireOnDemandMessagePickerOpen}
                dropAlign={{ top: 'bottom', left: 'left' }}
                style={{ visibility: 'hidden' }}
                dropContent={
                  <Box
                    align="start"
                    gap="none"
                    style={{ height: '200px', width: '400px', margin: '0 auto' }}
                  >
                    {questionnaireMessages.map(message => (
                      <StyledCannedMessageButton
                        onClick={() => {
                          setOnDemandChatMessage(message)
                          setQuestionnaireOnDemandMessagePickerOpen(false)
                        }}
                      >
                        {message}
                      </StyledCannedMessageButton>
                    ))}
                  </Box>
                }
              />
            </Box>

            <Box direction="row-responsive" align="center" justify="between">
              <Button
                color="focus"
                icon={<Send size="small" />}
                label={intl.formatMessage(messages.sendChatMessageButtonLabel)}
                onClick={sendOnDemandChatMessage}
              />
            </Box>
          </Box>
          <HorizontalDivider gridArea="chatMessagesDivider"></HorizontalDivider>
          <Box gridArea="scheduledChatMessages">
            <Header mini level="3" margin={{ top: 'none' }}>
              <FormattedMessage {...messages.scheduledChatMessagesHeader} />
            </Header>

            <Box direction="row-responsive" align="center" justify="between">
              <Text size="xsmall">
                <FormattedMessage {...messages.scheduledChatMessagesDescription} />
              </Text>
            </Box>

            <Box direction="row-responsive" align="center" justify="between">
              <Button
                color="focus"
                icon={<Add size="small" />}
                label={intl.formatMessage(messages.scheduleChatMessageButtonLabel)}
                onClick={() => {
                  dispatch({ type: 'reset' })
                  openModal()
                }}
              />
            </Box>

            {scheduledChatMessages && scheduledChatMessages.length > 0 && (
              <Box fill pad={{ bottom: 'medium', right: 'large' }}>
                <ScheduledChatMessageTable
                  editable
                  chatMessages={scheduledChatMessages}
                  editInSidebar={editInSidebar}
                  rowCount={chatMessageScheduleRowCount}
                  handlePageChange={handleChatMessageSchedulePageChange}
                  handleRowsPerPageChange={handleChatMessageScheduleRowsPerPageChange}
                />
              </Box>
            )}

            <Box>{error && <Message message={error} isError />}</Box>
          </Box>
        </Grid>
        <Box gridArea="divider" direction="column" justify="between" gap="small" pad="medium">
          <Divider />
        </Box>
        <>
          <Box gridArea="table" gap="xsmall" pad="medium">
            <Box direction="row" justify="between" align="start">
              <Box gap="small">
                <Box direction="row" align="center" gap="xsmall">
                  {!dateTimePickerOpen ? (
                    <Box
                      style={{ 'box-shadow': 'none' }}
                      direction="row"
                      align="center"
                      onClick={() => setDateTimePickerOpen(true)}
                    >
                      <Header mini level="5" margin="none">
                        <FormattedMessage {...messages.datePickerHeader} />
                      </Header>

                      <FormDown size="medium" color="placeholder" />
                    </Box>
                  ) : (
                    <Box
                      style={{ 'box-shadow': 'none' }}
                      direction="row"
                      align="center"
                      onClick={() => setDateTimePickerOpen(false)}
                    >
                      <Header mini level="5" margin="none">
                        <FormattedMessage {...messages.datePickerHeader} />
                      </Header>
                      <FormUp size="medium" />
                    </Box>
                  )}
                </Box>

                <Collapsible open={dateTimePickerOpen}>
                  <Box align="start" gap="none" margin={{ bottom: 'xlarge' }}>
                    <Box direction="row" pad={{ bottom: 'small' }} gap="small" align="center">
                      <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />

                      <StyledButton
                        hoverIndicator
                        primary={false}
                        icon={<Search color={colors.primary} />}
                        onClick={handleDateFilter}
                      />
                    </Box>
                    <Collapsible open={dateFilterApplied}>
                      <StyledButton
                        primary={false}
                        color={colors.primary}
                        label="Clear Filters"
                        onClick={handleDateFilterClear}
                      />
                    </Collapsible>
                  </Box>
                </Collapsible>
              </Box>
              {clientChatMessages && clientChatMessages.length > 0 && (
                <Box direction="row" justify="end" align="center" gap="small">
                  {downloadState === DOWNLOAD_STATES.downloaded && (
                    <Text
                      color="focus"
                      weight="bold"
                      size="small"
                      margin={{ horizontal: 'small' }}
                    >
                      File has been downloaded.
                    </Text>
                  )}

                  <StyledButton
                    color={colors.primary}
                    disabled={downloadState === DOWNLOAD_STATES.downloading}
                    label="Download PDF"
                    onClick={downloadPDF}
                    reverse
                  ></StyledButton>

                  <StyledButton
                    color={colors.primary}
                    disabled={downloadState === DOWNLOAD_STATES.downloading}
                    label="Download CSV"
                    onClick={downloadCSV}
                    reverse
                  ></StyledButton>
                </Box>
              )}
            </Box>

            <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>

            <Box fill pad={{ bottom: 'medium' }}>
              <MessageLogTable
                loading={loading}
                clientChatMessages={clientChatMessages}
                clientTimezone={client.timezone}
                rowCount={rowCount}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </Box>
        </>
        {showSidebar && (
          <WizardSidebar
            disabled={loading}
            onClose={onClose}
            onSubmit={onSubmit}
            onDelete={onDelete}
            isFormValid={isFormValid}
            isAbleToDelete={!!scheduledState.exists}
            header={sideBarHeader}
            content={sidebarContent}
          ></WizardSidebar>
        )}

        {!!successMessage && (
          <Notification
            position="top-right"
            message={successMessage}
            onClose={() => showSuccessMessage(false)}
          ></Notification>
        )}
      </Grid>
    </>
  )
}

const Divider = styled.div`
  margin-top: 0px;
  border: 0.5px solid lightGrey;
  width: 100%;
`

const HorizontalDivider = styled.div`
  border-right: 1px solid lightGrey;
  margin-right: 30px;
`

const StyledButton = styled(Button)`
  margin: 0 !important;
`

const StyledCannedMessageButton = styled(Button)`
  margin: 6px 12px !important;
  padding: 8px 16px;
  white-space: pre-wrap;
`
const ChatDetailsContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  min-height: -1px !important;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
`

ClientMessageLogPage.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ClientMessageLogPage)
