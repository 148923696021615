/* eslint-disable react/prop-types */
import React from 'react'
import { TextInput } from 'grommet'

import { doc } from './TextInput.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * TextInput
 *
 */

function createTextInput({ children, ...rest }) {
  return <TextInput {...rest}>{children}</TextInput>
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createTextInput,
})
