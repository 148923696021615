// eslint-disable-next-line import/no-cycle
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/** Distributor Service */

export async function getAgentsByDistributorId(
  distributorId,
  setError,
  setLoading,
  page,
  limit,
  agencyId,
  status,
  name,
) {
  setLoading(true)
  // updating agency query tag as per API documentation
  try {
    const data = await axios.get(`/distributors/${distributorId}/agents/`, {
      params: { page, limit, agencyid: agencyId, status, name },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// Zone details by Distributor Id

export async function getZonesByDistributorId(distributorId, setError, setLoading) {
  setLoading(true)
  try {
    const data = await axios.get(
      `/distributors/${distributorId}/zones/get_distributor_based_zones/`,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// Get Agency Names by Distributor ID

export async function getAgencyNamesByDistributorIdService(distributorId, setError, setLoading) {
  setLoading(true)

  try {
    const data = await axios.get(
      `/distributors/${distributorId}/zones/get_distributor_based_zones/`,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getAgenciesByDistributorId(
  distributorId,
  setError,
  setLoading,
  page,
  limit,
  name,
) {
  setLoading(true)
  try {
    const data = await axios.get(`/distributors/${distributorId}/agencies/`, {
      params: { page, limit, name },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getClientsByDistributorService(
  distributorId,
  setError,
  setLoading,
  currentPage,
  rowsPerPage,
  searchText,
  status,
  agentId,
  agencyId,
  customizeLoading,
) {
  setLoading(true)

  try {
    const data = await axios.get(`/distributors/${distributorId}/clients/`, {
      params: {
        limit: rowsPerPage,
        page: currentPage,
        name: searchText,
        status,
        agentid: agentId,
        agencyid: agencyId,
      },
    })
    setError(false)
    if (!customizeLoading) {
      setLoading(false)
    }
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postAgency(distributorId, agency, setError, setLoading) {
  setLoading(true)
  try {
    const results = await axios.post(`/distributors/${distributorId}/agencies/`, agency)
    setError(false)
    setLoading(false)
    return results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
export async function updateAgency(agencyId, agency, setError, setLoading) {
  setLoading(true)
  try {
    const results = await axios.patch(`/agencies/${agencyId}/`, agency)
    setError(false)
    setLoading(false)
    return results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteAgency(distributorId, agencyId, setLoading, setError) {
  setLoading(true)
  try {
    const results = await axios.delete(`/distributors/${distributorId}/agencies/${agencyId}/`)
    setError(false)
    setLoading(false)
    return results
  } catch (err) {
    if (err.results) {
      setError(err.results)
      return null
    }
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// Dashboard screen Client Status data

export async function getClientStatusDetailByDistributorId(
  distributorId,
  agencyId,
  setError,
  setLoading,
  limit = -1,
  currenPage = 1,
  name,
  customizeLoading,
) {
  setLoading(true)
  try {
    const data = await axios.get(`/agencies/${agencyId}/agency-dashboard-stats-data/`, {
      params: { name, agency_id: agencyId, limit, page: currenPage },
    })

    setError(false)
    if (!customizeLoading) {
      setLoading(false)
    }
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postDistributorCaseNoteToClient(
  distributorId,
  clientId,
  agentId,
  postData,
  setError,
  setLoading,
) {
  setLoading(true)

  try {
    const data = await axios.post(`/distributors/${distributorId}/case-notes/`, {
      notes: postData.notes,
      created_by: agentId,
      client: clientId,
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getUserDetailsByDistributorId(distributorId, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.get(`distributors/${distributorId}/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateUserProfileByDistributorId(
  distributorId,
  user,
  userId,
  setLoading,
  setError,
) {
  setLoading(true)

  try {
    const data = await axios.patch(
      `/distributors/${distributorId}/distributor-user/${userId}/`,
      user,
    )
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function getDistributorsById(distributorId, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.get(`/distributors/${distributorId}/distributor-user/`)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
