import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

// Components
import { Box } from '../Box'

// Utils, Services & Messages
import messages from './ScheduledChatMessageTable.messages'
import colors from '../../utils/colors'
import rruleBuilder from '../../utils/rruleBuilder'
import rruleParser from '../../utils/rruleParser'
import { getTablePaginationOptions } from '../../utils/helpers'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { TableHeader } from '../Table'
import { LoadingIndicator } from '../LoadingIndicator'
import { FormattedMessage } from 'react-intl'

/**
 * ScheduledChatMessageTable
 *
 * This component takes in an array of scheduled chatMessages and converts it into
 * the required format before passing to reusable PopulatedTable component
 *
 * Input format will match store/api:
 * {
 *    name:
 *    schedule:
 *    start:
 *    stop:
 *    ...other fields not used (id, created, etc.)
 * }
 *
 * This component will convert the input format into the format needed by
 * the sidebar (so that the sidebar can populate correctly on edit) as well
 * as pass the name, dates, and schedule (in text form) fields to the table
 *
 */
const ScheduledChatMessageTable = ({ editable = false, chatMessages, editInSidebar, rowCount,
  handlePageChange, handleRowsPerPageChange, printable, loading }) => {
  const tableHeaderData = [
    messages.tableHeaderOne,
    messages.tableHeaderTwo,
    messages.tableHeaderThree,
    messages.tableHeaderFour,
  ]


  const dataTableHeaders = [
    {
      selector: 'id',
      name: '',
      omit: true,
    },
    {
      selector: 'name',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'date',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'time',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'schedule',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFour} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },

  ]

  const bodyData = []

  const getChatMessageNameLink = chatMessage =>
    editable ? (
      <Box
        onClick={() => {
          editInSidebar(chatMessage)
        }}
        style={{ color: colors.primary, cursor: 'pointer', textDecoration: 'underline' }}
      >
        {chatMessage.name}
      </Box>
    ) : (
      <Box>{chatMessage.name}</Box>
    )

  chatMessages.forEach(chatMessage => {
    // convert into format needed by sidebar
    // sidebarFormatVCheck will be passed to editInSidebar function
    if (chatMessage.schedule) {
      const parsedFields = rruleParser.parseRRuleToVCheckFields(chatMessage.schedule)
      const sidebarFormatVCheck = {
        id: chatMessage.id,
        name: chatMessage.name,
        message_content: chatMessage.message_content,
        startDate: parsedFields.startDate,
        startTime: parsedFields.startTime,
        endTime: moment(parsedFields.startTime, 'HH:mm')
          .add(15, 'm')
          .format('HH:mm'),
        repeat: parsedFields.repeat,
        repeatEvery: parsedFields.repeatEvery,
        frequency: parsedFields.frequency,
        endsOn: parsedFields.endsOn,
        endDate: parsedFields.endDate,
        occurrences: parsedFields.occurrences,
        weekdays: parsedFields.weekdays,
      }

      // format and add to body data
      bodyData.push({
        name: getChatMessageNameLink(sidebarFormatVCheck),
        date: moment(sidebarFormatVCheck.startDate).format('MM/DD/YY'),
        time: moment(sidebarFormatVCheck.startTime, 'HH:mm').format('hh:mm A'),
        schedule: rruleBuilder.rruleStringToText(chatMessage.schedule),
      })
    }
  })

  const fieldProps = {}

  if (!printable) {
    fieldProps.progressPending = loading
    fieldProps.progressComponent = <LoadingIndicator />
  }

  return (
    <>
      <StyledDataTable
        noHeader
        border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
        columns={dataTableHeaders}
        data={bodyData}
        sortable
        striped
        highlightOnHover
        pointerOnHover
        pagination={!printable}
        paginationServer
        paginationTotalRows={rowCount}
        paginationPerPage={5}
        paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
        onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
        onChangePage={e => handlePageChange(e)}
        persistTableHead
        {...fieldProps}
      />
    </>
  )
}
const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  nav > div > select {
    padding-right: 24px;
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`
ScheduledChatMessageTable.propTypes = {
  editable: PropTypes.bool,
  editInSidebar: PropTypes.func,
  chatMessages: PropTypes.array,
  rowCount: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  printable: PropTypes.bool,
  loading: PropTypes.bool,
}

export default ScheduledChatMessageTable
