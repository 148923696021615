/* eslint-disable import/no-cycle */
import { observable, action } from 'mobx'
import { createContext } from 'react'

import { BaseStore } from './BaseStore'

import {
  getClientById as getClientByIdService,
  getClientVCheckCount as getClientVCheckCountService,
  getClientUnreadMessageCount as getClientUnreadMessageCountService,
  getClientAvatarlUrl as getClientAvatarUrlService,
  updateClientAvatarlUrl as updateClientAvatarUrlService,
  postClientToAgency as postClientToAgencyService,
  updateClientInAgency as updateClientInAgencyService,
  postClientLocationsById as postClientLocationsByIdService,
  postGlobalZonesToClient as postGlobalZonesToClientService,
  postOnDemandChatMessageToClient as postOnDemandChatMessageToClientService,
  postScheduledChatMessageToClient as postScheduledChatMessageToClientService,
  updateScheduledChatMessageById as updateScheduledChatMessageByIdService,
  deleteScheduledChatMessageById as deleteScheduledChatMessageByIdService,
  postScheduledVCheckToClient as postScheduledVCheckToClientService,
  updateScheduledVCheckById as updateScheduledVCheckByIdService,
  deleteScheduledVCheckById as deleteScheduledVCheckByIdService,
  postRandomVCheckToClient as postRandomVCheckToClientService,
  updateRandomVCheckById as updateRandomVCheckByIdService,
  deleteRandomVCheckById as deleteRandomVCheckByIdService,
  getClientCaseNotesById as getClientCaseNotesByIdService,
  postCaseNoteToClient as postCaseNoteToClientService,
  getClientSchedulesById as getClientSchedulesByIdService,
  getAllClientLocationsById as getAllClientLocationsByIdService,
  updateClientLocationById as updateClientLocationByIdService,
  deleteLocationById as deleteLocationByIdService,
  getAllClientEventsById as getAllClientEventsByIdService,
  getAgentUnreadChatMessageCount as getAgentUnreadChatMessageCountService,
  getAllClientChatMessagesById as getAllClientChatMessagesByIdService,
  getAllClientScheduledChatMessagesById as getAllClientScheduledChatMessagesByIdService,
  getClientVCheckEventsById as getClientVCheckEventsByIdService,
  updateClientVCheckEventById as updateClientVCheckEventByIdService,
  getClientComplianceReportsById as getClientComplianceReportsByIdService,
  getClientVCheckVideoById as getClientVideoByIdService,
  getClientActiveDaysById as getClientActiveDaysByClientId,
  postClientOverrideFunction as postOverrideFunction,
} from '../services/agency.service'

import { postDistributorCaseNoteToClient } from '../services/distributor.service'

const INITIAL_STATE = {
  clientBasicInfo: {},
  clientLocations: [],
  clientGlobalZones: [],
  clientScheduledChatMessages: [],
  clientScheduledVChecks: [],
  clientRandomVChecks: {},
  clientEvents: [],
  clientUnreadChatMessageCount: [],
  clientChatMessages: [],
  clientCompletedVChecks: [],
  clientNotes: [],
  clientPrimaryAgent: {},
}

export class ClientWizardStore extends BaseStore {
  @observable clientBasicInfo

  @observable clientLocations

  @observable clientGlobalZones

  @observable clientScheduledChatMessages

  @observable clientScheduledVChecks

  @observable clientRandomVChecks

  @observable clientEvents

  @observable clientUnreadChatMessageCount

  @observable clientChatMessages

  @observable clientCompletedVChecks

  @observable clientNotes

  // @observable clientScheduledChatMessages
  @observable clientVCheckCountValue

  @observable clientUnreadMessageCountValue

  @observable clientAvatarURLValue

  @observable distributorClientSelectedAgencyId

  // CLIENT

  @action.bound
  async getClientById(agencyId, clientId, setError, setLoading) {
    const client = await getClientByIdService(agencyId, clientId, setError, setLoading)
    if (client) {
      this.clientBasicInfo = client.data
    }
    return client.data
  }

  @action.bound
  async getClientVCheckCount(agencyId, clientId, setError, setLoading) {
    const clientVCheckCount = await getClientVCheckCountService(
      agencyId,
      clientId,
      setError,
      setLoading,
    )
    if (clientVCheckCount) {
      this.clientVCheckCountValue = clientVCheckCount
    }
    return clientVCheckCount
  }

  @action.bound
  async getClientUnreadMessageCount(agencyId, clientId, setError, setLoading) {
    const ClientUnreadMessageCount = await getClientUnreadMessageCountService(
      agencyId,
      clientId,
      setError,
      setLoading,
    )
    if (ClientUnreadMessageCount) {
      this.clientUnreadMessageCountValue = ClientUnreadMessageCount
    }
    return ClientUnreadMessageCount
  }

  @action.bound
  async getClientAvatarUrl(agencyId, clientId, vcheckSrc, setError, setLoading) {
    const clientAvatarUrl = await getClientAvatarUrlService(
      agencyId,
      clientId,
      vcheckSrc,
      setError,
      setLoading,
    )
    if (clientAvatarUrl) {
      this.clientAvatarURLValue = clientAvatarUrl
    }
    return clientAvatarUrl
  }

  @action.bound
  async updateClientAvatarUrl(agencyId, clientId, payLoad, setError, setLoading) {
    const response = await updateClientAvatarUrlService(
      agencyId,
      clientId,
      payLoad,
      setError,
      setLoading,
    )

    return response
  }

  @action.bound
  async postClientToAgency(agencyId, client, setLoading, setError) {
    const addedClient = await postClientToAgencyService(agencyId, client, setLoading, setError)
    if (addedClient) {
      this.clientBasicInfo = addedClient
    }
    return addedClient
  }

  @action.bound
  async updateClientInAgency(agencyId, client, setLoading, setError) {
    const updatedClient = await updateClientInAgencyService(agencyId, client, setLoading, setError)
    if (updatedClient) {
      this.clientBasicInfo = updatedClient
    }
    return updatedClient
  }

  @action.bound
  async setUpdatedClientImage(element) {
    this.clientAvatarURLValue(element)
  }

  @action.bound
  async setClientBasicInfo(client) {
    this.clientBasicInfo = client
  }

  @action.bound
  async setDistributorClientSelectedAgency(agencyId) {
    this.distributorClientSelectedAgencyId = agencyId
  }

  // CLIENT LOCATIONS

  @action.bound
  async getClientLocationsById(agencyId, clientId, setError, setLoading) {
    const results = await getAllClientLocationsByIdService(
      agencyId,
      clientId,
      setError,
      setLoading,
    )
    if (results) {
      // format locations
      const formattedLocations = []
      results.locations.forEach(location => {
        // add formatted address field
        if (location.address) {
          const formattedAddress = this.formatAddress(location.address)
          const formattedLocation = {
            ...location,
            formatted_address: formattedAddress,
          }
          formattedLocations.push(formattedLocation)
        }
      })
      this.clientLocations = formattedLocations
      return formattedLocations
    }
    return null
  }

  @action.bound
  async postClientLocationsById(agencyId, clientId, location, setLoading, setError) {
    const newLocation = await postClientLocationsByIdService(
      agencyId,
      clientId,
      location,
      setLoading,
      setError,
    )

    if (newLocation) {
      // add formatted_address field
      const formattedAddress = this.formatAddress(newLocation.address)
      const formattedLocation = {
        ...newLocation,
        formatted_address: formattedAddress,
      }
      this.setClientLocations([...this.clientLocations, formattedLocation])
      return formattedLocation
    }
    return null
  }

  @action.bound
  async updateClientLocationById(agencyId, clientId, locId, loc, setLoading, setError) {
    const updatedLocation = await updateClientLocationByIdService(
      agencyId,
      clientId,
      locId,
      loc,
      setLoading,
      setError,
    )

    if (updatedLocation) {
      // add formatted_address field
      const formattedAddress = this.formatAddress(updatedLocation.address)
      const formattedLocation = {
        ...updatedLocation,
        formatted_address: formattedAddress,
      }
      const filteredLocations = this.clientLocations.filter(location => location.id !== locId)
      this.setClientLocations([...filteredLocations, formattedLocation])
      return formattedLocation
    }
    return null
  }

  @action.bound
  async deleteLocationById(agencyId, clientId, locationId, setLoading, setError) {
    const requestSuccess = await deleteLocationByIdService(
      agencyId,
      clientId,
      locationId,
      setLoading,
      setError,
    )
    if (requestSuccess) {
      const filteredLocations = this.clientLocations.filter(loc => loc.id !== locationId)
      this.setClientLocations(filteredLocations)
    }
    return requestSuccess
  }

  @action.bound
  async getClientGlobalZonesById(agencyId, clientId, setError, setLoading) {
    const results = await getAllClientLocationsByIdService(
      agencyId,
      clientId,
      setError,
      setLoading,
    )
    if (results) {
      // format zones
      const formattedZones = []
      results.global.forEach(zone => {
        // add formatted address field
        if (zone.address) {
          const formattedAddress = this.formatAddress(zone.address)
          const formattedZone = {
            ...zone,
            formatted_address: formattedAddress,
          }
          formattedZones.push(formattedZone)
        }
      })
      this.clientGlobalZones = formattedZones
      return formattedZones
    }
    this.clientGlobalZones = []
    return null
  }

  @action.bound
  async postGlobalZonesToClient(agencyId, clientId, zones, setLoading, setError) {
    // build array of ids to send to BE
    const selectedZoneIds = []
    zones.forEach(row => {
      selectedZoneIds.push(row.zone.id)
    })
    const response = await postGlobalZonesToClientService(
      agencyId,
      clientId,
      selectedZoneIds,
      setLoading,
      setError,
    )

    if (response) {
      // format zones
      const formattedZones = []
      zones.forEach(zone => {
        // add formatted address field
        if (zone.zone.address) {
          const formattedAddress = this.formatAddress(zone.zone.address)
          const formattedZone = {
            ...zone.zone,
            formatted_address: formattedAddress,
          }
          formattedZones.push(formattedZone)
        }
      })
      this.setClientGlobalZones(formattedZones)
      return response
    }
    return null
  }

  @action.bound
  async setClientLocations(locations) {
    this.clientLocations = locations
  }

  @action.bound
  async setClientGlobalZones(zones) {
    this.clientGlobalZones = zones
  }

  // CLIENT SCHEDULES

  @action.bound
  async getClientSchedulesById(agencyId, clientId, setError, setLoading) {
    const vchecks = await getClientSchedulesByIdService(agencyId, clientId, setError, setLoading)
    if (vchecks) {
      this.clientScheduledVChecks = vchecks.scheduled
      this.clientRandomVChecks = vchecks.random
    }

    return vchecks
  }

  @action.bound
  async postOnDemandChatMessageToClient(agencyId, clientId, chatMessage, setLoading, setError) {
    const onDemandChatMessage = await postOnDemandChatMessageToClientService(
      agencyId,
      clientId,
      chatMessage,
      setLoading,
      setError,
    )

    return onDemandChatMessage
  }

  @action.bound
  async postScheduledChatMessageToClient(agencyId, clientId, vcheck, setLoading, setError) {
    const scheduledChatMessage = await postScheduledChatMessageToClientService(
      agencyId,
      clientId,
      vcheck,
      setLoading,
      setError,
    )

    if (scheduledChatMessage) {
      this.clientScheduledChatMessages = [
        ...this.clientScheduledChatMessages,
        scheduledChatMessage,
      ]
    }
    return scheduledChatMessage
  }

  @action.bound
  async updateScheduledChatMessageById(agencyId, clientId, vcheck, setLoading, setError) {
    const updatedChatMessage = await updateScheduledChatMessageByIdService(
      agencyId,
      clientId,
      vcheck,
      setLoading,
      setError,
    )
    if (updatedChatMessage) {
      const filteredChatMessages = this.clientScheduledChatMessages.filter(v => v.id !== vcheck.id)
      this.setClientScheduledChatMessages([...filteredChatMessages, updatedChatMessage])
    }
    return updatedChatMessage
  }

  @action.bound
  async deleteScheduledChatMessageById(agencyId, clientId, vcheckId, setLoading, setError) {
    const requestSuccess = await deleteScheduledChatMessageByIdService(
      agencyId,
      clientId,
      vcheckId,
      setLoading,
      setError,
    )
    if (requestSuccess) {
      const filteredChatMessages = this.clientScheduledChatMessages.filter(v => v.id !== vcheckId)
      this.setClientScheduledChatMessages(filteredChatMessages)
    }
    return requestSuccess
  }

  @action.bound
  async setClientScheduledChatMessages(chatMessages) {
    this.clientScheduledChatMessages = chatMessages
  }

  @action.bound
  async postScheduledVCheckToClient(agencyId, clientId, vcheck, setLoading, setError) {
    const scheduledVCheck = await postScheduledVCheckToClientService(
      agencyId,
      clientId,
      vcheck,
      setLoading,
      setError,
    )

    if (scheduledVCheck) {
      this.clientScheduledVChecks = [...this.clientScheduledVChecks, scheduledVCheck]
    }
    return scheduledVCheck
  }

  @action.bound
  async updateScheduledVCheckById(agencyId, clientId, vcheck, setLoading, setError) {
    const updatedVCheck = await updateScheduledVCheckByIdService(
      agencyId,
      clientId,
      vcheck,
      setLoading,
      setError,
    )
    if (updatedVCheck) {
      const filteredVChecks = this.clientScheduledVChecks.filter(v => v.id !== vcheck.id)
      this.setClientScheduledVChecks([...filteredVChecks, updatedVCheck])
    }
    return updatedVCheck
  }

  @action.bound
  async deleteScheduledVCheckById(agencyId, clientId, vcheckId, setLoading, setError) {
    const requestSuccess = await deleteScheduledVCheckByIdService(
      agencyId,
      clientId,
      vcheckId,
      setLoading,
      setError,
    )
    if (requestSuccess) {
      const filteredVChecks = this.clientScheduledVChecks.filter(v => v.id !== vcheckId)
      this.setClientScheduledVChecks(filteredVChecks)
    }
    return requestSuccess
  }

  @action.bound
  async setClientScheduledVChecks(vchecks) {
    this.clientScheduledVChecks = vchecks
  }

  @action.bound
  async postRandomVCheckToClient(agencyId, clientId, vcheck, setLoading, setError) {
    const randomVCheck = await postRandomVCheckToClientService(
      agencyId,
      clientId,
      vcheck,
      setLoading,
      setError,
    )
    if (randomVCheck) {
      this.clientRandomVChecks = randomVCheck
    }
    return randomVCheck
  }

  @action.bound
  async postClientOverrideFunction(agencyId, clientId, setLoading, setError) {
    const overrideVCheck = await postOverrideFunction(agencyId, clientId, setLoading, setError)

    return overrideVCheck
  }

  @action.bound
  async updateRandomVCheckById(agencyId, clientId, vcheckid, vcheck, setLoading, setError) {
    const randomVCheck = await updateRandomVCheckByIdService(
      agencyId,
      clientId,
      vcheckid,
      vcheck,
      setLoading,
      setError,
    )
    if (randomVCheck) {
      this.clientRandomVChecks = randomVCheck
    }
    return randomVCheck
  }

  @action.bound
  async deleteRandomVCheckById(agencyId, clientId, vcheckid, setLoading, setError) {
    const requestSuccess = await deleteRandomVCheckByIdService(
      agencyId,
      clientId,
      vcheckid,
      setLoading,
      setError,
    )
    if (requestSuccess) {
      this.clientRandomVChecks = {}
    }
    return requestSuccess
  }

  @action.bound
  async setClientRandomVChecks(vchecks) {
    this.clientRandomVChecks = vchecks
  }

  // CLIENT EVENTS
  @action.bound
  async getAllClientEventsById(
    agencyId,
    clientId,
    setError,
    setLoading,
    page = 1,
    limit = -1,
    hasPoint,
    createdAfter,
    createdBefore,
  ) {
    // limit of -1 will return ALL data, make sure to pass in limit if this isn't desired
    const events = await getAllClientEventsByIdService(
      agencyId,
      clientId,
      setError,
      setLoading,
      page,
      limit,
      hasPoint,
      createdAfter,
      createdBefore,
    )
    if (events) {
      this.clientEvents = events.results
    }
    return events
  }

  // CLIENT UNREAD CHAT MESSAGES
  @action.bound
  async getAgentUnreadChatMessageCount(agencyId) {
    // limit of -1 will return ALL data, make sure to pass in limit if this isn't desired
    const unreadChatMessages = await getAgentUnreadChatMessageCountService(agencyId)
    if (unreadChatMessages) {
      this.clientUnreadChatMessageCount = unreadChatMessages.count
      return unreadChatMessages.count
    }
    return 0
  }

  // CLIENT CHAT MESSAGE
  @action.bound
  async getAllClientChatMessagesById(
    agencyId,
    clientId,
    agentId,
    setError,
    setLoading,
    page = 1,
    limit = -1,
    createdAfter,
    createdBefore,
  ) {
    // limit of -1 will return ALL data, make sure to pass in limit if this isn't desired
    const chatMessages = await getAllClientChatMessagesByIdService(
      agencyId,
      clientId,
      agentId,
      setError,
      setLoading,
      page,
      limit,
      createdAfter,
      createdBefore,
    )
    if (chatMessages) {
      this.clientChatMessages = chatMessages.results
    }
    return chatMessages
  }

  @action.bound
  async getAllClientScheduledChatMessagesById(
    agencyId,
    clientId,
    setError,
    setLoading,
    page = 1,
    limit = -1,
    createdAfter,
    createdBefore,
  ) {
    const schduledChatMessagesList = await getAllClientScheduledChatMessagesByIdService(
      agencyId,
      clientId,
      setError,
      setLoading,
      page,
      limit,
      createdAfter,
      createdBefore,
    )
    if (schduledChatMessagesList) {
      this.clientScheduledChatMessages = schduledChatMessagesList.results
    }

    return schduledChatMessagesList
  }

  @action.bound
  async getClientVCheckEventsById(
    agencyId,
    clientId = '',
    setError,
    setLoading,
    page = 1,
    limit = -1,
    pending = false,
  ) {
    // limit of -1 will return ALL data, make sure to pass in limit if this isn't desired
    const vchecks = await getClientVCheckEventsByIdService(
      agencyId,
      clientId,
      setError,
      setLoading,
      page,
      limit,
      pending,
    )
    if (vchecks) {
      this.clientCompletedVChecks = vchecks.results
    }
    return vchecks
  }

  @action.bound
  async getClientVCheckVideo(agencyId, clientId, documentPath, setError, setLoading) {
    // limit of -1 will return ALL data, make sure to pass in limit if this isn't desired
    const vchecks = await getClientVideoByIdService(
      agencyId,
      clientId,
      documentPath,
      setError,
      setLoading,
    )
    if (vchecks) {
      return vchecks
    }
    return null
  }

  @action.bound
  async updateClientVCheckEventById(
    agencyId,
    clientId,
    vcheckId,
    vcheckReview,
    setError,
    setLoading,
  ) {
    const updatedVCheck = await updateClientVCheckEventByIdService(
      agencyId,
      clientId,
      vcheckId,
      vcheckReview,
      setError,
      setLoading,
    )
    if (updatedVCheck) {
      return updatedVCheck
    }
    return this.clientCompletedVChecks
  }

  @action.bound
  async getClientComplianceReportsById(
    agencyId,
    clientId,
    setError,
    setLoading,
    page = 1,
    limit = -1,
    createdAfter,
    createdBefore,
  ) {
    // limit of -1 will return ALL data, make sure to pass in limit if this isn't desired
    const vchecks = await getClientComplianceReportsByIdService(
      agencyId,
      clientId,
      setError,
      setLoading,
      page,
      limit,
      createdAfter,
      createdBefore,
    )
    if (vchecks) {
      this.clientCompletedVChecks = vchecks.results
    }
    return vchecks
  }

  // EXTRAS

  @action.bound
  async getClientCaseNotesById(agencyId, clientId, setError, setLoading) {
    const notes = await getClientCaseNotesByIdService(agencyId, clientId, setError, setLoading)
    if (notes) {
      this.clientNotes = notes.results
      return notes.results
    }
    return notes
  }

  @action.bound
  async postCaseNoteToClient(agencyId, clientId, agentId, note, setError, setLoading) {
    const postedNote = await postCaseNoteToClientService(
      agencyId,
      clientId,
      agentId,
      note,
      setError,
      setLoading,
    )
    if (postedNote) {
      this.clientNotes = [...this.clientNotes, postedNote]
    }
    return postedNote
  }

  @action.bound
  async postSelectedAgent(selectedAgent) {
    this.clientPrimaryAgent = { ...selectedAgent }
  }

  @action.bound
  async postCaseNoteClientByDistributorId(
    distributorId,
    clientId,
    agentId,
    note,
    setError,
    setLoading,
  ) {
    const postedNote = await postDistributorCaseNoteToClient(
      distributorId,
      clientId,
      agentId,
      note,
      setError,
      setLoading,
    )
    const addNote = { ...postedNote, created: postedNote.created }
    if (postedNote) {
      this.clientNotes = [...this.clientNotes, addNote]
    }
    return addNote
  }

  formatAddress(address) {
    if (address.address_1 && address.city && address.state) {
      const formattedAddress = address.address_1.concat(
        ', ',
        address.city,
        ', ',
        address.state,
        ', ',
        address.country,
        ' ',
        address.zip_code,
      )
      return formattedAddress
    }
    return null
  }

  @action.bound
  async getClientActiveDays(agencyId, clientId, setError, setLoading) {
    const activeDays = await getClientActiveDaysByClientId(
      agencyId,
      clientId,
      setError,
      setLoading,
    )
    return activeDays
  }
}

export const wizard = new ClientWizardStore(INITIAL_STATE)
export const ClientWizardStoreContext = createContext(wizard)
