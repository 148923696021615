import { describe, PropTypes } from 'react-desc'

export const doc = DropButton => {
  const DocumentedDropButton = describe(DropButton)
    .description('A DropButton')
    .usage(
      `import { DropButton } from 'components/DropButton';
<DropButton />`,
    )

  DocumentedDropButton.propTypes = {
    id: PropTypes.string
      .description('The DOM id attribute value to use for the element.')
      .defaultValue(false),
  }

  return DocumentedDropButton
}
