/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import { LoadingIndicator } from '../LoadingIndicator'

// Components
import { TableHeader } from '../Table'

// Utils, Services & Messages
import messages from './AgencyZonesTable.messages'
import {
  getTablePaginationOptions,
  initialPaginationConstants as initialPagination,
} from '../../utils/helpers'

/**
 * AgencyZonesTable
 *
 * This component takes in the agencyZones array data and converts it into the
 * required format before passing to reusable PopulatedTable component
 *
 */

const AgencyZonesTable = props => {
  const {
    loading,
    agencyZones,
    isDistributorUser,
    rowCount,
    handlePageChange,
    handleRowsPerPageChange,
    editInSidebar,
  } = props

  const dataTableHeaders = [
    {
      selector: 'name',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.name.toLowerCase().localeCompare(rowB.name.toLowerCase()),
      style: {
        'font-size': 14,
      },
    },
    {
      selector: 'agencyName',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      sortable: true,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      omit: !isDistributorUser,
    },
    {
      selector: 'address',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      sortable: true,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'zone',
      omit: true,
    },
  ]

  const bodyData = []

  const getAddress = zone => {
    if (zone.address) {
      return zone.address.address_1.concat(
        ', ',
        zone.address.city,
        ', ',
        zone.address.state,
        ', ',
        zone.address.country,
        ' ',
        zone.address.zip_code,
      )
    }
    return 'Address Not Found'
  }

  agencyZones.forEach(zone => {
    const zoneRowObject = {
      name: zone.name ? zone.name : 'Not Found',
      agencyName: zone.agency_name,
      address: getAddress(zone),
      zone,
    }
    bodyData.push(zoneRowObject)
  })

  const fieldProps = {}
  fieldProps.progressPending = loading
  fieldProps.progressComponent = <LoadingIndicator />

  return (
    <StyledDataTable
      noHeader
      border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
      columns={dataTableHeaders}
      data={bodyData}
      sortable
      striped
      highlightOnHover
      pointerOnHover
      onRowClicked={e => editInSidebar(e.zone)}
      paginationTotalRows={rowCount}
      pagination
      paginationServer
      paginationPerPage={initialPagination.rows}
      paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
      onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
      onChangePage={e => handlePageChange(e)}
      persistTableHead
      {...fieldProps}
    />
  )
}

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`

AgencyZonesTable.propTypes = {
  loading: PropTypes.bool,
  agencyZones: PropTypes.array,
  isDistributorUser: PropTypes.bool,
  editInSidebar: PropTypes.func,
  rowCount: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
}

export default AgencyZonesTable
