import { defineMessages } from 'react-intl'

export const scope = 'app.components.ClientComplianceTable'

/*
 * ClientComplianceTable Messages
 *
 * This contains all the text for the ClientComplianceTable component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ClientComplianceTable component!',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Event Type',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Created',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Location',
  },
  tableHeaderFour: {
    id: `${scope}.tableHeaderFour`,
    defaultMessage: 'Zone Name',
  },
  tableHeaderFive: {
    id: `${scope}.tableHeaderFive`,
    defaultMessage: 'Zone Type',
  },
})
