import { defineMessages } from 'react-intl'

export const scope = 'app.components.ProfileSidebar'

/*
 * ProfileSidebar Messages
 *
 * This contains all the text for the ProfileSidebar component.
 */
export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Profile Data...',
  },
  sidebarHeader: {
    id: `${scope}.sidebarHeader`,
    defaultMessage: 'Profile',
  },
  deactivateLabel: {
    id: `${scope}.deactivateLabel`,
    defaultMessage: 'Deactivate',
  },
  agentFirstNameLabel: {
    id: `${scope}.agentNameLabel`,
    defaultMessage: 'First Name',
  },
  agentLastNameLabel: {
    id: `${scope}.agentLastNameLabel`,
    defaultMessage: 'Last Name',
  },
  agentTitleLabel: {
    id: `${scope}.agentTitleLabel`,
    defaultMessage: 'Title',
  },
  agentRoleLabel: {
    id: `${scope}.agentRoleLabel`,
    defaultMessage: 'Role Type',
  },
  agentPhoneLabel: {
    id: `${scope}.agentPhoneLabel`,
    defaultMessage: 'Phone Number',
  },
  agentEmailLabel: {
    id: `${scope}.agentEmailLabel`,
    defaultMessage: 'Email Address',
  },
  notificationLabel: {
    id: `${scope}.notificationLabel`,
    defaultMessage: 'Notification Settings',
  },
  smsLabel: {
    id: `${scope}.smsLabel`,
    defaultMessage: 'SMS',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'Email',
  },
  missedVChecksLabel: {
    id: `${scope}.missedVChecksLabel`,
    defaultMessage: 'Missed VChecks',
  },
  completedVChecksLabel: {
    id: `${scope}.completedVChecksLabel`,
    defaultMessage: 'Completed VChecks',
  },
  zoneAlertLabel: {
    id: `${scope}.zoneAlertLabel`,
    defaultMessage: 'Zone and Location Alerts',
  },
  appErrorLabel: {
    id: `${scope}.appErrorLabel`,
    defaultMessage: 'Application Errors',
  },
  noActivityLabel: {
    id: `${scope}.noActivityLabel`,
    defaultMessage: 'No GPS Alerts',
  },
  facialNotification: {
    id: `${scope}.facialNotification`,
    defaultMessage: 'Facial Recognition Alert',
  },
  noteDescirption: {
    id: `${scope}.noteDescirption`,
    defaultMessage:
      'NOTE: \u2002 By checking any of the boxes above, I am agreeing to receive email and/or text message notifications from the VCheck24 system.',
  },
})
