import { defineMessages } from 'react-intl'

export const scope = 'app.components.ChatMessageScheduler'

/*
 * ChatMessageScheduler Messages
 *
 * This contains all the text for the ChatMessageScheduler component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ChatMessageScheduler component!',
  },
  startDateLabel: {
    id: `${scope}.startDateLabel`,
    defaultMessage: 'Start',
  },
  messageSendTimeLabel: {
    id: `${scope}.messageSendTimeLabel`,
    defaultMessage: 'Send Time',
  },
  messageSendDateLabel: {
    id: `${scope}.messageSendDateLabel`,
    defaultMessage: 'Send Date',
  },
  startTimeLabel: {
    id: `${scope}.startTimeLabel`,
    defaultMessage: 'Begin',
  },
  endTimeLabel: {
    id: `${scope}.endTimeLabel`,
    defaultMessage: 'End',
  },
  repeatLabel: {
    id: `${scope}.repeatLabel`,
    defaultMessage: 'Repeat',
  },
  repeatEveryLabel: {
    id: `${scope}.repeatEveryLabel`,
    defaultMessage: 'Repeat Every',
  },
  frequencyLabel: {
    id: `${scope}.frequencyLabel`,
    defaultMessage: 'Frequency',
  },
  repeatDaysLabel: {
    id: `${scope}.repeatDaysLabel`,
    defaultMessage: 'Repeat On',
  },
  endsOnLabel: {
    id: `${scope}.endsOnLabel`,
    defaultMessage: 'Ends On',
  },
  endDateLabel: {
    id: `${scope}.endDateLabel`,
    defaultMessage: 'End Date',
  },
  occurrencesLabel: {
    id: `${scope}.occurrencesLabel`,
    defaultMessage: 'Number of Occurrences',
  },
})
