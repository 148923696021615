import { createGlobalStyle } from 'styled-components'
import { BODY_FONT } from 'utils/theme'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: ${BODY_FONT}, Helvetica, Arial, sans-serif !important;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }
`

export default GlobalStyle
