/* eslint-disable react/prop-types */
import React from 'react'
import { DropButton } from 'grommet'

// Utils, Services & Messages
import { doc } from './DropButton.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 * DropButton
 */
function createDropButton({ children, primary = true, reverse, style, ...rest }) {
  return (
    <DropButton
      primary={primary}
      reverse={reverse}
      style={{
        fontSize: 14,
        fontWeight: 'bold',
        ...style,
      }}
      {...rest}
    >
      {children}
    </DropButton>
  )
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createDropButton,
})
