import React from 'react'

// Components
import { Text } from '../Text'

/**
 * AppVersion
 *
 * Displays the current version and commit hash as long as we're running outside of production
 */
const AppVersion = () =>
  process.env.CIRCLE_BRANCH !== 'prod' && (
    <Text color="accent-2" size="small" weight="bold">
      App Version: {process.env.SHORT_GIT_HASH}
    </Text>
  )

export default AppVersion
