import { defineMessages } from 'react-intl'

export const scope = 'app.components.MessageLogTable'

/*
 * MessageLogTable Messages
 *
 * This contains all the text for the MessageLogTable component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the MessageLogTable component!',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Source',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Sent',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Sent From',
  },
  tableHeaderFour: {
    id: `${scope}.tableHeaderFour`,
    defaultMessage: 'Sent To',
  },
  tableHeaderFive: {
    id: `${scope}.tableHeaderFive`,
    defaultMessage: 'Message',
  },
  tableHeaderSix: {
    id: `${scope}.tableHeaderSix`,
    defaultMessage: 'Status',
  },
})
