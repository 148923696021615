/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { Tab } from 'grommet'
import { doc } from './Tab.doc'
import { createWithDoc } from '../../utils/helpers'
import colors from '../../utils/colors'

/**
 *
 * Tabs
 *
 * Displays Material Design style Tabs
 *
 * - When clicked, it renders a different content panel and applies styles to the active tab
 *
 */

const StyledTab = styled(Tab).attrs(props => ({ ...props }))`
  div {
    padding-right: 40px;
    margin: 0 12px 12px 0;
    padding-bottom: 11px;
    border-bottom: ${({ active }) =>
      active ? `3px solid ${colors.secondary80}` : `3px solid ${colors.primary}`};
    color: ${({ active }) => (active ? colors.secondary80 : colors.primary)};
    font-weight: bold;
    font-size: 18px;
    &:hover {
      color: ${({ disabled }) => !disabled && `${colors.secondary80}`};
      border-color: ${({ disabled }) => !disabled && `${colors.secondary80}`};
    }
  }
`
const createTab = ({
  active,
  forwardRef,
  plain,
  title,
  onMouseOver,
  onMouseOut,
  onFocus,
  onBlur,
  theme,
  disabled,
  ...rest
}) => (
  <StyledTab
    active={active}
    forwardRef={forwardRef}
    plain={plain}
    title={title}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onFocus={onFocus}
    onBlur={onBlur}
    theme={theme}
    disabled={disabled}
    {...rest}
  />
)

export default createWithDoc({
  docFunction: doc,
  component: createTab,
})
