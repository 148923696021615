import React, { useEffect, useContext, useState, useReducer } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import { Add } from 'grommet-icons'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { findIndex } from 'lodash'
import moment from 'moment'

// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Form, FormField } from 'components/Form'
import { Header } from 'components/Header'
import { MaskedInput } from 'components/MaskedInput'
import { Message } from 'components/Message'
import { Link } from 'components/Link'
import { ScheduledVCheckTable } from 'components/ScheduledVCheckTable'
import { Select } from 'components/Select'
import { Text } from 'components/Text'
import { TextBlock } from 'components/TextBlock'
import { TextInput } from 'components/TextInput'
import { VCheckScheduler } from 'components/VCheckScheduler'
import { WizardSidebar } from 'components/WizardSidebar'

// Stores
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils, Services & Messages
import messages from './AddClientSchedule.messages'
import rruleBuilder from '../../utils/rruleBuilder'
import useFlashMessage from '../../hooks/FlashMessage'

// Mins value from json
import time from './Time.json'

/**
 *
 * AddClientSchedule
 *
 * This container holds all components needed to build the third step
 * in the Add Client flow - Add Scheduled (and eventually Random) VChecks:
 *
 * User can add Scheduled VCheck by clicking the "Add VCheck" button
 * This opens a sidebar where the user can enter:
 * - VCheck Name
 * - VCheck Schedule - uses VCheckScheduler component
 *
 * Once added, the vcheck will appear in the summary table displaying:
 * - start date
 * - time
 * - schedule description
 *
 */
const AddClientSchedule = ({ intl, goToStep, clientIsVerified }) => {
  const {
    clientScheduledVChecks,
    clientRandomVChecks,
    distributorClientSelectedAgencyId,
    setClientRandomVChecks,
    getClientSchedulesById,
    postScheduledVCheckToClient,
    updateScheduledVCheckById,
    deleteScheduledVCheckById,
    postRandomVCheckToClient,
    updateRandomVCheckById,
    deleteRandomVCheckById,
  } = useContext(ClientWizardStoreContext)
  const { user, isDistributor } = useContext(UserStoreContext)

  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [pageLoadError, showPageLoadError] = useState(null)
  const [validationError, setValidationError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, seterrorMessage] = useState('  ')
  const [clientId, setClientId] = useState()
  const [agencyId, setAgencyId] = useState(
    isDistributor ? distributorClientSelectedAgencyId : user.agencyId,
  )

  useEffect(() => {
    async function setDefaultData() {
      // Retreive the client ID from URL parameters
      const urlParams = new URLSearchParams(window.location.search)
      const clientIdParam = urlParams.get('id')
      if (clientIdParam) {
        setClientId(clientIdParam)

        // check if store has scheduled vchecks - if not, get data from api
        if (clientScheduledVChecks.length > 0) {
          setScheduledVChecks(clientScheduledVChecks)
        } else {
          const vchecks = await getClientSchedulesById(
            agencyId,
            clientIdParam,
            showPageLoadError,
            setLoading,
          )
          if (vchecks !== null) {
            setScheduledVChecks(vchecks.scheduled)
          }
        }

        // check if store has random vcheck data - if not, get data from api
        if (clientRandomVChecks.frequency) {
          setRandomVCheckInitialState(clientRandomVChecks)
        } else {
          const vchecks = await getClientSchedulesById(
            agencyId,
            clientIdParam,
            showPageLoadError,
            setLoading,
          )
          if (vchecks !== null) {
            setRandomVCheckInitialState(vchecks.random)
          }
        }
      }
    }
    if (agencyId !== '') {
      setDefaultData()
    } else {
      setAgencyId(distributorClientSelectedAgencyId)
    }
  }, [agencyId])

  // state of random vcheck toggle and form fields
  const [showRandom, setShowRandom] = useState(false)
  const [randomState, updateRandomState] = useReducer(vcheckReducer, {})
  const [randomFrequencyValue, setRandomFrequencyValue] = useState('1')
  const [randomStartValue, setRandomStartValue] = useState(randomState.rStartTime)
  const [randomEndValue, setRandomEndValue] = useState(randomState.rEndTime)

  // list of all scheduled vchecks formatted to post to client
  const [scheduledVChecks, setScheduledVChecks] = useState()

  // set to data being passed from store or api
  // random VCheck window defaults to 8AM - 8PM if no data
  const setRandomVCheckInitialState = state => {
    if (state.frequency) {
      setShowRandom(true)
    }
    updateRandomState({ fieldName: 'id', data: state.id ? state.id : null })
    updateRandomState({ fieldName: 'rFrequency', data: state.frequency ? state.frequency : 1 })
    updateRandomState({ fieldName: 'rStartTime', data: state.start ? state.start : '08:00:00' })
    updateRandomState({ fieldName: 'rEndTime', data: state.stop ? state.stop : '20:00:00' })
    setRandomFrequencyValue(state.frequency ? state.frequency.toString() : '1')
    setRandomStartValue(
      state.start ? moment(state.start, 'HH:mm:ss').format('hh:mm A') : '08:00 AM',
    )
    setRandomEndValue(state.stop ? moment(state.stop, 'HH:mm:ss').format('hh:mm A') : '8:00 PM')
  }

  // boolean for showing sidebar
  const [showSidebar, setShowSidebar] = useState()

  // state of sidebar form for single vcheck - cleared on "Save"
  const scheduledVCheckInitialState = {
    name: '',
    startDate: moment(new Date())
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    startTime: '08:00',
    endTime: '08:15',
    repeat: 'Does Not Repeat',
    repeatEvery: '1',
    frequency: 'Week(s)',
    endsOn: 'Never',
    endDate: undefined,
    occurrences: '',
    weekdays: [],
  }

  const [scheduledState, dispatch] = useReducer(vcheckReducer, scheduledVCheckInitialState)

  function vcheckReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return scheduledVCheckInitialState
  }

  // Sidebar open/close functions
  const onClose = () => setShowSidebar(false)
  const openModal = () => {
    setShowSidebar(true)
  }

  // Function to pass into ScheduledVCheckTable
  // that will open and populate sidebar for editing
  const editInSidebar = vcheck => {
    dispatch({
      type: 'update',
      data: {
        id: vcheck.id,
        exists: true,
        name: vcheck.name,
        startDate: vcheck.startDate,
        startTime: vcheck.startTime,
        endTime: vcheck.endTime,
        repeat: vcheck.repeat || scheduledVCheckInitialState.repeat,
        repeatEvery: vcheck.repeatEvery || scheduledVCheckInitialState.repeatEvery,
        frequency: vcheck.frequency || scheduledVCheckInitialState.frequency,
        endsOn: vcheck.endsOn || scheduledVCheckInitialState.endsOn,
        endDate: vcheck.endDate,
        occurrences: vcheck.occurrences || scheduledVCheckInitialState.occurrences,
        weekdays: vcheck.weekdays || scheduledVCheckInitialState.weekdays,
      },
    })

    openModal()
  }
  // Sidebar submit function
  const onSubmit = async () => {
    let isExist = false
    let requestResponse
    if (scheduledState.id) {
      // update existing vcheck.
      const updatedVCheck = {
        id: scheduledState.id,
        name: scheduledState.name,
        schedule: rruleBuilder.rruleToString(scheduledState),
      }

      // Update the schedule in the API
      requestResponse = await updateScheduledVCheckById(
        agencyId,
        clientId,
        updatedVCheck,
        setLoading,
        showError,
      )

      if (requestResponse) {
        // Replace the current value of the schedule with the updated version in our store
        const index = findIndex(scheduledVChecks, prevZone => prevZone.id === updatedVCheck.id)
        setScheduledVChecks(Object.assign([], scheduledVChecks, { [index]: updatedVCheck }))
      }
    } else {
      // add new vcheck
      let newVCheck = {}
      const errorMessagevalue =
        'vCheck already exists for the date & time, please choose another date & time'
      scheduledVChecks.forEach(scheduledVCheck => {
        const existingtime = `${scheduledVCheck.schedule.slice(
          17,
          19,
        )}:${scheduledVCheck.schedule.substring(19, 21)}`
        const existingDate = moment(scheduledVCheck.schedule.slice(8, 16)).format('YYYY-MM-DD')
        if (
          existingDate === scheduledState.startDate &&
          existingtime === scheduledState.startTime
        ) {
          isExist = true
        }
      })
      if (!isExist) {
        newVCheck = {
          name: scheduledState.name,
          schedule: rruleBuilder.rruleToString(scheduledState),
        }
      } else {
        return seterrorMessage(errorMessagevalue)
      }
      requestResponse = await postScheduledVCheckToClient(
        agencyId,
        clientId,
        newVCheck,
        setLoading,
        showError,
      )
      // Use the API response as the new VCheck's data since it includes the ID
      if (requestResponse) {
        setScheduledVChecks([...scheduledVChecks, requestResponse])
      }
    }
    if (requestResponse) {
      // clear sidebar form state
      dispatch({ type: 'reset' })
      onClose()
    }
    return undefined
  }

  const onDelete = async () => {
    let requestSuccess = true

    // if it has been previously saved, send request to delete endpoint
    if (scheduledState.id) {
      requestSuccess = await deleteScheduledVCheckById(
        agencyId,
        clientId,
        scheduledState.id,
        setLoading,
        showError,
      )
    }
    if (requestSuccess) {
      // remove from scheduledVCheck list (use id if available, otherwise name)
      const filteredSchedules = scheduledVChecks.filter(vcheck =>
        vcheck.id ? vcheck.id !== scheduledState.id : vcheck.name !== scheduledState.name,
      )
      setScheduledVChecks(filteredSchedules)

      // clear sidebar form state
      dispatch({ type: 'reset' })
      onClose()
    }
  }

  // Save & Continue button functions
  const saveRandomSchedule = async () => {
    let requestResponse = true
    // only post random if toggled on
    if (showRandom) {
      const vcheck = {
        start: randomState.rStartTime,
        stop: randomState.rEndTime,
        frequency: randomState.rFrequency,
        schedule: 'RRULE:FREQ=DAILY',
      }
      if (randomState.id) {
        requestResponse = await updateRandomVCheckById(
          agencyId,
          clientId,
          randomState.id,
          vcheck,
          setLoading,
          showError,
        )
      } else {
        requestResponse = await postRandomVCheckToClient(
          agencyId,
          clientId,
          vcheck,
          setLoading,
          showError,
        )
      }
    } else {
      if (randomState.id) {
        // if there was an existing random vcheck from api, send delete request
        requestResponse = await deleteRandomVCheckById(
          agencyId,
          clientId,
          randomState.id,
          setLoading,
          showError,
        )
      }
      if (requestResponse) {
        setClientRandomVChecks({})
      }
    }
    return !!requestResponse
  }

  const saveAndBack = () => {
    const requestSuccess = saveRandomSchedule()
    if (requestSuccess) {
      goToStep(1)
    }
  }

  const saveScheduleAndContinue = async () => {
    const requestSuccess = await saveRandomSchedule()

    if (!clientIsVerified && requestSuccess) {
      goToStep(3)
    }
  }

  const verifyFuction = () => {
    setTimeout(() => {
      seterrorMessage(null)
    }, 1000)
  }

  const isFormValid =
    !!scheduledState.name && !!scheduledState.startDate && !!scheduledState.startTime

  // Build Sidebar header & content
  const sideBarHeader = scheduledState.exists ? (
    <FormattedMessage {...messages.scheduleSidebarHeaderEdit} />
  ) : (
    <FormattedMessage {...messages.scheduleSidebarHeaderAdd} />
  )
  const sidebarContent = (
    <Box>
      <Header mini level="5">
        <FormattedMessage {...messages.vcheckNameHeader} />
      </Header>

      <Box>
        <FormField
          component={TextInput}
          label={intl.formatMessage(messages.vcheckNameLabel)}
          name="vcheck_name"
          id="vcheck_name"
          onChange={e => dispatch({ fieldName: 'name', data: e.target.value })}
          value={{ value: scheduledState.name }}
          required
        />
      </Box>

      <Header mini level="5">
        <FormattedMessage {...messages.scheduleHeader} />
      </Header>

      <Text size="12px">
        <FormattedMessage {...messages.scheduleDetails} />
      </Text>

      <VCheckScheduler
        state={scheduledState}
        dispatch={dispatch}
        lowerBoundDate={moment(new Date()).format('YYYY-MM-DD')}
        messagecallback={verifyFuction}
      ></VCheckScheduler>

      <Box>{error && <Message message={error} isError />}</Box>
      {errorMessage !== null ? (
        <Text size="12px" color="red">
          {errorMessage}{' '}
        </Text>
      ) : (
        seterrorMessage('')
      )}
    </Box>
  )

  if (pageLoadError) {
    return (
      <Box fill justify="center" align="center">
        <Message message={pageLoadError} isError />
      </Box>
    )
  }

  return (
    <Box margin={{ bottom: 'large' }} width={{ max: '700px' }}>
      <Box direction="row" justify="between">
        <Header mini level="5">
          <FormattedMessage {...messages.randomVCheckHeader} />
        </Header>

        <Checkbox
          id="random_toggle"
          toggle
          checked={showRandom}
          onChange={e => setShowRandom(e.target.checked)}
        ></Checkbox>
      </Box>

      <TextBlock
        multiLineString={messages.randomVCheckDescription.defaultMessage}
        fontSize="xsmall"
      ></TextBlock>

      {showRandom && randomState.rStartTime && randomState.rEndTime && (
        <Form validate="blur">
          <Box direction="row-responsive" gap="small">
            <FormField
              name="random_frequency"
              id="random_frequency"
              label={intl.formatMessage(messages.rFreqLabel)}
            >
              <Select
                plain
                size="small"
                name="random_frequency"
                id="random_frequency"
                options={[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                ]}
                value={randomFrequencyValue}
                onChange={event => {
                  updateRandomState({ fieldName: 'rFrequency', data: event.option })
                  setRandomFrequencyValue(event.target.value)
                }}
                style={{ maxWidth: '100px' }}
              ></Select>
            </FormField>

            <FormField
              name="random_start"
              id="random_start"
              step={1800} // 30 minutes in seconds
              label={intl.formatMessage(messages.rStartLabel)}
              value={{ value: moment(randomState.rStartTime, 'HH:mm:ss').format('hh:mm A') }}
              onChange={e => {
                updateRandomState({
                  fieldName: 'rStartTime',
                  data: moment(e.target.value, 'hh:mm A').format('HH:mm:ss'),
                })
              }}
            >
              <MaskedInput
                plain
                name="random_start"
                id="random_start"
                mask={[
                  {
                    length: [1, 2],
                    options: time.hours,

                    regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                    placeholder: 'HH',
                  },
                  { fixed: ':' },
                  {
                    length: [1, 2],
                    options: time.mins,
                    regexp: /^0?[1-9]$|^[0-5][0-9]$|^[0-9]$/,
                    placeholder: 'MM',
                  },
                  { fixed: ' ' },
                  {
                    length: 2,
                    options: ['AM', 'PM'],
                    regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                    placeholder: 'AM/PM',
                  },
                ]}
                value={randomStartValue}
                onChange={event => setRandomStartValue(event.target.value)}
              />
            </FormField>

            <FormField
              name="random_end"
              id="random_end"
              label={intl.formatMessage(messages.rEndLabel)}
              value={{ value: moment(randomState.rEndTime, 'HH:mm:ss').format('hh:mm A') }}
              onChange={e => {
                updateRandomState({
                  fieldName: 'rEndTime',
                  data: moment(e.target.value, 'hh:mm A').format('HH:mm:ss'),
                })
              }}
              validate={[
                () => {
                  const startTime = moment(randomState.rStartTime, 'HH:mm:ss')
                  const endTime = moment(randomState.rEndTime, 'HH:mm:ss')
                  const diffTime = (startTime - endTime) / 60000
                  const minutes = diffTime % 60
                  let hours = (diffTime - minutes) / 60
                  let isValidationError = false
                  isValidationError = !!(
                    startTime < endTime &&
                    endTime.diff(moment(startTime), 'minutes') < 60 * randomState.rFrequency
                  )
                  hours = Math.abs(hours) - 24
                  if (!isValidationError)
                    isValidationError = !!(
                      startTime > endTime && Math.abs(hours) < 1 * randomState.rFrequency
                    )
                  if (isValidationError) {
                    setValidationError(true)
                    return '1 hour window required for each VCheck'
                  }
                  setValidationError(false)
                  return undefined
                },
              ]}
            >
              <MaskedInput
                plain
                name="random_end"
                id="random_end"
                mask={[
                  {
                    length: [1, 2],
                    options: time.hours,
                    regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                    placeholder: 'HH',
                  },
                  { fixed: ':' },
                  {
                    length: [1, 2],
                    options: time.mins,
                    regexp: /^0?[1-9]$|^[0-5][0-9]$|^[0-9]$/,
                    placeholder: 'MM',
                  },
                  { fixed: ' ' },
                  {
                    length: 2,
                    options: ['AM', 'PM'],
                    regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                    placeholder: 'AM/PM',
                  },
                ]}
                value={randomEndValue}
                onChange={event => setRandomEndValue(event.target.value)}
              />
            </FormField>
          </Box>
        </Form>
      )}

      <Divider />

      <Header mini level="5" margin={{ top: 'large', bottom: '0px' }}>
        <FormattedMessage {...messages.scheduledVCheckHeader} />
      </Header>

      <Box direction="row-responsive" align="center" justify="between">
        <Text size="xsmall">
          <FormattedMessage {...messages.scheduledVCheckDescription} />
        </Text>

        <Button
          color="focus"
          icon={<Add size="small" />}
          label={intl.formatMessage(messages.addScheduleButtonLabel)}
          onClick={() => {
            dispatch({ type: 'reset' })
            openModal()
          }}
        />
      </Box>

      {scheduledVChecks && scheduledVChecks.length > 0 && (
        <ScheduledVCheckTable editable vchecks={scheduledVChecks} editInSidebar={editInSidebar} />
      )}

      <Box>{error && <Message message={error} isError />}</Box>

      <Box direction="row-responsive" gap="small" justify="between">
        {/* Back */}
        <Button
          label={intl.formatMessage(messages.backButtonLabel)}
          primary={false}
          onClick={saveAndBack}
          color="status-unknown"
        />

        {/* Save & Continue */}
        {/* directs client to Verify tab or Summary Page based on verified status */}
        {!clientIsVerified ? (
          <Button
            color="accent-1"
            label={intl.formatMessage(messages.continueButtonLabel)}
            onClick={saveScheduleAndContinue}
            clientIsVerified={clientIsVerified}
            disabled={validationError || loading}
          />
        ) : (
          <Link to={`/clients/summary?id=${clientId}`}>
            <Button
              color="accent-1"
              label={intl.formatMessage(messages.closeButtonLabel)}
              onClick={saveScheduleAndContinue}
              clientIsVerified={clientIsVerified}
              disabled={loading}
            />
          </Link>
        )}
      </Box>

      {showSidebar && (
        <WizardSidebar
          disabled={loading}
          onClose={onClose}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isFormValid={isFormValid}
          isAbleToDelete={!!scheduledState.exists}
          header={sideBarHeader}
          content={sidebarContent}
        ></WizardSidebar>
      )}
    </Box>
  )
}

const Divider = styled.div`
  margin-top: 20px;
  border: 0.5px solid lightGrey;
  width: 100%;
`

AddClientSchedule.propTypes = {
  intl: intlShape.isRequired,
  goToStep: PropTypes.func.isRequired,
  clientIsVerified: PropTypes.bool,
}

export default injectIntl(AddClientSchedule)
