import { defineMessages } from 'react-intl'

export const scope = 'app.components.AgencyZonesTable'

/*
 * AgencyZonesTable Messages
 *
 * This contains all the text for the AgencyZonesTable component.
 */
export default defineMessages({
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Name',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Agency Name',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Address',
  },
})
