import { defineMessages } from 'react-intl'

export const scope = 'app.containers.ClientVCheckPage'

/*
 * ClientVCheckPage Messages
 *
 * This contains all the text for the ClientVCheckPage container.
 */
export default defineMessages({
  loading: {
    id: `${scope}.header`,
    defaultMessage: 'Loading Client Data...',
  },
  phraseHeader: {
    id: `${scope}.phraseHeader`,
    defaultMessage: 'Random Phrase',
  },
  reviewHeader: {
    id: `${scope}.reviewHeader`,
    defaultMessage: 'Review',
  },
  notesHeader: {
    id: `${scope}.notesHeader`,
    defaultMessage: 'Notes',
  },
  cancelButton: {
    id: `${scope}.cancelButton`,
    defaultMessage: 'Cancel',
  },
  resetButton: {
    id: `${scope}.deactivateButton`,
    defaultMessage: 'Reset',
  },
  resetMessage: {
    id: `${scope}.deactivateMessage`,
    defaultMessage:
      'Do you want to reset the facial recognition reference image? This will prompt the individual to perform an on-demand VCheck so that a new reference image sequence may be captured.',
  },
  confirmModalHeader: {
    id: `${scope}.confirmModalHeader`,
    defaultMessage: 'Are you sure',
  },
})
