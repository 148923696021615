import { RRule } from 'rrule'

const rruleBuilders = {
  buildRRule(schedule) {
    // grab data pieces from all schedule types
    const { startTime, startDate } = schedule
    const { endTime, endDate } = schedule
    const count = schedule.occurrences
    let interval = schedule.repeatEvery
    let freq
    let byweekday
    let until

    const dtstart = this.buildFullDate(startDate, startTime)

    // if Does Not Repeat, return default rrule with dtstart
    if (schedule.repeat === 'Does Not Repeat') {
      const rule = new RRule({
        dtstart,
        freq: RRule.DAILY,
        count: 1,
      })
      return rule
    }

    // else, build rrule

    // check for frequency
    if (schedule.repeat === 'Daily') {
      freq = RRule.DAILY
      interval = 1
    }
    if (schedule.repeat === 'Custom') {
      switch (schedule.frequency) {
        case 'Day(s)':
          freq = RRule.DAILY
          break
        case 'Week(s)':
          freq = RRule.WEEKLY
          break
        case 'Month(s)':
          freq = RRule.MONTHLY
          break
        default:
          freq = null
      }
    }

    // check for byweekday
    if (schedule.weekdays) {
      byweekday = []
      if (schedule.weekdays.includes('M')) {
        byweekday.push(RRule.MO)
      }
      if (schedule.weekdays.includes('Tu')) {
        byweekday.push(RRule.TU)
      }
      if (schedule.weekdays.includes('W')) {
        byweekday.push(RRule.WE)
      }
      if (schedule.weekdays.includes('Th')) {
        byweekday.push(RRule.TH)
      }
      if (schedule.weekdays.includes('F')) {
        byweekday.push(RRule.FR)
      }
      if (schedule.weekdays.includes('Sa')) {
        byweekday.push(RRule.SA)
      }
      if (schedule.weekdays.includes('Su')) {
        byweekday.push(RRule.SU)
      }
    }

    if (endDate) {
      const dateString = endDate.concat('T', endTime, ':00.000Z')
      until = new Date(dateString)
    }

    const rule = new RRule({
      dtstart,
      freq,
      interval,
      byweekday,
      until,
      count,
    })
    return rule
  },
  rruleToText(schedule) {
    const rrule = this.buildRRule(schedule)
    if (rrule) {
      return rrule.toText()
    }
    return null
  },
  rruleToString(schedule) {
    const rrule = this.buildRRule(schedule)
    if (rrule) {
      return rrule.toString()
    }
    return null
  },
  buildFullDate(date, time) {
    if (date && time) {
      const dateString = date.concat('T', time, ':00.000Z')
      return new Date(dateString)
    }
    return null
  },
  rruleStringToText(rruleString) {
    // check for Does Not Repeat default format
    if (rruleString.split('RRULE:').pop() === 'FREQ=DAILY;COUNT=1') {
      return 'Does Not Repeat'
    }
    const rrule = RRule.fromString(rruleString)
    return rrule.toText()
  },
}

export default rruleBuilders
