/* eslint-disable prettier/prettier */
import { observable, action } from 'mobx'
import { createContext } from 'react'

import { BaseStore } from './BaseStore'

import {
  getAgencyZones as getAgencyZonesService,
  postZoneToAgency as postZoneToAgencyService,
  updateAgencyZoneById as updateAgencyZoneByIdService,
  deleteAgencyZoneById as deleteAgencyZoneByIdService,
  getZonesByDistributorId as getZonesByDistributorIdService,
} from '../services/zone.service'

const INITIAL_STATE = {
  agencies: [],
  agencyZones: [],
  distributorZones: [],
}

export class ZoneStore extends BaseStore {
  @observable agencyZones

  @observable distributorZones

  @observable agencies

  // DISTRIBUTOR ZONES

  @action.bound
  async getZonesByDistributorId(
    distributorId,
    setLoading,
    setError,
    currentPage,
    rowPerPage,
    agencyId,
    searchText,
  ) {
    const data = await getZonesByDistributorIdService(
      distributorId,
      setError,
      setLoading,
      currentPage,
      rowPerPage,
      agencyId,
      searchText,
    )

    if (data) {
      // format locations
      const formattedLocations = []
      data.results.forEach(location => {
        if (location.address) {
          const formattedAddress = this.formatAddress(location.address)
          const formattedLocation = {
            ...location,
            formatted_address: formattedAddress,
          }
          formattedLocations.push(formattedLocation)
        }
      })
      this.distributorZones = formattedLocations
    }
    setLoading(false)
    return data
  }

  // AGENCY ZONES
  @action.bound
  async getAgencyZones(agencyId, setError, setLoading, currentPage, rowsPerPage, queryFilter) {
    const data = await getAgencyZonesService(
      agencyId,
      setError,
      setLoading,
      currentPage,
      rowsPerPage,
      queryFilter,
    )

    if (data) {
      // format locations
      const formattedLocations = []
      data.results.forEach(location => {
        // add formatted_address field
        if (location.address) {
          const formattedAddress = this.formatAddress(location.address)
          const formattedLocation = {
            ...location,
            formatted_address: formattedAddress,
          }
          formattedLocations.push(formattedLocation)
        }
      })
      this.agencyZones = formattedLocations
      this.distributorZones = formattedLocations
    } else {
      this.agencyZones = []
      this.distributorZones = []
    }
    return data
  }

  @action.bound
  async postZoneToAgency(agencyId, zone, setLoading, setError) {
    const addedZone = await postZoneToAgencyService(agencyId, zone, setLoading, setError)

    if (addedZone) {
      // add formatted_address field
      const formattedAddress = this.formatAddress(addedZone.address)
      const formattedZone = {
        ...addedZone,
        formatted_address: formattedAddress,
      }
      this.agencyZones = [...this.agencyZones, formattedZone]
      this.distributorZones = [...this.distributorZones, formattedZone]
      return formattedZone
    }
    return addedZone
  }

  @action.bound
  async updateAgencyZoneById(agencyId, zoneId, zone, setLoading, setError) {
    const updatedZone = await updateAgencyZoneByIdService(
      agencyId,
      zoneId,
      zone,
      setLoading,
      setError,
    )

    if (updatedZone) {
      // add formatted_address field
      const formattedAddress = this.formatAddress(updatedZone.address)
      const formattedZone = {
        ...updatedZone,
        formatted_address: formattedAddress,
      }
      const filteredZones = this.agencyZones.filter(location => location.id !== zoneId)
      const filteredDistributorZones = this.distributorZones.filter(
        location => location.id !== zoneId,
      )

      this.agencyZones = [...filteredZones, formattedZone]
      this.distributorZones = [...filteredDistributorZones, formattedZone]
      return formattedZone
    }
    return updatedZone
  }

  @action.bound
  async deleteAgencyZoneById(agencyId, zoneId, setLoading, setError) {
    const requestSuccess = await deleteAgencyZoneByIdService(
      agencyId,
      zoneId,
      setLoading,
      setError,
    )

    if (requestSuccess) {
      const filteredZones = this.agencyZones.filter(location => location.id !== zoneId)
      const filtereddistributorZones = this.distributorZones.filter(
        location => location.id !== zoneId,
      )

      this.agencyZones = filteredZones
      this.distributorZones = filtereddistributorZones
    }
    return requestSuccess
  }

  formatAddress(address) {
    if (address.address_1 && address.city && address.state) {
      const formattedAddress = address.address_1.concat(
        ', ',
        address.city,
        ', ',
        address.state,
        ', ',
        address.country,
        ' ',
        address.zip_code,
      )
      return formattedAddress
    }
    return null
  }
}
export const zones = new ZoneStore(INITIAL_STATE)
export const ZoneStoreContext = createContext(zones)
