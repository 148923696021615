const placesAddressParser = {
  /**
   *
   * buildAddressField parses google address terms into format needed by BE api
   * example fields from google places api:
   * address_components = [
   *     { long_name: '3462', short_name: '3462', types: ['street_number'] },
   *     { long_name: 'Walnut Street', short_name: 'Walnut St', types: ['route'] },
   *     { long_name: 'Five Points', short_name: 'Five Points', types: ['neighborhood'] },
   *     { long_name: 'Denver', short_name: 'Denver', types: ['locality'] },
   *     { long_name: 'Denver County', short_name: 'Denver County', types: ['administrative_area_level_2'] },
   *     { long_name: 'Colorado', short_name: 'CO', types: ['administrative_area_level_1'] },
   *     { long_name: 'United States', short_name: 'US', types: ['country'] },
   *     { long_name: '80205', short_name: '80205', types: ['postal_code'] }
   *   ]
   * types: ["street_address"] or ["premise"]
   *
   * desired format for api
   *   "address": {
   *     "address_1": "3462 Walnut Street",
   *     "address_2": "",
   *     "city": "Denver",
   *     "state": "CO",
   *     "zip_code": "80205"
   *   },
   *
   */
  buildAddressField(googlePlacesData) {
    if (googlePlacesData && googlePlacesData.address_components) {
      const terms = googlePlacesData.address_components
      let addressFields
      let streetNumber
      let streetName
      let city
      let state
      let zip
      let country
      let administrativeArea2
      let administrativeArea3
      terms.forEach(term => {
        if (term.types.includes('street_number')) {
          streetNumber = term.short_name
        } else if (term.types.includes('route')) {
          streetName = term.short_name
        } else if (term.types.includes('locality')) {
          city = term.short_name
        } else if (term.types.includes('administrative_area_level_1')) {
          state = term.short_name
        } else if (term.types.includes('administrative_area_level_2')) {
          administrativeArea2 = term.short_name
        } else if (term.types.includes('administrative_area_level_3')) {
          administrativeArea3 = term.short_name
        } else if (term.types.includes('postal_code')) {
          zip = term.short_name
        } else if (term.types.includes('country')) {
          country = term.short_name
        }
      })

      if (city === undefined) {
        city = administrativeArea3
          ? administrativeArea2.concat(', ', administrativeArea3)
          : administrativeArea2
      }

      // Disable zip code check

      if (streetName && city && state && country) {
        addressFields = {
          address_1: streetNumber ? streetNumber.concat(' ', streetName) : streetName,
          address_2: '',
          city,
          state,
          zip_code: zip || '',
          country,
        }
      }
      return addressFields
    }
    return null
  },
}

export default placesAddressParser
