const colors = {
  green: 'rgb(0, 138, 34)',
  gold: 'rgb(224, 189, 92)',
  darkGrey: 'rgb(68,68,68)',
  mediumGrey: 'rgb(170,170,170)',
  lightGrey: 'rgb(238,238,238)',
  darkGreyBlue: 'rgb(40,75,99)',
  darkerGreyBlue: 'rgb(33, 62, 82)',
  lightTeal: 'rgb(84, 153, 157)',
  teal: 'rgb(60, 111, 114)',
  teal80: 'rgba(60, 111, 114, .8)',
  red: 'rgb(174, 72, 69)',
  purple: 'rgb(57,136,254)',
  brightRed: 'rgb(255, 7, 58)',
  brightGreen: 'rgb(63, 222, 33)',
}

colors.primary = colors.darkGreyBlue
colors.secondary = colors.teal
colors.secondary80 = colors.teal80
colors.primaryText = colors.darkGrey
colors.background = colors.lightGrey
colors.error = colors.red

export default colors
