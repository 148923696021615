import { describe, PropTypes } from 'react-desc'

export const doc = Collapsible => {
  const DocumentedCollapsible = describe(Collapsible)
    .description('A Collapsible')
    .usage(
      `import { Collapsible } from 'components/Collapsible';
<Collapsible />`,
    )

  DocumentedCollapsible.propTypes = {
    id: PropTypes.string
      .description('The DOM id attribute value to use for the element.')
      .defaultValue(false),
  }

  return DocumentedCollapsible
}
