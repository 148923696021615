/* eslint-disable react/prop-types */
import React from 'react'
import { Box } from 'grommet'

import { doc } from './Box.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * Box
 *
 */
function createBox({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createBox,
})
