import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

// Components
import { Box } from '../Box'
// import { DateTimeInput } from '../DateTimeInput'
import { Form, FormField } from '../Form'
import { Text } from '../Text'

// Utils, Services & Messages
import { BODY_FONT } from '../../utils/theme'
import messages from './DateRangePicker.messages'

/**
 * DateRangePicker
 */
const DateRangePicker = ({ dateRange, setDateRange }) => (
  <>
    <Form>
      <Box gap="small" direction="row">
        <Box direction="row" gap="xsmall" align="center" justify="between">
          <Text size="small">
            <FormattedMessage {...messages.from} />
          </Text>

          <Box direction="row" gap="xsmall">
            <StyledFormField
              name="start_date"
              label=" "
              onChange={e => setDateRange({ fieldName: 'startDate', data: e.target.value })}
            >
              <StyledInput type="date" value={dateRange.startDate} />
            </StyledFormField>

            <StyledFormField
              name="start_time"
              label=" "
              onChange={e => setDateRange({ fieldName: 'startTime', data: e.target.value })}
            >
              <StyledInput type="time" value={dateRange.startTime} />
            </StyledFormField>
          </Box>
        </Box>

        <Box direction="row" gap="xsmall" align="center" justify="between">
          <Text size="small">
            <FormattedMessage {...messages.to} />
          </Text>

          <Box direction="row" gap="xsmall">
            <StyledFormField
              name="end_date"
              label=" "
              onChange={e => setDateRange({ fieldName: 'endDate', data: e.target.value })}
            >
              <StyledInput type="date" value={dateRange.endDate} />
            </StyledFormField>

            <StyledFormField
              name="end_time"
              label=" "
              onChange={e => setDateRange({ fieldName: 'endTime', data: e.target.value })}
            >
              <StyledInput type="time" value={dateRange.endTime} />
            </StyledFormField>
          </Box>
        </Box>
      </Box>
    </Form>
  </>
)

const StyledFormField = styled(FormField)`
  margin-bottom: 0 !important;
`

const StyledInput = styled.input`
  font-family: ${BODY_FONT}, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border: none;
  margin: none;
`

DateRangePicker.propTypes = {
  dateRange: PropTypes.any,
  setDateRange: PropTypes.func,
}

export default DateRangePicker
