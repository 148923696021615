/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { BODY_FONT } from '../../utils/theme'

const StyledTimeInput = styled.input`
  font-family: ${BODY_FONT}, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-radius: 10px;
  border: none;
`

/**
 * TimeInput
 */
const TimeInput = ({ type, ...rest }) => {
  if (type) {
    // eslint-disable-next-line no-console
    console.warn('type prop does not have an effect on TimeInput!')
  }

  return <StyledTimeInput type="time" {...rest} />
}

export default TimeInput
