/* eslint-disable react/prop-types */
import React from 'react'
import { Heading } from 'grommet'

import { doc } from './Header.doc'
import { createWithDoc } from '../../utils/helpers'

import { BODY_FONT, HEADER_FONT } from '../../utils/theme'

export const MINI_HEADER_STYLES = {
  letterSpacing: '1px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontFamily: `${BODY_FONT}, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  fontSize: 12,
}

/**
 *
 * Header
 *
 */
function createHeader({ mini = false, children, style = {}, ...rest }) {
  const additionalStyles = mini ? MINI_HEADER_STYLES : {}

  return (
    <Heading
      style={{
        fontFamily: `${HEADER_FONT}, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
        ...additionalStyles,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Heading>
  )
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createHeader,
})
