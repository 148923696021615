/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for async support
import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import Analytics from 'react-router-ga'
import { Grommet } from 'grommet'
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { Router } from 'react-router'
import FontFaceObserver from 'fontfaceobserver'
import * as Sentry from '@sentry/browser'
import 'sanitize.css/sanitize.css'

import { App } from 'containers/App'
import { LanguageProvider } from 'containers/LanguageProvider'

import { flags, FlagsProvider } from './utils/flags'
import theme from './utils/theme'
import trunk from './configureStore'
import { translationMessages } from './i18n'

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
import 'file-loader?name=.htaccess!./.htaccess' // eslint-disable-line import/extensions

// The env variable is not converted to a bool - must check for the explicit string
if (process.env.SENTRY_ENABLED === 'true')
  Sentry.init({
    release: process.env.SENTRY_RELEASE_GIT_HASH,
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
  })

// Observe loading of Lato
const bodyFontObserver = new FontFaceObserver('Lato', {})

// When Lato is loaded, add a font-family using Lato to the body
bodyFontObserver.load().then(() => {
  document.body.classList.add('fontLoaded')
})

const MOUNT_NODE = document.getElementById('app')

// Create MobX store with history
const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()
const stores = {
  routing: routingStore,
}

const history = syncHistoryWithStore(browserHistory, routingStore)

const render = messages => {
  trunk.init().then(() => {
    ReactDOM.render(
      <LanguageProvider messages={messages}>
        <Provider {...stores}>
          <FlagsProvider flags={flags}>
            <Router history={history}>
              {/* To view all analytics events in your browser console, set the `debug` prop */}
              <Analytics id={process.env.GOOGLE_ANALYTICS_ID}>
                <Grommet theme={theme} userAgent={navigator.userAgent} full>
                  <App />
                </Grommet>
              </Analytics>
            </Router>
          </FlagsProvider>
        </Provider>
      </LanguageProvider>,
      MOUNT_NODE,
    )
  })
}

if (module.hot) {
  /**
   * Hot reloadable React components and translation json files
   * modules.hot.accept does not accept dynamic dependencies,
   * have to be constants at compile-time
   */
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}

/**
 * Install ServiceWorker and AppCache in the end since
 * it's not most important operation and if main code fails,
 * we do not want it installed
 */
if (process.env.NODE_ENV === 'production') {
  console.info('[App:SW] Installing service worker...') // eslint-disable-line no-console
  require('offline-plugin/runtime').install() // eslint-disable-line global-require
  console.info('[App:SW] Service worker installed!') // eslint-disable-line no-console
}
