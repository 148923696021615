import { defineMessages } from 'react-intl'

export const scope = 'app.containers.AddClientZone'

/*
 * AddClientZone Messages
 *
 * This contains all the text for the AddClientZone container.
 */
export default defineMessages({
  loading: {
    id: `${scope}.header`,
    defaultMessage: 'Loading client data..',
  },
  locationHeader: {
    id: `${scope}.locationHeader`,
    defaultMessage: 'Locations and Zones',
  },
  locationDescription: {
    id: `${scope}.locationDescription`,
    defaultMessage:
      'Enter locations of interest, such as a client’s home address or clinic. Control geofences and curfew rules.',
  },
  locationButtonText: {
    id: `${scope}.locationButtonText`,
    defaultMessage: 'Add Location',
  },
  globalZoneHeader: {
    id: `${scope}.globalZoneHeader`,
    defaultMessage: 'Global Zones',
  },
  globalZoneDescription: {
    id: `${scope}.globalZoneDescription`,
    defaultMessage: 'Commonly used geographic zones.',
  },
  globalZoneButtonAddText: {
    id: `${scope}.globalZoneButtonAddText`,
    defaultMessage: 'Add Global Zones',
  },
  globalZoneButtonEditText: {
    id: `${scope}.globalZoneButtonEditText`,
    defaultMessage: 'Edit Global Zones',
  },
  locationSidebarHeaderAdd: {
    id: `${scope}.locationSidebarHeaderAdd`,
    defaultMessage: 'Add Location',
  },
  locationSidebarHeaderEdit: {
    id: `${scope}.locationSidebarHeaderEdit`,
    defaultMessage: 'Edit Location',
  },
  locationDetailsHeader: {
    id: `${scope}.locationDetailsHeader`,
    defaultMessage: 'Location Details',
  },
  locationNameLabel: {
    id: `${scope}.locationNameLabel`,
    defaultMessage: 'Name',
  },
  locationAddressLabel: {
    id: `${scope}.locationAddressLabel`,
    defaultMessage: 'Address',
  },
  zoneToggleHeader: {
    id: `${scope}.zoneToggleHeader`,
    defaultMessage: 'Zone',
  },
  zoneToggleDescription: {
    id: `${scope}.zoneToggleDescription`,
    defaultMessage: 'Add a geofence, curfew, or Zone of Interest rules to this location.',
  },
  geofenceHeader: {
    id: `${scope}.geofenceHeader`,
    defaultMessage: 'Geofence Diameter',
  },
  inclusionHeader: {
    id: `${scope}.inclusionHeader`,
    defaultMessage: 'Inclusion Zone',
  },
  inclusionDescription: {
    id: `${scope}.inclusionDescription`,
    defaultMessage:
      'Inclusion Zones are areas the client is required to be in for a set period of time, such as a curfew, or brief periods like scheduled appointments.',
  },
  scheduleHeader: {
    id: `${scope}.scheduleHeader`,
    defaultMessage: 'Schedule',
  },
  scheduleDescription: {
    id: `${scope}.scheduleDescription`,
    defaultMessage: 'Control curfews or pre-determined visits to this Zone.',
  },
  triggerHeader: {
    id: `${scope}.triggerHeader`,
    defaultMessage: 'Trigger VCheck',
  },
  triggerDescription: {
    id: `${scope}.triggerDescription`,
    defaultMessage: 'Automatically trigger a VCheck once the Client exits this Zone.',
  },
  ZOIHeader: {
    id: `${scope}.ZOIHeader`,
    defaultMessage: 'Zone of Interest',
  },
  ZOIDescription: {
    id: `${scope}.ZOIDescription`,
    defaultMessage: `Zones of Interest are areas relevant to a client's case such as WORK, TREATMENT, or COURT that can alert primary and back-up agents upon client arrival and departure. A Zone of Interest will also auto-prompt the client to perform a VCheck upon arrival.`,
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Name',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Address',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Type',
  },
  backButtonLabel: {
    id: `${scope}.backButtonLabel`,
    defaultMessage: 'Back',
  },
  continueButtonLabel: {
    id: `${scope}.continueButtonLabel`,
    defaultMessage: 'Save & Continue',
  },
  tableTypeExclustion: {
    id: `${scope}.tableTypeExclustion`,
    defaultMessage: 'Zone of Interest',
  },
  tableTypeInclustion: {
    id: `${scope}.tableTypeInclustion`,
    defaultMessage: 'Inclusion Zone',
  },
  tableTypeNone: {
    id: `${scope}.tableTypeNone`,
    defaultMessage: 'None',
  },
  geofenceDiameterLabel: {
    id: `${scope}.geofenceDiameterLabel`,
    defaultMessage: 'Geofence Diameter: ',
  },
  geofenceMinDiameterInfo: {
    id: `${scope}.geofenceMinDiameterInfo`,
    defaultMessage: 'Diameter must be greater than 300 feet ',
  },
  geofenceAreaLabel: {
    id: `${scope}.geofenceAreaLabel`,
    defaultMessage: 'Geofence Area: ',
  },
  geofenceMinAreaInfo: {
    id: `${scope}.geofenceMinAreaInfo`,
    defaultMessage: 'Area must be greater than 10,765 square feet ',
  },
  globalZonesSidebarHeader: {
    id: `${scope}.globalZonesSidebarHeader`,
    defaultMessage: 'Select Global Zones ',
  },
  globalZonesDetailsHeader: {
    id: `${scope}.globalZonesDetailsHeader`,
    defaultMessage:
      'Select global zones from the list below to add to the client.\nGlobal zones are always of type exclusion.',
  },
})
