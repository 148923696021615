/* eslint-disable react/prop-types */
import React from 'react'
import { FormUp, RadialSelected } from 'grommet-icons'

// Components
import { Box } from '../Box'
import { Button } from '../Button'
import { Text } from '../Text'

// Utils
import colors from '../../utils/colors'

export const InfoBox = ({ timestamp, width = '140px' }) => (
  <Box
    align="center"
    background="focus"
    margin={{ top: '15px', left: '-48px' }}
    style={{ background: colors.purple, height: '20px', width, maxWidth: width }}
  >
    <Text color="white" size="xsmall" weight="bold">
      {timestamp}
    </Text>
  </Box>
)

export const Cluster = ({ value, ...rest }) => (
  <Box align="center" pad="medium">
    <Button
      color="accent-4"
      label={
        <Text size="xsmall" weight="bold" color="white">
          {value}
        </Text>
      }
      size="small"
      style={{
        left: -10,
        margin: 0,
        padding: 0,
        position: 'absolute',
        top: -10,
        width: `${24 + value.length * 3}px`,
      }}
      {...rest}
    />
  </Box>
)

export const MovingPin = ({ id, heading, over, setOver, timestamp, ...rest }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
    <Button
      onMouseOut={() => setOver(null)}
      onMouseOver={() => setOver(id)}
      plain
      primary={false}
      style={{
        background: colors.purple,
        borderRadius: 10,
        fontSize: 11,
        height: 20,
        left: -10,
        margin: 0,
        position: 'absolute',
        top: -10,
        transform: `rotate(${heading}deg)`,
        width: 20,
      }}
    >
      <FormUp color="white" size="20px" style={{ height: 20, width: 20 }} {...rest} />
    </Button>

    {over === id && <InfoBox timestamp={timestamp} />}
  </>
)
export const Pin = ({ id, over, setOver, timestamp, status, resourcetype, ...rest }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
    <Button
      onMouseOut={() => setOver(null)}
      onMouseOver={() => setOver(id)}
      plain
      primary={false}
      style={{ left: -10, margin: 0, position: 'absolute', top: -10 }}
    >
      {/* FIXME: Refactor the svg to a resuable component */}
      <Box direction="row">
        {status !== 'missed' && resourcetype === 'VCheckEvent' && (
          <Box style={{ position: 'absolute' }}>
            <svg fill="none" viewBox="0 0 18 18" height="25" width="25">
              <circle fill="#00B907" r="9" cy="9" cx="9"></circle>
              <path
                fill="white"
                d="M9 2L3 4V10C3 13.3141 5.68594 16 9 16C12.3141 16 15 13.3141 15 10V4L9 2ZM13.875 10C13.875 12.6922 11.6922 14.875 9 14.875C6.30781 14.875 4.125 12.6922 4.125 10V4.84375L9 3.125L13.875 4.84375V10Z"
              ></path>
              <path
                fill="white"
                d="M6.90978 8.42393C6.85764 8.37155 6.79568 8.32999 6.72743 8.30164C6.65919 8.27328 6.58602 8.25868 6.51212 8.25868C6.43822 8.25868 6.36505 8.27328 6.29681 8.30164C6.22857 8.32999 6.1666 8.37155 6.11446 8.42393C6.06209 8.47606 6.02053 8.53803 5.99217 8.60627C5.96382 8.67451 5.94922 8.74768 5.94922 8.82158C5.94922 8.89548 5.96382 8.96865 5.99217 9.0369C6.02053 9.10514 6.06209 9.1671 6.11446 9.21924L8.13635 11.2411L8.16916 11.2739C8.21847 11.3233 8.27705 11.3625 8.34153 11.3893C8.40601 11.416 8.47513 11.4298 8.54494 11.4298C8.61475 11.4298 8.68387 11.416 8.74835 11.3893C8.81283 11.3625 8.87141 11.3233 8.92072 11.2739L12.4129 7.78174C12.4623 7.73242 12.5015 7.67385 12.5283 7.60937C12.555 7.54489 12.5688 7.47577 12.5688 7.40596C12.5688 7.33615 12.555 7.26703 12.5283 7.20255C12.5015 7.13807 12.4623 7.07949 12.4129 7.03018L12.3692 6.98643C12.3199 6.93702 12.2613 6.89782 12.1968 6.87108C12.1323 6.84433 12.0632 6.83057 11.9934 6.83057C11.9236 6.83057 11.8545 6.84433 11.79 6.87108C11.7255 6.89782 11.6669 6.93702 11.6176 6.98643L8.54416 10.0583L6.90978 8.42393Z"
              ></path>
            </svg>
          </Box>
        )}
        {resourcetype !== 'VCheckEvent' && (
          <RadialSelected color="accent-4" size="25px" {...rest} />
        )}
      </Box>
    </Button>
    {over === id && <InfoBox timestamp={timestamp} />}
  </>
)
