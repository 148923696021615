import { filter, forEach, indexOf, map } from 'lodash'
import { getCoordinateObject } from './helpers'

/**
 *
 * Transform an array of clusters and points into an array of polyline configurations for lines
 * between consecutive moving points
 *
 * This is quite non-trivial, as clustering doesn't care about timestamps! This means that we
 * could wind up with all but 2 points clustered, but those two remaining points may not be
 * consecutive. Thus, we must use our original list of points (ordered by timestamp) to
 * determine if the remaining un-clustered points are indeed consecutive.
 *
 * Consider an arbitrary array of points:
 *   [
 *      { time: '12:00', id: 1, isMoving: false, coordinates: [1, 1] },
 *      { time: '12:05', id: 2, isMoving: false, coordinates: [1, 2] },
 *      { time: '12:10', id: 3, isMoving: false, coordinates: [1, 1] },
 *      { time: '12:15', id: 4, isMoving: true, coordinates: [1, 2] },
 *      { time: '12:20', id: 5, isMoving: true, coordinates: [1, 8] },
 *      { time: '12:25', id: 6, isMoving: true, coordinates: [1, 10] },
 *      { time: '12:30', id: 7, isMoving: false, coordinates: [2, 2] },
 *      { time: '12:35', id: 8, isMoving: true, coordinates: [2, 1] },
 *      { time: '12:40', id: 9, isMoving: true, coordinates: [1, 4] },
 *      { time: '12:45', id: 10, isMoving: true, coordinates: [1, 1] },
 *      { time: '12:50', id: 11, isMoving: false, coordinates: [1, 1] },
 *   ]
 *
 * Say these are clustered into:
 *   [
 *    [ [1, 1], [1, 2] ],
 *    [ [2, 1], [2, 2] ],
 *    [1, 4],
 *    [1, 8],
 *    [1, 10],
 *   ]
 *
 * It would be wrong to assume that a line should be drawn from [1, 8] -> [1, 10] -> [1, 4] as
 * these points were not visited sequentially. Without consulting the list of all points, we
 * have no way to know that [1, 8] -> [1, 10] is the correct polyline.
 *
 * ---
 *
 * The algorithm used is as follows:
 *
 * - For all points, ensure that points are sorted by timestamp.
 * - Replace all stationary points with `null`:
 *   [
 *      null,
 *      null,
 *      null,
 *      { time: '12:15', id: 4, isMoving: true, coordinates: [1, 2] },
 *      { time: '12:20', id: 5, isMoving: true, coordinates: [1, 8] },
 *      { time: '12:25', id: 6, isMoving: true, coordinates: [1, 10] },
 *      null,
 *      { time: '12:35', id: 8, isMoving: true, coordinates: [2, 1] },
 *      { time: '12:40', id: 9, isMoving: true, coordinates: [1, 4] },
 *      { time: '12:45', id: 10, isMoving: true, coordinates: [1, 1] },
 *      null,
 *   ]
 *
 * - Generate a list of all ids for current non-clustered points sorted by timestamp:
 * P(nonClusteredPoints) ->
 *   [
 *      { time: '12:40', id: 9, isMoving: true, coordinates: [1, 4] },
 *      { time: '12:20', id: 5, isMoving: true, coordinates: [1, 8] },
 *      { time: '12:25', id: 6, isMoving: true, coordinates: [1, 10] },
 *   ]
 *
 * - Identify and replace all ids that are in P(nonClusteredPoints) but not in P(movingPoints) and
 * remove them from P(movingPoints). Put another way, P(movingPoints) should only contain points
 * that exist in P(nonClusteredPoints)
 * P(movingPoints) ->
 *   [
 *      null,
 *      null,
 *      null,
 *      null,
 *      { time: '12:20', id: 5, isMoving: true, coordinates: [1, 8] },
 *      { time: '12:25', id: 6, isMoving: true, coordinates: [1, 10] },
 *      null,
 *      null,
 *      { time: '12:40', id: 9, isMoving: true, coordinates: [1, 4] },
 *      null,
 *      null,
 *   ]
 *
 * - From P(movingPoints), generate all consecutive points of a length greater than 1:
 * Polylines ->
 *   [
 *      { time: '12:20', id: 5, isMoving: true, coordinates: [1, 8] },
 *      { time: '12:25', id: 6, isMoving: true, coordinates: [1, 10] },
 *   ]
 */
const convertDateTo24Hour = date => {
  const elem = date.split(' ')
  const stSplit = elem[1].split(':')
  let stHour = stSplit[0]
  const stMin = stSplit[1]
  const stSec = stSplit[2]
  const stAmPm = elem[2]

  if (stAmPm === 'PM') {
    if (stHour !== '12') {
      stHour = stHour * 1 + 12
    }
  } else if (stAmPm === 'AM' && stHour === '12') {
    stHour -= 12
  }
  return `${elem[0]} ${stHour}:${stMin}:${stSec}`
}
export const generatePolylines = (points, pointsByCluster) => {
  const mapPoints = JSON.parse(JSON.stringify(points))
  // - For all points, ensure that points are sorted by timestamp
  mapPoints.map(item => {
    const point = item
    point.properties.timestamp = convertDateTo24Hour(point.properties.timestamp)
    return point
  })
  const pointsSorted = mapPoints.sort(function(a, b) {
    return new Date(a.properties.timestamp) - new Date(b.properties.timestamp)
  })

  // - Replace all stationary points with `null`
  const movingPoints = nullifyStationaryPoints(pointsSorted)

  // - Generate a list of all ids for current non-clustered points sorted by timestamp
  const nonClusteredPoints = getNonClusteredPoints(pointsByCluster)

  // - Identify and replace all ids that are in P(nonClusteredPoints) but not in P(movingPoints) and
  // remove them from P(movingPoints). Put another way, P(movingPoints) should only contain points
  // that exist in P(nonClusteredPoints)
  // eslint-disable-next-line no-unused-vars
  const polylinePoints = nullifyUnusedPoints(movingPoints, nonClusteredPoints)

  // - From P(movingPoints), generate all consecutive points of a length greater than 1
  return getPolylines(pointsSorted)
}

/**
 * Sort points by timestamp
 *
 * @param {array} points
 */
/**
 * Nullify points that are not marked as moving
 *
 * @param {array} points
 */
const nullifyStationaryPoints = points =>
  map(points, point => (point.properties.isMoving ? point : null))

/**
 * Filter out any points that are clusters
 *
 * @param {array} points The array of points to manipulate
 */
const getNonClusteredPoints = points => filter(points, point => !point.properties.cluster)

/**
 * Nullify points from the `points` array that are not found in the `pointsToInclude` array.
 *
 * The actual objects used are more complex, but here's a simplified example:
 * Input: ([1, 5, 6, 10], [5, 6, 7])
 * Out: [5, 6]
 *
 * @param {array} points The main array to manipulate
 * @param {array} pointsToInclude The points that should be kept in the main array. Any points that
 * are not found are nullified
 */
const nullifyUnusedPoints = (points, pointsToInclude) => {
  const idsToInclude = map(pointsToInclude, 'properties.eventId')
  return map(points, point =>
    indexOf(idsToInclude, point ? point.properties.eventId : null) !== -1 ? point : null,
  )
}

/**
 * Generate an array of polyline configurations, where a polyline configuration is an array of
 * coordinates. Polylines will only be plotted with 2+ points, though this generates all options
 * even with only a single point per line.
 *
 * The actual objects used are more complex, but here's a simplified example:
 * Input: [ 1, 4, 6, null, 2, null, 7, 8 ]
 * Output:[ [1, 4, 6], [7, 8] ]
 *
 * @param {array} points An array of nulls or point objects
 * @returns {array} An array of coordinates
 */
const getPolylines = points => {
  const polylines = []
  let polylineIndex = 0

  forEach(points, point => {
    // If there isn't a point, make sure that we're starting a new polyline
    // Otherwise, add the current point to the current polyline
    if (point === null) {
      if (polylines.length === polylineIndex + 1) polylineIndex += 1
    } else {
      // Attempt to add the coordinates to the current polyline
      // If the array doesn't exist at the given index, create it then add it
      try {
        polylines[polylineIndex].push(getCoordinateObject(point.geometry.coordinates))
      } catch (err) {
        polylines[polylineIndex] = []
        polylines[polylineIndex].push(getCoordinateObject(point.geometry.coordinates))
      }
    }
  })

  return polylines
}
