import React, { useEffect, useReducer, useRef, useState, useContext } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import { Add, Close, Trash } from 'grommet-icons'
import { observer } from 'mobx-react'
import { isEmail } from 'validator'
import PropTypes from 'prop-types'
import moment from 'moment'

// Components
import styled from 'styled-components'
import { RadioButton, CheckBox } from 'grommet'
import { Box } from '../../components/Box'
import { Button } from '../../components/Button'
import { DateInput } from '../../components/DateInput'
import { Form, FormField } from '../../components/Form'
import { Header } from '../../components/Header'
import { Message } from '../../components/Message'
import { Select } from '../../components/Select'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { Layer } from '../../components/Layer'
// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Styles
import { Section } from './AddClientInfo.styles'

// Utils, Services & Messages
import { difference, getCurrentDateWithOutMilliSeconds } from '../../utils/helpers'
import formatters from '../../utils/formatters'
import messages from './AddClientInfo.messages'
import useFlashMessage from '../../hooks/FlashMessage'
import { DistributorStoreContext } from '../../stores/DistributorStore'
// import { date } from 'mobx-sync'

/**
 *
 * AddClientInfo
 *
 * This container holds all components needed to build the first step
 * in the Add Client flow - Add Basic Info:
 *
 * - name * (first and last required)
 * - date of birth
 * - phone number *
 * - email address
 * - monitoring start date *
 * - monitoring end date
 * - primary agent *
 * - case number
 * - supervision type
 * - backup agents
 *
 */

const notificationInitialState = {
  first_name: '',
  last_name: '',
  title: '',
  email: '',
  phone: '',
  email_address: [],
}

const agentReducer = (state, action) => {
  if (action.type === 'reload') {
    return action.data
  }

  const result = { ...state }
  return result
}

const selectedAgentsReducer = (state, action) => {
  if (action.type === 'reload') {
    return action.data
  }

  const result = { ...state }
  return result
}

const AddClientInfo = observer(({ intl, clientInitialState, goToStep }) => {
  const {
    distributorClientSelectedAgencyId,
    postClientToAgency,
    updateClientInAgency,
    setClientBasicInfo,
    getClientById,
    setDistributorClientSelectedAgency,
  } = useContext(ClientWizardStoreContext)
  const { user, isDistributor } = useContext(UserStoreContext)
  const {
    getAgencyById,
    getAgentsByAgency,
    getSupervisionTypes,
    getTimezones,
    reactivateClient,
  } = useContext(AgencyStoreContext)
  const { agencies } = useContext(DistributorStoreContext)
  const [agencyId, setAgencyId] = useState(!isDistributor ? user.agencyId : '')
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const { message: notificationError, showMessage: showNotificationError } = useFlashMessage(null)
  const [pageLoadError, showPageLoadError] = useState(false)
  const [loading, setLoading] = useState(false)
  const savedClientData = useRef({ ...clientInitialState } || {})
  const [notificationState, dispatch] = useReducer(sidebarReducer, notificationInitialState)

  const [displayPhone, setDisplayPhone] = useState()
  const [client, setClient] = useReducer(
    (state, updatedField) => ({ ...state, ...updatedField }),
    clientInitialState,
  )
  const [isExistingClient, setIsExistingClient] = useState(false)

  // agents and supervision types set in useEffect below
  const [supervisionOptions, setSupervisionOptions] = useState([])
  const [timezoneOptions, setTimezoneOptions] = useState([])

  const [allAgentsInAgencyWithIds, setAllAgentsInAgencyWithIds] = useReducer(
    selectedAgentsReducer,
    [],
  )
  const [agencyName, setAgencyName] = useState('')
  const [agentOptions, dispatchOptions] = useReducer(agentReducer, [])
  const [primaryAgent, setPrimaryAgent] = useState({ name: null, id: null })
  const [backupAgentOne, setBackupAgentOne] = useState({ name: null, id: null })
  const [backupAgentTwo, setBackupAgentTwo] = useState({ name: null, id: null })
  const [backupAgentThree, setBackupAgentThree] = useState({ name: null, id: null })
  const [numBackupAgents, setNumBackupAgents] = useState(0)
  const [activityReportConfig, setActivityReportConfig] = useState('None')
  const [alarmSummaryReportConfig, setAlarmSummaryReportConfig] = useState('None')
  const [agenciesOptions, setAgencyOptions] = useReducer((state, agent) => [...state, agent], [])
  const [showConfirmModal, setShowConfirmModal] = useState()
  const [addtionalEmail, setAdditionalEmail] = useState('')
  const [refresh, setRefresh] = useState(0)

  const currentPage = 1
  const rowPerPage = -1

  function sidebarReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }

    return notificationInitialState
  }

  const noActivityAlertOptions = [
    {
      lab: 'No GPS Alerts',
      val: -1.0,
    },
    {
      lab: '30 Minutes',
      val: 0.5,
    },
    {
      lab: '1 Hour',
      val: 1.0,
    },
    {
      lab: '2 Hours',
      val: 2.0,
    },
    {
      lab: '3 Hours',
      val: 3.0,
    },
    {
      lab: '6 Hours',
      val: 6.0,
    },
    {
      lab: '9 Hours',
      val: 9.0,
    },
    {
      lab: '12 Hours',
      val: 12.0,
    },
    {
      lab: '1 Day',
      val: 24.0,
    },
    {
      lab: '2 Days',
      val: 48.0,
    },
    {
      lab: '3 Days',
      val: 72.0,
    },
    {
      lab: '4 Days',
      val: 96.0,
    },
    {
      lab: '5 Days',
      val: 120.0,
    },
    {
      lab: '6 Days',
      val: 144.0,
    },
    {
      lab: '7 Days',
      val: 168.0,
    },
    {
      lab: '8 Days',
      val: 192.0,
    },
  ]

  /**
   * Grab client id from url params
   *
   * Set agent options, supervision types, and default information
   *
   * - If we do have a `clientIdParam`, then we retrieve fresh user data and set it in our local
   * client state
   * - If we don't have a `clientIdParam`, then we're creating a new user reset our store and client to an
   * empty object for the new user data
   *
   */

  useEffect(() => {
    async function setDefaultData() {
      // Retreive the client ID from URL parameters
      const urlParams = new URLSearchParams(window.location.search)
      const clientIdParam = urlParams.get('id')

      // set agent options for select dropdowns
      if (agenciesOptions.length <= 0) {
        agencies.forEach(item => {
          setAgencyOptions(item.name)
        })
      }

      const response = await getAgentsByAgency(
        agencyId,
        showPageLoadError,
        setLoading,
        currentPage,
        rowPerPage,
        null,
        null,
        true,
      )
      const agents = response.results
      const agentOptionsList = []
      const selectedAgentsInAgency = []
      agents.forEach(agent => {
        // check that first and last name exist, then format to display
        if (agent.user && agent.user.first_name && agent.user.last_name) {
          const displayName = agent.user.last_name.concat(', ', agent.user.first_name)
          agentOptionsList.push(displayName)
          selectedAgentsInAgency.push({
            name: displayName,
            id: agent.id,
          })
        }
      })
      setAllAgentsInAgencyWithIds({ type: 'reload', data: selectedAgentsInAgency })
      dispatchOptions({ type: 'reload', data: agentOptionsList })

      // set supervision types
      const supervisionTypes = await getSupervisionTypes(showPageLoadError, setLoading)
      setSupervisionOptions(supervisionTypes)

      // set timezone options and default from agency
      const timezones = await getTimezones(showPageLoadError, setLoading)
      setTimezoneOptions(timezones)

      const agency = await getAgencyById(agencyId, showPageLoadError, setLoading)

      setAgencyName(agency.name)
      if (!isDistributor) {
        setAgencyOptions(agency.name)
      }
      if (agency) {
        // set default fields
        setClient(
          clientInitialState || {
            ...client,
            timezone: agency.timezone,
          },
        )
      }

      // set client info
      if (clientIdParam) {
        setIsExistingClient(true)
        const currentClient = await getClientById(
          agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )

        savedClientData.current = { ...currentClient }
        setClient(currentClient)
        dispatch({
          type: 'update',
          data: {
            activityAdditionalEmail: currentClient.notifications[0]
              ? currentClient.notifications[0].required
              : false,
            alaramAdditionalEmail: currentClient.notifications[1]
              ? currentClient.notifications[1].required
              : false,
            email_address: currentClient.notifications[0]
              ? currentClient.notifications[0].email_addresses
              : [],
          },
        })
        setDisplayPhone(currentClient.phone)
        if (currentClient.activity_report_config) {
          setActivityReportConfig(currentClient.activity_report_config)
        }
        if (currentClient.alarm_report_config) {
          setAlarmSummaryReportConfig(currentClient.alarm_report_config)
        }

        // check for agents
        agents.forEach(agent => {
          const displayName = agent.user.last_name.concat(', ', agent.user.first_name)
          if (agent.id === currentClient.primary_agent_id) {
            setPrimaryAgent({ name: displayName, id: agent.id })
          }
          currentClient.backup_agents.forEach((agentId, index) => {
            if (agent.id === agentId) {
              switch (index) {
                case 1:
                  setBackupAgentTwo({ name: displayName, id: agent.id })
                  break
                case 2:
                  setBackupAgentThree({ name: displayName, id: agent.id })
                  break
                default:
                  setBackupAgentOne({ name: displayName, id: agent.id })
              }
            }
          })
        })
      } else {
        // if new client, make sure store is clear
        setClientBasicInfo({})
      }
    }
    if (agencyId !== '') {
      setDefaultData()
    } else {
      try {
        setAgencyId(distributorClientSelectedAgencyId || agencies[0].id)
        if (!distributorClientSelectedAgencyId && agencies.length > 0) {
          setDistributorClientSelectedAgency(agencies[0].id)
        }
      } catch (err) {
        showError('There are no agencies found in order to create a client')
      }
    }
  }, [agencyId])

  useEffect(() => {
    if (refresh > 0) {
      setLoading(true)
      setTimeout(() => {
        setClient({ ...client, monitoring_end: savedClientData.current.monitoring_end })
        setLoading(false)
      }, 1500)
    }
  }, [refresh])

  // save and continue functions
  const saveClient = async () => {
    // Check if client is not set. If so, POST (create).
    // Return id to pass to goToStep function so that subsequent steps can PATCH (update)
    if (!client.id) {
      if (client.monitoring_start == null) {
        client.monitoring_start = getCurrentDateWithOutMilliSeconds()
      }

      const addedClient = await postClientToAgency(
        agencyId,
        {
          ...client,
          primary_agent_id: primaryAgent.id,
          backup_agent_1_id: backupAgentOne.id ? backupAgentOne.id : null,
          backup_agent_2_id: backupAgentTwo.id ? backupAgentTwo.id : null,
          backup_agent_3_id: backupAgentThree.id ? backupAgentThree.id : null,
          activity_report_config: activityReportConfig,
          alarm_report_config: alarmSummaryReportConfig,
          case_id: client.case_id ? client.case_id : null,
          notifications: [
            {
              type: 'activity_email',
              required: notificationState.activityAdditionalEmail
                ? notificationState.activityAdditionalEmail
                : false,
              email_addresses: notificationState.email_address,
            },
            {
              type: 'alarm_email',
              required: notificationState.alaramAdditionalEmail
                ? notificationState.alaramAdditionalEmail
                : false,
              email_addresses: notificationState.email_address,
            },
          ],
        },
        setLoading,
        showError,
      )
      if (addedClient) {
        setClient({
          ...client,
          id: addedClient.id,
        })

        return addedClient.id
      }
      return null
    }

    // Otherwise, we need to PATCH (update)
    // Since we are saving an existing client, get the differences between the
    // stored data and the current form data to only send up pieces that have changed
    const clientDelta = difference(
      {
        ...client,
        primary_agent_id: primaryAgent.id,
        backup_agent_1_id: backupAgentOne.id ? backupAgentOne.id : null,
        backup_agent_2_id: backupAgentTwo.id ? backupAgentTwo.id : null,
        backup_agent_3_id: backupAgentThree.id ? backupAgentThree.id : null,
        case_id: client.case_id ? client.case_id : null,
        activity_report_config: activityReportConfig,
        alarm_report_config: alarmSummaryReportConfig,
      },
      savedClientData.current,
    )
    // Use initially saved monitoring start date (this value can't change after being set)
    clientDelta.monitoring_start = savedClientData.current.monitoring_start
    const monitoringEnd = clientDelta.monitoring_end
      ? moment.tz(clientDelta.monitoring_end, clientDelta.timezone).format('YYYY-MM-DD')
      : null
    if (
      (!savedClientData.current.is_active &&
        clientDelta.monitoring_end !== savedClientData.current.monitoring_end &&
        moment(monitoringEnd).isAfter(moment(savedClientData.monitoring_end), 'day')) ||
      (!savedClientData.current.is_active &&
        clientDelta.monitoring_end !== savedClientData.current.monitoring_end &&
        !clientDelta.monitoring_end)
    ) {
      const response = await reactivateClient(agencyId, client.id, setLoading, showError)
      if (response) {
        const updatedClient = await updateClientInAgency(
          agencyId,
          {
            id: client.id,
            ...clientDelta,
          },
          setLoading,
          showError,
        )
        if (updatedClient) {
          return client.id
        }
      }
    }
    const updatedClient = await updateClientInAgency(
      agencyId,
      {
        id: client.id,
        ...clientDelta,
        notifications: [
          {
            type: 'activity_email',
            required: notificationState.activityAdditionalEmail
              ? notificationState.activityAdditionalEmail
              : false,
            email_addresses: notificationState.email_address,
          },
          {
            type: 'alarm_email',
            required: notificationState.alaramAdditionalEmail
              ? notificationState.alaramAdditionalEmail
              : false,
            email_addresses: notificationState.email_address,
          },
        ],
      },
      setLoading,
      showError,
    )
    if (updatedClient) {
      return client.id
    }
    return null
  }

  const handleAgenciesChange = e => {
    const { target } = e
    const selectedOption = agencies.find(item => item.name === target.value)
    setDistributorClientSelectedAgency(selectedOption.id)
    setAgencyId(selectedOption.id)
  }

  const saveClientAndContinue = async () => {
    const clientId = await saveClient()

    if (clientId) {
      goToStep(1, clientId)
    }
  }

  const convertToNewTimezone = (date, newZone) => {
    // read in current datetime in current timezone, then convert to date only
    const dateString = moment.tz(date, client.timezone).format('YYYY-MM-DD')

    // return same date with new timezone
    return moment.tz(dateString, newZone).format()
  }

  // function to handle changes on all inputs except selects
  const handleChange = e => {
    const { target, option } = e

    let dateTime
    if (target.name === 'phone') {
      const phone = target.value.replace(/\s/g, '') // Remove spaces from phone number value

      setClient({
        ...client,
        [target.name]: phone,
      })
    } else if (target.name.includes('monitoring')) {
      if (!client.is_active && target.name === 'monitoring_end' && target.value === '') {
        openConfirmModal()
      }
      dateTime = target.value ? moment.tz(target.value, client.timezone).format() : null

      setClient({
        ...client,
        [target.name]: dateTime,
      })
    } else if (target.name === 'timezone') {
      client.monitoring_start = getCurrentDateWithOutMilliSeconds()
      const startDate = client.monitoring_start
        ? convertToNewTimezone(client.monitoring_start, option)
        : null
      const endDate = client.monitoring_end
        ? convertToNewTimezone(client.monitoring_end, option)
        : null

      setClient({
        ...client,
        monitoring_start: startDate,
        monitoring_end: endDate,
        timezone: option,
      })
    } else if (target.name === 'no_activity_alert_hours') {
      const alertHours = target.value

      setClient({
        ...client,
        // eslint-disable-next-line radix
        no_activity_alert_hours: parseFloat(alertHours),
      })
    } else {
      setClient({
        ...client,
        [target.name]: option || target.value,
      })
    }
  }

  const checkDisabledStatus = () => {
    const monitoringStart = client.monitoring_start
      ? moment.tz(client.monitoring_start, client.timezone).format('YYYY-MM-DD')
      : null
    if (
      client.is_active === false &&
      monitoringStart &&
      moment(monitoringStart).isAfter(moment(moment(), client.timezone), 'day')
    ) {
      return true
    }

    return false
  }

  // Confirmation Modal functions
  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }
  const openConfirmModal = () => {
    setShowConfirmModal(true)
  }

  const clearMonitoringEndDate = () => {
    closeConfirmModal()
  }

  const isFormValid = () =>
    client.first_name &&
    client.last_name &&
    client.date_of_birth &&
    client.phone &&
    client.timezone &&
    primaryAgent.id

  const stringValidation = {
    regexp: /[A-Za-z]/,
    message: 'Please enter a valid name',
  }

  /**
   * If we have a clientId, that means that we're editing an existing client.
   * If we don't have a client id in our data object, or don't have data for the
   * matching client yet, display a loading message
   */
  if (!client || loading || (isExistingClient && !client.monitoring_start)) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }

  return (
    <>
      <Box margin={{ bottom: 'large' }} width={{ max: '700px' }}>
        <Form validate="blur" onSubmit={saveClientAndContinue}>
          <Section>
            <Box direction="row-responsive" align="left" justify="between">
              <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
                <FormattedMessage {...messages.nameDobHeader} />
              </Header>
            </Box>

            <FormField
              component={TextInput}
              id="agency_name"
              name="agency_name"
              label={intl.formatMessage(messages.agencyNameLabel)}
              required
              value={{ value: '' }}
            >
              <Select
                size="small"
                name="agency_name"
                id="agency_name"
                options={agenciesOptions}
                value={agencyName}
                onChange={handleAgenciesChange}
                disabled={!isDistributor || isExistingClient}
              ></Select>
            </FormField>

            <FormField
              plain
              component={TextInput}
              name="first_name"
              id="first_name"
              label={intl.formatMessage(messages.firstNameLabel)}
              required
              onChange={handleChange}
              validate={stringValidation}
              value={{ value: client.first_name }}
            />

            <FormField
              plain
              component={TextInput}
              name="middle_name"
              id="middle_name"
              label={intl.formatMessage(messages.middleNameLabel)}
              onChange={handleChange}
              value={{ value: client.middle_name }}
            />

            <FormField
              plain
              component={TextInput}
              name="last_name"
              id="last_name"
              label={intl.formatMessage(messages.lastNameLabel)}
              required
              onChange={handleChange}
              validate={stringValidation}
              value={{ value: client.last_name }}
            />

            <FormField
              required
              component={DateInput}
              name="date_of_birth"
              id="date_of_birth"
              label={intl.formatMessage(messages.dobLabel)}
              onChange={handleChange}
              value={{ value: client.date_of_birth }}
            />
          </Section>

          <Section>
            <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
              <FormattedMessage {...messages.contactHeader} />
            </Header>

            <FormField
              label={intl.formatMessage(messages.phoneLabel)}
              name="phone"
              id="phone"
              onChange={handleChange}
              validate={[
                () => {
                  if (isValidPhoneNumber(displayPhone)) return true
                  return 'Please enter a valid phone number'
                },
              ]}
              value={{ value: displayPhone }}
            >
              <StyledPhoneInput
                name="phone"
                onChange={setDisplayPhone}
                value={displayPhone}
                international
                defaultCountry="US"
              />
            </FormField>

            <FormField
              plain
              component={TextInput}
              label={intl.formatMessage(messages.emailLabel)}
              onChange={handleChange}
              name="email"
              id="email"
              value={{ value: client.email }}
              validate={[
                () => {
                  if (client.email && !isEmail(client.email))
                    return 'Please enter a valid email address'
                  return undefined
                },
              ]}
            />
          </Section>

          <Section>
            <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
              <FormattedMessage {...messages.monitoringHeader} />
            </Header>

            <Text size="xsmall">
              <FormattedMessage {...messages.monitoringDescription} />
            </Text>

            <FormField
              component={DateInput}
              name="monitoring_start"
              id="monitoring_start"
              required
              disabled={isExistingClient}
              min={formatters.formatToDateInputDisplayFormat(moment())}
              label={intl.formatMessage(messages.startDateLabel)}
              onChange={handleChange}
              defaultValue={moment(new Date()).format('YYYY-MM-DD')}
              value={{
                value: client.monitoring_start
                  ? moment.tz(client.monitoring_start, client.timezone).format('YYYY-MM-DD')
                  : null,
              }}
              validate={[
                () => {
                  // don't run this validation if field is disabled
                  const monitoringStart = client.monitoring_start
                    ? moment.tz(client.monitoring_start, client.timezone).format('YYYY-MM-DD')
                    : null

                  if (
                    !isExistingClient &&
                    monitoringStart &&
                    moment(monitoringStart).isBefore(moment(), 'day')
                  )
                    return 'Monitoring start date cannot be in the past'
                  return undefined
                },
              ]}
            />

            <FormField
              disabled={checkDisabledStatus() || (isExistingClient && isDistributor)}
              component={DateInput}
              name="monitoring_end"
              id="monitoring_end"
              min={formatters.formatToDateInputDisplayFormat(moment())}
              label={intl.formatMessage(messages.endDateLabel)}
              onChange={handleChange}
              value={{
                value: client.monitoring_end
                  ? moment.tz(client.monitoring_end, client.timezone).format('YYYY-MM-DD')
                  : null,
              }}
              validate={[
                () => {
                  const monitoringEnd = client.monitoring_end
                    ? moment.tz(client.monitoring_end, client.timezone).format('YYYY-MM-DD')
                    : null
                  if (
                    monitoringEnd &&
                    moment(monitoringEnd).isBefore(moment().add(1, 'days'), 'day')
                  )
                    return 'Monitoring end date must be in the future'
                  return undefined
                },
              ]}
            />

            <FormField
              name="timezone"
              htmlFor="timezone"
              required
              label={intl.formatMessage(messages.timezoneLabel)}
              onChange={handleChange}
              value={{ value: client.timezone }}
            >
              <Select
                plain
                size="small"
                name="timezone"
                id="timezone"
                options={timezoneOptions}
                onChange={handleChange}
                value={client.timezone}
              ></Select>
            </FormField>
          </Section>

          <Section>
            <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
              <FormattedMessage {...messages.caseSupervisionHeader} />
            </Header>

            <Text size="xsmall">
              <FormattedMessage {...messages.caseSupervisionDescription} />
            </Text>

            <FormField
              htmlFor="primary_agent"
              id="primary_agent"
              label={intl.formatMessage(messages.primaryAgentLabel)}
              onChange={handleChange}
              value={{ value: primaryAgent.name }}
            >
              <Select
                plain
                size="small"
                name="primary_agent"
                id="primary_agent"
                options={agentOptions.filter(
                  a =>
                    a !== backupAgentOne.name &&
                    a !== backupAgentTwo.name &&
                    a !== backupAgentThree.name,
                )}
                onChange={option => {
                  const selectedAgent = allAgentsInAgencyWithIds.find(agent => {
                    if (agent.name === option.value) {
                      return true
                    }
                    return false
                  })
                  setPrimaryAgent(selectedAgent)
                }}
                value={primaryAgent.name}
              ></Select>
            </FormField>

            <FormField
              plain
              component={TextInput}
              name="case_id"
              id="case_id"
              label={intl.formatMessage(messages.caseNumberLabel)}
              onChange={handleChange}
              value={{ value: client.case_id }}
            />

            <FormField
              htmlFor="supervision_type"
              id="supervision_type"
              label={intl.formatMessage(messages.supervisionLabel)}
              onChange={handleChange}
              value={{ value: client.supervision_type }}
            >
              <Select
                plain
                size="small"
                name="supervision_type"
                id="supervision_type"
                options={supervisionOptions}
                onChange={handleChange}
                value={client.supervision_type}
                onClose={() => setSupervisionOptions(supervisionOptions)}
                onSearch={text => {
                  setSupervisionOptions(supervisionOptions.filter(option => option.includes(text)))
                }}
              ></Select>
            </FormField>

            <Box margin="10px"></Box>
            <Box direction="row-responsive" align="center" justify="between">
              <Text size="xsmall">
                <FormattedMessage {...messages.backupAgentHelp} />
              </Text>

              <Button
                data-testid="add-agent-button"
                align="start"
                color="focus"
                direction="row-responsive"
                disabled={numBackupAgents >= 3}
                icon={<Add size="small" />}
                label={intl.formatMessage(messages.addAgentButtonLabel)}
                margin={{ vertical: 'small' }}
                onClick={() => setNumBackupAgents(numBackupAgents + 1)}
              />
            </Box>

            {numBackupAgents > 0 || !!backupAgentOne.name ? (
              <Box fill="horizontal" direction="row" gap="small" align="center" justify="between">
                <FormField
                  fill
                  id="backup_agent_1"
                  label={intl.formatMessage(messages.backupAgentsLabel)}
                >
                  <Select
                    plain
                    pad="small"
                    size="small"
                    options={agentOptions.filter(
                      a =>
                        a !== primaryAgent.name &&
                        a !== backupAgentTwo.name &&
                        a !== backupAgentThree.name,
                    )}
                    onChange={option => {
                      const selectedAgent = allAgentsInAgencyWithIds.find(agent => {
                        if (agent.name === option.value) {
                          return true
                        }
                        return false
                      })
                      setBackupAgentOne(selectedAgent)
                    }}
                    value={backupAgentOne.name}
                  ></Select>
                </FormField>

                {backupAgentOne.name && (
                  <Box pad={{ top: 'small' }}>
                    <Button
                      onClick={() => setBackupAgentOne({ name: null, id: null })}
                      plain
                      primary={false}
                      icon={<Close size="small" color="status-error" />}
                      margin={{ vertical: 'none' }}
                    />
                  </Box>
                )}
              </Box>
            ) : null}

            {numBackupAgents > 1 || !!backupAgentTwo.name ? (
              <Box fill="horizontal" direction="row" gap="small" align="center" justify="between">
                <FormField fill id="backup_agent_2" label={' '}>
                  <Select
                    plain
                    pad="small"
                    size="small"
                    options={agentOptions.filter(
                      a =>
                        a !== primaryAgent.name &&
                        a !== backupAgentOne.name &&
                        a !== backupAgentThree.name,
                    )}
                    onChange={option => {
                      const selectedAgent = allAgentsInAgencyWithIds.find(agent => {
                        if (agent.name === option.value) {
                          return true
                        }
                        return false
                      })
                      setBackupAgentTwo(selectedAgent)
                    }}
                    value={backupAgentTwo.name}
                  ></Select>
                </FormField>

                {backupAgentTwo.name && (
                  <Box pad={{ bottom: 'small' }}>
                    <Button
                      onClick={() => setBackupAgentTwo({ name: null, id: null })}
                      plain
                      primary={false}
                      icon={<Close size="small" color="status-error" />}
                      margin={{ vertical: 'none' }}
                    />
                  </Box>
                )}
              </Box>
            ) : null}

            {numBackupAgents > 2 || !!backupAgentThree.name ? (
              <Box fill="horizontal" direction="row" gap="small" align="center" justify="between">
                <FormField fill id="backup_agent_3" label={' '}>
                  <Select
                    plain
                    pad="small"
                    size="small"
                    options={agentOptions.filter(
                      a =>
                        a !== primaryAgent.name &&
                        a !== backupAgentOne.name &&
                        a !== backupAgentTwo.name,
                    )}
                    onChange={option => {
                      const selectedAgent = allAgentsInAgencyWithIds.find(agent => {
                        if (agent.name === option.value) {
                          return true
                        }
                        return false
                      })
                      setBackupAgentThree(selectedAgent)
                    }}
                    value={backupAgentThree.name}
                  ></Select>
                </FormField>

                {backupAgentThree.name && (
                  <Box pad={{ bottom: 'small' }}>
                    <Button
                      onClick={() => setBackupAgentThree({ name: null, id: null })}
                      plain
                      primary={false}
                      icon={<Close size="small" color="status-error" />}
                      margin={{ vertical: 'none' }}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
          </Section>
          <Section>
            <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
              <FormattedMessage {...messages.autoReportHeader} />
            </Header>
            <Text size="small">
              <FormattedMessage {...messages.activityReportSubHeader} />
            </Text>
            <Box direction="column" gap="xsmall" gridArea="radioGroup">
              <Box direction="row-responsive" align="center" justify="between">
                <Text size="xsmall">
                  <FormattedMessage {...messages.noActivityReport} />
                </Text>
                <RadioButton
                  id="vcheck_noActivityReport"
                  checked={activityReportConfig === 'None'}
                  onChange={() => setActivityReportConfig('None')}
                ></RadioButton>
              </Box>
              <Box direction="row-responsive" align="center" justify="between">
                <Text size="xsmall">
                  <FormattedMessage {...messages.dailyActivityReport} />
                </Text>
                <RadioButton
                  id="vcheck_dailyActivityReport"
                  checked={activityReportConfig === 'Daily'}
                  onChange={() => setActivityReportConfig('Daily')}
                ></RadioButton>
              </Box>
              <Box direction="row-responsive" align="center" justify="between">
                <Text size="xsmall">
                  <FormattedMessage {...messages.weeklyActivityReport} />
                </Text>
                <RadioButton
                  id="vcheck_weeklyActivityReport"
                  checked={activityReportConfig === 'Weekly'}
                  onChange={() => setActivityReportConfig('Weekly')}
                ></RadioButton>
              </Box>
              <Box direction="row-responsive" align="center" justify="between">
                <Box style={{ width: '91%' }} pad={{ top: 'xxsmall' }} align="end">
                  <Text size="xsmall" wordBreak="break-all">
                    <FormattedMessage {...messages.activityReportAdditionalEmail} />
                  </Text>
                </Box>
                <CheckBox
                  id="vcheck_activityReportAdditionalEmail"
                  checked={notificationState.activityAdditionalEmail}
                  onChange={event =>
                    dispatch({ fieldName: 'activityAdditionalEmail', data: event.target.checked })
                  }
                ></CheckBox>
              </Box>
            </Box>
            <Box margin="10px"></Box>
            <Text size="small">
              <FormattedMessage {...messages.alarmReportSubHeader} />
            </Text>
            <Box direction="column" gap="xsmall" gridArea="radioGroup">
              <Box direction="row-responsive" align="center" justify="between">
                <Text size="xsmall">
                  <FormattedMessage {...messages.noAlarmReport} />
                </Text>
                <RadioButton
                  id="vcheck_noAlarmReport"
                  checked={alarmSummaryReportConfig === 'None'}
                  onChange={() => setAlarmSummaryReportConfig('None')}
                ></RadioButton>
              </Box>
              <Box direction="row-responsive" align="center" justify="between">
                <Text size="xsmall">
                  <FormattedMessage {...messages.dailyAlarmSummaryReport} />
                </Text>

                <RadioButton
                  id="vcheck_dailyAlarmSummaryReport"
                  checked={alarmSummaryReportConfig === 'Daily'}
                  onChange={() => setAlarmSummaryReportConfig('Daily')}
                ></RadioButton>
              </Box>

              <Box direction="row-responsive" align="center" justify="between">
                <Text size="xsmall">
                  <FormattedMessage {...messages.weeklyAlarmSummaryReport} />
                </Text>
                <RadioButton
                  id="vcheck_weeklyAlarmSummaryReport"
                  checked={alarmSummaryReportConfig === 'Weekly'}
                  onChange={() => setAlarmSummaryReportConfig('Weekly')}
                ></RadioButton>
              </Box>
              <Box direction="row-responsive" align="center" justify="between">
                <Box style={{ width: '90%' }} pad={{ top: 'xxsmall' }} align="end">
                  <Text size="xsmall" wordBreak="break-all">
                    <FormattedMessage {...messages.alaramReportAdditionalEmail} />
                  </Text>
                </Box>
                <CheckBox
                  id="vcheck_alaramReportAdditionalEmail"
                  checked={notificationState.alaramAdditionalEmail}
                  onChange={event =>
                    dispatch({ fieldName: 'alaramAdditionalEmail', data: event.target.checked })
                  }
                ></CheckBox>
              </Box>
            </Box>
          </Section>
          <Box direction="row-responsive" align="center" justify="between">
            <Text size="xsmall">
              <FormattedMessage {...messages.backupEmailHelp} />
            </Text>

            <Button
              data-testid="add-email-button"
              align="start"
              color="focus"
              direction="row-responsive"
              disabled={notificationState.email_address.length >= 3}
              icon={<Add size="small" />}
              label={intl.formatMessage(messages.addEmailLabel)}
              margin={{ vertical: 'small' }}
              onClick={() => dispatch({ fieldName: 'enableEmailAcvitvity', data: true })}
            />
          </Box>
          {notificationState.enableEmailAcvitvity && notificationState.email_address.length < 3 && (
            <Section>
              <Box direction="row-responsive" align="center" justify="between">
                <FormField
                  plain
                  component={TextInput}
                  label={intl.formatMessage(messages.enterEmailLabel)}
                  onChange={event => {
                    setAdditionalEmail(event.target.value)
                  }}
                  name="notification_email"
                  id="notification_email"
                  value={{ value: addtionalEmail }}
                  validate={[
                    () => {
                      if (addtionalEmail && !isEmail(addtionalEmail))
                        return 'Please enter a valid email address'
                      return undefined
                    },
                  ]}
                  style={{ width: '85%' }}
                />
                <Button
                  align="start"
                  color="focus"
                  direction="row-responsive"
                  label={intl.formatMessage(messages.addLabel)}
                  margin={{ bottom: '10px', left: 'small' }}
                  disabled={!isEmail(addtionalEmail)}
                  onClick={() => {
                    if (notificationState.email_address.includes(addtionalEmail)) {
                      showNotificationError('Duplicate email cannot be added')
                      return
                    }
                    dispatch({
                      fieldName: 'email_address',
                      data: [...notificationState.email_address, addtionalEmail],
                    })
                    setAdditionalEmail('')
                    showNotificationError(null)
                  }}
                />
              </Box>
              <Box
                margin={{
                  left: '15px',
                }}
              >
                {notificationError && <Message message={notificationError} isError />}
              </Box>
            </Section>
          )}
          {notificationState.email_address.length > 0 && (
            <Section>
              <Text size="xsmall">
                <FormattedMessage {...messages.notificatilemailLabel} />
              </Text>
              {notificationState.email_address.map(data => (
                <Box
                  direction="row-responsive"
                  align="center"
                  justify="between"
                  style={{
                    height: '45px',
                    borderRadius: '10px',
                    border: 'solid 1px #d9d9d9',
                    marginTop: '15px',
                  }}
                >
                  <Text margin={{ left: 'small' }} size="small">
                    {data}
                  </Text>
                  <Button
                    align="start"
                    color="focus"
                    direction="row-responsive"
                    style={{
                      color: '#d9d9d9',
                      backgroundColor: '#fff',
                    }}
                    badge={false}
                    icon={<Trash size="small" color="#000" />}
                    onClick={() =>
                      dispatch({
                        fieldName: 'email_address',
                        data: notificationState.email_address.filter(item => item !== data),
                      })
                    }
                  />
                </Box>
              ))}
            </Section>
          )}
          <Section>
            <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
              <FormattedMessage {...messages.noActivityAlertHeader} />
            </Header>

            <FormField
              htmlFor="no_activity_alert_hours"
              id="no_activity_alert_hours"
              label={intl.formatMessage(messages.noActivityAlertLabel)}
              onChange={handleChange}
              value={{ value: client.no_activity_alert_hours }}
            >
              <Select
                plain
                size="small"
                name="no_activity_alert_hours"
                id="no_activity_alert_hours"
                options={noActivityAlertOptions}
                labelKey="lab"
                valueKey={{
                  key: 'val',
                  reduce: true,
                }}
                onChange={handleChange}
                value={client.no_activity_alert_hours}
                defaultValue={-1.0}
              ></Select>
            </FormField>
            <Text size="xsmall">
              <FormattedMessage {...messages.noActivityAlertAndroidMessage} />
            </Text>
          </Section>

          <Box>{error && <Message message={error} isError />}</Box>

          <Box direction="row-responsive" gap="small" justify="between">
            {/* Back */}
            <Button
              color="status-unknown"
              disabled
              label={intl.formatMessage(messages.backButtonLabel)}
              primary={false}
            />

            {/* Save & Continue */}
            <Button
              color="accent-1"
              disabled={!isFormValid() || loading}
              label={intl.formatMessage(messages.continueButtonLabel)}
              type="submit"
              onChange={handleChange}
            />
          </Box>
        </Form>
      </Box>
      {!!showConfirmModal && (
        <Layer position="center" onClickOutside={closeConfirmModal} onEsc={closeConfirmModal}>
          <Box pad="large" width="medium" justify="center" align="center">
            <Text size="medium" weight="bold" textAlign="center">
              <FormattedMessage {...messages.reactivateOnClearingMonitoringEndDateModalMessage} />
            </Text>

            <Box direction="row" gap="medium" margin={{ bottom: '0px', top: '20px' }}>
              <Button
                color="status-unknown"
                label={intl.formatMessage(messages.cancelButton)}
                primary={false}
                margin={{ vertical: 'none' }}
                onClick={() => {
                  setRefresh(Math.random())
                  closeConfirmModal()
                }}
              />
              <Button
                color="accent-1"
                label={intl.formatMessage(messages.confirmModalHeader)}
                margin={{ vertical: 'none' }}
                onClick={clearMonitoringEndDate}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  )
})

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputCountryIcon {
    margin-left: 10px;
  }
  input {
    border-radius: 10px;
    border-width: 0;
    color: rgb(60, 111, 114);
  }
`

export default injectIntl(AddClientInfo)

AddClientInfo.propTypes = {
  intl: intlShape.isRequired,
  clientInitialState: PropTypes.any,
  goToStep: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}
