import React from 'react'
import { FormattedMessage } from 'react-intl'
import { orderBy, startCase, toLower } from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
// Components
import { Box } from '../Box'
import { TableHeader } from '../Table'
// Utils, Services & Messages
import messages from './MonitoringTable.messages'
import { getTablePaginationOptions } from '../../utils/helpers'
import { LoadingIndicator } from '../LoadingIndicator'

/**
 * MonitoringTable
 *
 *  Timezone Notes:
 *  - created datetime is displayed in client timezone because it is client-created
 *
 */
const MonitoringTable = ({
  loading,
  printable,
  clientEvents,
  clientTimezone,
  setCustomCenter,
  rowCount,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const orderedEvents = orderBy(clientEvents, ['created'], ['desc'])

  const dataTableHeaders = [
    {
      selector: 'id',
      name: '',
      omit: true,
    },
    {
      selector: 'type',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'time',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      format: row => {
        if (row.time) {
          return moment(row.time)
            .tz(clientTimezone)
            .format('MM/DD/YY hh:mm:ss A z')
        }
        return 'Not Found'
      },
      sortable: false,
      style: { 'font-size': 14 },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'location',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      wrap: true,
    },
    {
      selector: 'zoneName',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFour} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'zoneType',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFive} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'event',
      omit: true,
    },
  ]

  const getEventType = event => {
    if (event.resourcetype === 'Event') {
      const type = startCase(toLower(event.type))
      const classifier = event.classifier ? `:${startCase(toLower(event.classifier))}` : ''
      const typeClassifier = `${type}${classifier}`
      if (type === 'Geo' || type === 'App') {
        return 'Location Trace'
      }
      return typeClassifier
    }

    if (event.resourcetype === 'VCheckEvent') {
      if (
        event.status === 'on-time' ||
        event.status === 'late' ||
        event.status === 'grouped' ||
        event.status === 'completed'
      )
        return (
          <Box direction="row" gap="xsmall" justify="center" alignContent="center">
            <Box justify="center" alignContent="center">
              {event.type} VCheck
            </Box>
            <svg
              width="30"
              height="25"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 1.875L3.75 5.625V16.875C3.75 23.0889 8.78613 28.125 15 28.125C21.2139 28.125 26.25 23.0889 26.25 16.875V5.625L15 1.875ZM24.1406 16.875C24.1406 21.9229 20.0479 26.0156 15 26.0156C9.95215 26.0156 5.85938 21.9229 5.85938 16.875V7.20703L15 3.98438L24.1406 7.20703V16.875Z"
                fill="#4C989D"
              />
              <path
                d="M11.0862 13.9192C10.9885 13.821 10.8723 13.7431 10.7443 13.6899C10.6164 13.6367 10.4792 13.6094 10.3406 13.6094C10.202 13.6094 10.0648 13.6367 9.93689 13.6899C9.80894 13.7431 9.69275 13.821 9.59499 13.9192C9.49679 14.0169 9.41887 14.1331 9.3657 14.2611C9.31253 14.389 9.28516 14.5262 9.28516 14.6648C9.28516 14.8034 9.31253 14.9406 9.3657 15.0685C9.41887 15.1965 9.49679 15.3126 9.59499 15.4104L13.386 19.2014L13.4475 19.2629C13.54 19.3556 13.6498 19.4291 13.7707 19.4792C13.8916 19.5294 14.0212 19.5552 14.1521 19.5552C14.283 19.5552 14.4126 19.5294 14.5335 19.4792C14.6544 19.4291 14.7643 19.3556 14.8567 19.2629L21.4046 12.7151C21.4972 12.6226 21.5707 12.5128 21.6209 12.3919C21.671 12.271 21.6968 12.1414 21.6968 12.0105C21.6968 11.8796 21.671 11.75 21.6209 11.6291C21.5707 11.5082 21.4972 11.3984 21.4046 11.3059L21.3226 11.2239C21.2301 11.1312 21.1203 11.0577 20.9994 11.0076C20.8785 10.9575 20.7489 10.9316 20.618 10.9316C20.4871 10.9316 20.3575 10.9575 20.2366 11.0076C20.1157 11.0577 20.0058 11.1312 19.9134 11.2239L14.1507 16.9836L11.0862 13.9192Z"
                fill="#4C989D"
              />
            </svg>
          </Box>
        )
      return (
        <Box direction="row" gap="xsmall">
          {event.type} VCheck
        </Box>
      )
    }

    return ''
  }

  const getLocation = event => {
    if (event && event.address) {
      return (
        <Box>
          {event.address && event.address
            ? `${typeof event.address === 'string' ? event.address : `${event.address.formattedAddress ? event.address.formattedAddress : 'Street Address Not Available'}`}` : 'Street Address Not Available'}
        </Box>
      )
    }

    return 'No Location'
  }

  const bodyData = []

  orderedEvents.forEach((event, index) => {
    let zoneName = 'None'
    let zoneType = 'None'
    if (event.data && event.data.geolocationMeta && event.data.geolocationMeta.geofence) {
      zoneName = event.data.geolocationMeta.geofence.identifier
      zoneType = event.data.geolocationMeta.geofence.extras.type
      if (zoneType === 'exclusion') zoneType = 'Zone Of Interest'
    }
    const eventRowObject = {
      id: index + 1,
      type: getEventType(event),
      time: event.created,
      location: getLocation(event),
      zoneName,
      zoneType,
      event,
    }
    bodyData.push(eventRowObject)
  })

  const fieldProps = {}

  if (!printable) {
    fieldProps.progressPending = loading
    fieldProps.progressComponent = <LoadingIndicator />
  }

  return (
    <>
      <StyledDataTable
        noHeader
        border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
        columns={dataTableHeaders}
        data={bodyData}
        sortable
        striped
        highlightOnHover
        pointerOnHover
        onRowClicked={e => {
          if (e.event) {
            setCustomCenter({
              lat: e.event.latitude,
              lng: e.event.longitude,
            })
            const element = document.getElementById('google-map-component')
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        }}
        pagination={!printable}
        paginationServer
        paginationTotalRows={rowCount}
        paginationPerPage={25}
        paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
        onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
        onChangePage={e => handlePageChange(e)}
        persistTableHead
        {...fieldProps}
      />
    </>
  )
}

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  nav > div > select {
    padding-right: 24px;
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`

MonitoringTable.propTypes = {
  loading: PropTypes.bool,
  printable: PropTypes.bool,
  clientEvents: PropTypes.array,
  clientTimezone: PropTypes.string,
  setCustomCenter: PropTypes.func,
  rowCount: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
}

export default MonitoringTable
