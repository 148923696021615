/* eslint-disable react/prop-types */
import React from 'react'
import { Tabs } from 'grommet'
import { doc } from './Tabs.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * Tabs
 *
 * Displays Material Design style tabs
 *
 * - A container for Tabs.
 *
 */
const createTabs = ({
  a11yTitle,
  activeIndex,
  alignSelf,
  children,
  flex,
  gridArea,
  justify,
  margin,
  messages,
  onActive,
  ...rest
}) => (
  <Tabs
    a11yTitle={a11yTitle}
    activeIndex={activeIndex}
    alignSelf={alignSelf}
    flex={flex}
    gridArea={gridArea}
    justify={justify}
    margin={margin}
    messages={messages}
    onActive={onActive}
    {...rest}
  >
    {children}
  </Tabs>
)

export default createWithDoc({
  docFunction: doc,
  component: createTabs,
})
