/* eslint-disable react/prop-types */
import React from 'react'
import { TableFooter } from 'grommet'

import { doc } from './TableFooter.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * TableFooter
 *
 */
function createTableFooter({ children, ...props }) {
  return <TableFooter {...props}>{children}</TableFooter>
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createTableFooter,
})
