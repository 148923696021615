/* eslint-disable prettier/prettier */
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'
export async function getAgencyZones(
  agencyId,
  setError,
  setLoading,
  currentPage,
  rowsPerPage,
  queryContent,
) {
  setLoading(true)
  try {
    const data = await axios.get(`/agencies/${agencyId}/zones/`, {
      params: { page: currentPage, limit: rowsPerPage, name: queryContent },
    })
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function postZoneToAgency(agencyId, zone, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.post(`/agencies/${agencyId}/zones/`, zone)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function updateAgencyZoneById(agencyId, zoneId, zone, setLoading, setError) {
  setLoading(true)

  try {
    const data = await axios.patch(`/agencies/${agencyId}/zones/${zoneId}/`, zone)
    setError(false)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

export async function deleteAgencyZoneById(agencyId, zoneId, setLoading, setError) {
  setLoading(true)

  try {
    await axios.delete(`/agencies/${agencyId}/zones/${zoneId}/`)
    setError(false)
    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return false
  }
}

// Distributor Zones

export async function getZonesByDistributorId(
  distributorId,
  setError,
  setLoading,
  currentPage,
  rowPerPage,
  agencyid,
  searchText,
) {
  setLoading(true)
  try {
    const data = await axios.get(
      `/distributors/${distributorId}/zones/`,
      {
        params: {
          page: currentPage,
          limit: rowPerPage,
          agencyid,
          name: searchText
        },
      },
    )
    setError(false)
    // setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
