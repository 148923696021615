import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

// Components
import { Box } from '../Box'
import { PopulatedTable } from '../PopulatedTable'

// Utils, Services & Messages
import messages from './ScheduledVCheckTable.messages'
import colors from '../../utils/colors'
import rruleBuilder from '../../utils/rruleBuilder'
import rruleParser from '../../utils/rruleParser'

/**
 * ScheduledVCheckTable
 *
 * This component takes in an array of scheduled vchecks and converts it into
 * the required format before passing to reusable PopulatedTable component
 *
 * Input format will match store/api:
 * {
 *    name:
 *    schedule:
 *    start:
 *    stop:
 *    ...other fields not used (id, created, etc.)
 * }
 *
 * This component will convert the input format into the format needed by
 * the sidebar (so that the sidebar can populate correctly on edit) as well
 * as pass the name, dates, and schedule (in text form) fields to the table
 *
 */
const ScheduledVCheckTable = ({ editable = false, vchecks, editInSidebar }) => {
  const tableHeaderData = [
    messages.tableHeaderOne,
    messages.tableHeaderTwo,
    messages.tableHeaderThree,
    messages.tableHeaderFour,
  ]
  const bodyData = []

  const getVCheckNameLink = vcheck =>
    editable ? (
      <Box
        onClick={() => {
          editInSidebar(vcheck)
        }}
        style={{ color: colors.primary, cursor: 'pointer', textDecoration: 'underline' }}
      >
        {vcheck.name}
      </Box>
    ) : (
      <Box>{vcheck.name}</Box>
    )

  vchecks.forEach(vcheck => {
    // convert into format needed by sidebar
    // sidebarFormatVCheck will be passed to editInSidebar function
    if (vcheck.schedule) {
      const parsedFields = rruleParser.parseRRuleToVCheckFields(vcheck.schedule)
      const sidebarFormatVCheck = {
        id: vcheck.id,
        name: vcheck.name,
        startDate: parsedFields.startDate,
        startTime: parsedFields.startTime,
        endTime: moment(parsedFields.startTime, 'HH:mm')
          .add(15, 'm')
          .format('HH:mm'),
        repeat: parsedFields.repeat,
        repeatEvery: parsedFields.repeatEvery,
        frequency: parsedFields.frequency,
        endsOn: parsedFields.endsOn,
        endDate: parsedFields.endDate,
        occurrences: parsedFields.occurrences,
        weekdays: parsedFields.weekdays,
      }

      // format and add to body data
      bodyData.push({
        name: getVCheckNameLink(sidebarFormatVCheck),
        date: moment(sidebarFormatVCheck.startDate).format('MM/DD/YY'),
        time: moment(sidebarFormatVCheck.startTime, 'HH:mm').format('hh:mm A'),
        schedule: rruleBuilder.rruleStringToText(vcheck.schedule),
      })
    }
  })

  return <PopulatedTable headerData={tableHeaderData} bodyData={bodyData}></PopulatedTable>
}

ScheduledVCheckTable.propTypes = {
  editable: PropTypes.bool,
  editInSidebar: PropTypes.func,
  vchecks: PropTypes.array,
}

export default ScheduledVCheckTable
