import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Components
import { Box } from '../Box'
import { Table, TableHeader, TableBody, TableCell, TableRow } from '../Table'
import { Text } from '../Text'

import messages from './PopulatedTable.messages'

/**
 * PopulatedTable
 *
 * Reusable complete table component
 * Takes in the following parameters
 * - headerData: array of react-intl message objects to use as headers
 * - bodyData: array of objects formatted to match headers (same order)
 * - nullMessage: string that will display if field is null
 * - cellSize: string (typical grommet sizes) that will make cells
 *   all the same width of defined size. This field is not necessary but
 *   can be helpful if trying to make two tables the same size
 *
 *
 * Example data:
 * headerData = [
 *    {
 *      id: `${scope}.headerOne`,
 *      defaultMessage: 'City',
 *    },
 *    {
 *      id: `${scope}.headerTwo`,
 *      defaultMessage: 'State',
 *    }
 * ]
 *
 * bodyData = [
 *    {
 *       city: 'Denver',
 *       state: 'Colorado',
 *    },
 *    {
 *       city: 'Golden',
 *       state: 'Colorado',
 *    }
 * ]
 *
 */
const PopulatedTable = ({ headerData, bodyData, nullMessage, cellSize }) => (
  <>
    <Table style={{ width: '100%' }}>
      <StyledTableHeader>
        <TableRow>
          {headerData.map(heading => (
            <TableCell scope="col" key={heading.defaultMessage} size={cellSize}>
              <FormattedMessage {...heading} />
            </TableCell>
          ))}
        </TableRow>
      </StyledTableHeader>

      <TableBody>
        {Object.entries(bodyData).map((row, i) => {
          const rowKey = row[0]
          const rowValue = row[1]

          return (
            <StyledTableRow key={rowKey} isOdd={Boolean(i % 2)}>
              {Object.entries(rowValue).map(cell => {
                const cellKey = cell[0]
                const cellValue = cell[1]
                return (
                  <TableCell scope="row" key={cellKey}>
                    {cellValue !== null && cellValue !== '' ? cellValue : nullMessage}
                  </TableCell>
                )
              })}
            </StyledTableRow>
          )
        })}
      </TableBody>
    </Table>

    {bodyData.length === 0 && (
      <Box pad="small">
        <Text size="small">
          <FormattedMessage {...messages.noDataMessage} />
        </Text>
      </Box>
    )}
  </>
)

const StyledTableHeader = styled(TableHeader)`
  border: none;
  color: #666666;
  text-transform: capitalize;
`

const StyledTableRow = styled(TableRow)`
  background: ${props => (props.isOdd ? 'white' : '#f9f9f9')};
  border: solid 1px #d9d9d9;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  height: 70px;
  text-transform: capitalize;
`

PopulatedTable.defaultProps = {
  nullMessage: 'Not Found',
}

PopulatedTable.propTypes = {
  headerData: PropTypes.array,
  bodyData: PropTypes.array,
  nullMessage: PropTypes.string,
  cellSize: PropTypes.string,
}

export default PopulatedTable
