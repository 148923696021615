import { defineMessages } from 'react-intl'

export const scope = 'app.containers.ZonesPage'

/*
 * ZonesPage Messages
 *
 * This contains all the text for the ZonesPage container.
 */
export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Zones Data...',
  },
  sidebarHeader: {
    id: `${scope}.sidebarHeader`,
    defaultMessage: 'Edit Zone',
  },
  addZoneButton: {
    id: `${scope}.addZoneButton`,
    defaultMessage: 'Add Zone',
  },
  locationDetailsHeader: {
    id: `${scope}.locationDetailsHeader`,
    defaultMessage: 'Location Details',
  },
  agencyNameLabel: {
    id: `${scope}.agencyNameLabel`,
    defaultMessage: 'Agency Name',
  },
  locationNameLabel: {
    id: `${scope}.locationNameLabel`,
    defaultMessage: 'Name',
  },
  locationAddressLabel: {
    id: `${scope}.locationAddressLabel`,
    defaultMessage: 'Address',
  },
  geofenceHeader: {
    id: `${scope}.geofenceHeader`,
    defaultMessage: 'Geofence Diameter',
  },
  geofenceDiameterLabel: {
    id: `${scope}.geofenceDiameterLabel`,
    defaultMessage: 'Geofence Diameter: ',
  },
  geofenceMinDiameterInfo: {
    id: `${scope}.geofenceMinDiameterInfo`,
    defaultMessage: 'Diameter must be greater than 300 feet ',
  },
  geofenceAreaLabel: {
    id: `${scope}.geofenceAreaLabel`,
    defaultMessage: 'Geofence Area: ',
  },
  geofenceMinAreaInfo: {
    id: `${scope}.geofenceMinAreaInfo`,
    defaultMessage: 'Area must be greater than 10,765 square feet ',
  },
  geoAddressValidation: {
    id: `${scope}.geoAddressValidation`,
    defaultMessage: 'Please enter a valid address',
  },
})
