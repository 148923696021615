import { defineMessages } from 'react-intl'

export const scope = 'app.containers.ClientReportsPage'

/*
 * ClientReportsPage Messages
 *
 * This contains all the text for the ClientReportsPage container.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ClientReportsPage container!',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
  datePickerHeader: {
    id: `${scope}.datePickerHeader`,
    defaultMessage: 'Filter Events By Date',
  },
})
