/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

// Components
import { Box } from 'components/Box'
import { ClientAvatar } from 'components/ClientAvatar'
import { ClientVCheckTable } from 'components/ClientVCheckTable'
import { Grid } from 'components/Grid'
import { Text } from 'components/Text'

// Utils, Services & Messages
import styled from 'styled-components'
import formatters from '../../utils/formatters'

/**
 * PrintableComplianceReportPage
 */
class PrintableClientVCheckPage extends Component {
  render() {
    return (
      <>
        <StyledGrid
          responsive
          rows={['auto', 'auto']}
          columns={['auto']}
          pad="medium"
          areas={[['header'], ['table']]}
        >
          <Box gridArea="header" direction="column" justify="between">
            <Box
              direction="row-responsive"
              justify="start"
              align="center"
              gap="small"
              margin={{ bottom: 'small' }}
            >
              <Box
                background="light-2"
                justify="center"
                align="center"
                pad="none"
                round="full"
                height="150px"
                width="150px"
              >
                <ClientAvatar
                  file={this.props.clientAvatarURLValue}
                  size="large"
                  type={this.props.type}
                />
              </Box>

              <Box align="end" gap="small">
                <Text size="medium">{`${this.props.agencyName} / ${this.props.client.first_name} ${this.props.client.last_name}`}</Text>
              </Box>
            </Box>

            <Box direction="row" gap="medium" pad={{ horizontal: 'small' }}>
              <Box direction="column">
                <Text size="xsmall" weight="bold">
                  Client ID
                </Text>
                <Text size="small">{this.props.client.id}</Text>
              </Box>
              <Box direction="column">
                <Text size="xsmall" weight="bold">
                  Case ID
                </Text>
                <Text size="small">{this.props.client.case_id || 'Not Found'}</Text>
              </Box>
              <Box direction="column">
                <Text size="xsmall" weight="bold">
                  Phone
                </Text>
                <Text size="small">
                  {formatters.formatPhoneNumber(this.props.client.phone) || 'Not Found'}
                </Text>
              </Box>
            </Box>

            <Box direction="row" gap="medium" pad={{ horizontal: 'small' }}>
              <Box direction="column">
                <Text size="xsmall" weight="bold">
                  Activation Date
                </Text>
                <Text size="small">
                  {this.props.client.monitoring_start
                    ? moment.utc(this.props.client.monitoring_start).format('YYYY-MM-DD')
                    : 'Not Found'}
                </Text>
              </Box>

              <Box direction="column">
                <Text size="xsmall" weight="bold">
                  Deactivation Date
                </Text>
                <Text size="small">
                  {this.props.client.monitoring_end
                    ? moment.utc(this.props.client.monitoring_end).format('YYYY-MM-DD')
                    : 'Not Set'}
                </Text>
              </Box>
            </Box>
          </Box>

          <>
            <Box gridArea="table" gap="small" margin={{ top: 'medium' }}>
              <Box fill>
                <ClientVCheckTable
                  loading
                  printable
                  disabled
                  clientVChecks={this.props.clientVChecks}
                  clientTimezone={this.props.client.timezone}
                  editInSidebar={() => {}}
                  rowCount={this.props.rowCount}
                  agents={this.props.agents}
                  user={this.props.user}
                  agencyName={this.props.agencyName}
                  handlePageChange={() => {}}
                  handleRowsPerPageChange={() => {}}
                ></ClientVCheckTable>
              </Box>
            </Box>
          </>
        </StyledGrid>
      </>
    )
  }
}

const StyledGrid = styled(Grid)`
  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @page {
    size: auto;
    margin: 15mm 0;
  }
`

PrintableClientVCheckPage.propTypes = {
  client: PropTypes.object.isRequired,
  clientAvatarURLValue: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  clientVChecks: PropTypes.array.isRequired,
  agents: PropTypes.array.isRequired,
  user: PropTypes.any.isRequired,
  agencyName: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default PrintableClientVCheckPage
