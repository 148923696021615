/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { BODY_FONT } from '../../utils/theme'

const StyledDateInput = styled.input`
  font-family: ${BODY_FONT}, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-radius: 10px;
  border: none;
`

/**
 * DateInput
 */
const DateInput = ({ type, ...rest }) => {
  if (type) {
    // eslint-disable-next-line no-console
    console.warn('type prop does not have an effect on DateInput!')
  }

  return <StyledDateInput type="date" {...rest}></StyledDateInput>
}

export default DateInput
