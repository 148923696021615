/* eslint-disable react/prop-types */
import React from 'react'
import { TableBody } from 'grommet'

import { doc } from './TableBody.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * TableBody
 *
 */
function createTableBody({ children, ...props }) {
  return <TableBody {...props}>{children}</TableBody>
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createTableBody,
})
