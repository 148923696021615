/** @todo Fix import cycle */
/* eslint-disable import/no-cycle */

import { ignore } from 'mobx-sync'
import { action } from 'mobx'
import { createContext } from 'react'
import * as Sentry from '@sentry/browser'

import { refreshAccessToken as refreshAccessTokenService } from '../services/user.service'
import { locale } from './LocaleStore'
import { user } from './UserStore'
import { agency } from './AgencyStore'
import { wizard } from './ClientWizardStore'
import { distributor } from './DistributorStore'

export class RootStore {
  @ignore storeLoaded = false

  locale = locale

  agency = agency

  user = user

  distributor = distributor

  wizard = wizard

  @action.bound
  clearStore() {
    this.user.reset()
    this.agency.reset()
    this.wizard.reset()
    this.distributor.reset()

    Sentry.configureScope(scope => scope.setUser(null))
  }

  @action.bound
  async triggerRefreshToken(failedRequest) {
    try {
      const { access } = await refreshAccessTokenService(this.user.refreshToken)
      this.user.accessToken = access

      // Reassign to `failedRequest` as this object is used by the token
      // interceptor to retry the request
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${access}`
    } catch (err) {
      this.clearStore()
      throw err
    }
  }
}

export const store = new RootStore()
export const RootStoreContext = createContext(store)
