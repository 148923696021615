import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink, useLocation, withRouter } from 'react-router-dom'
import { Header as GrommetHeader, Image } from 'grommet'
import { User } from 'grommet-icons'
import styled from 'styled-components'

// Components
import { AppVersion } from '../AppVersion'
import { Box } from '../Box'
import { Button } from '../Button'
import { Header } from '../Header'
import { Nav } from '../Nav'
import { Text } from '../Text'
import { Restricted } from '../Restricted'

// Utils, Services & Messages
import logo from '../../images/logo.png'
import messages from './NavBar.messages'
import colors from '../../utils/colors'
import { Flag } from '../../utils/flags'

// Stores
import { RootStoreContext } from '../../stores/RootStore'
import { UserStoreContext } from '../../stores/UserStore'
import { logout } from '../../services/user.service'

/**
 * NavBar
 */
const NavBar = withRouter(({ history }) => {
  const { user: userStore, clearStore } = useContext(RootStoreContext)
  const { isDistributor } = useContext(UserStoreContext)

  const location = useLocation()

  return (
    <Nav direction="column" fill="vertical" background={colors.primary} gap="none">
      <GrommetHeader alignSelf="center" margin={{ top: 'medium' }}>
        <Box style={{ height: '70px', width: '180px' }}>
          <Image fit="contain" src={logo} />
        </Box>

        <Button
          plain
          color="transparent"
          onClick={() => {
            history.push({
              pathname: '/profile',
              state: { background: location },
            })
          }}
        >
          <User color="accent-2" size="22px" />
        </Button>
      </GrommetHeader>

      <Box flex="grow" justify="start">
        <NavItem to="/dashboard">
          <Header level={4} margin="none">
            <FormattedMessage {...messages.home} />
          </Header>
        </NavItem>

        <NavItem to="/vcheckreview">
          <Header level={4} margin="none">
            <FormattedMessage {...messages.vcheckreview} />
          </Header>
        </NavItem>

        {isDistributor && (
          <NavItem to="/agencyinfo">
            <Header level={4} margin="none">
              <FormattedMessage {...messages.agencyinfo} />
            </Header>
          </NavItem>
        )}

        <Restricted allowedRole="isAgentAdmin">
          <NavItem to="/agents">
            <Header level={4} margin="none">
              <FormattedMessage {...messages.agents} />
            </Header>
          </NavItem>
        </Restricted>

        <NavItem to="/clients">
          <Header level={4} margin="none">
            <FormattedMessage {...messages.clients} />
          </Header>
        </NavItem>

        <NavItem to="/zones">
          <Header level={4} margin="none">
            <FormattedMessage {...messages.zones} />
          </Header>
        </NavItem>

        <Flag name={['pages', 'reports']}>
          <NavItem to="/report">
            <Header level={4} margin="none">
              <FormattedMessage {...messages.reports} />
            </Header>
          </NavItem>
        </Flag>

        <Footer direction="row" align="center" justify="between">
          <LogoutLink
            onClick={() => logout(userStore.refreshToken, clearStore)}
            margin="medium"
            size="small"
            underline
            weight="bold"
          >
            Logout
          </LogoutLink>

          <AppVersion />
        </Footer>
      </Box>
    </Nav>
  )
})

const NavItem = styled(NavLink)`
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 19px;
  font-weight: thin;
  padding: 1em;
  outline: 0;
  text-decoration: none;
  user-select: none;

  &.active {
    background: ${colors.darkerGreyBlue};
  }
`
const Footer = styled(Box)`
  bottom: 0;
  position: absolute;
`
const LogoutLink = styled(Text)`
  cursor: pointer;
`

export default NavBar
