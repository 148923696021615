import moment from 'moment'
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from 'react-phone-number-input'

const formatters = {
  formatPhoneNumber(phoneNumberString) {
    if (phoneNumberString) {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumberString)
      if (!parsedPhoneNumber) {
        return phoneNumberString
      }
      if (parsedPhoneNumber.country === 'US') {
        return formatPhoneNumber(phoneNumberString)
      }
      return formatPhoneNumberIntl(phoneNumberString)
    }
    return ''
  },
  formatToDateInputDisplayFormat(longDate) {
    if (longDate) {
      const displayDate = moment(longDate).format('YYYY-MM-DD')
      return displayDate
    }
    return ''
  },
  formatClusterQty(n) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ]

    let i = 0
    for (i = si.length - 1; i > 0; i -= 1) {
      if (n >= si[i].value) {
        break
      }
    }

    return (n / si[i].value).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
  },
  truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num).concat('...')
  },
}

export default formatters
