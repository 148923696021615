import { defineMessages } from 'react-intl'

export const scope = 'app.components.CaseNotesTable'

/*
 * CaseNotesTable Messages
 *
 * This contains all the text for the CaseNotesTable component.
 */
export default defineMessages({
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Created',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Agent',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Notes',
  },
  noDataMessage: {
    id: `${scope}.noDataMessage`,
    defaultMessage: 'No Data Available',
  },
})
