/* eslint-disable react/prop-types */
import React from 'react'
import { Stack } from 'grommet'

import { doc } from './Stack.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * Stack
 *
 */
function createStack({ children, ...rest }) {
  return <Stack {...rest}>{children}</Stack>
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createStack,
})
