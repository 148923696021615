import styled from 'styled-components'

export const Section = styled.section`
  margin: 1em 0;
  padding-top: 1em;
  border-top: 1px solid lightGrey;
  &:first-child {
    margin-top: 0;
    border-top: none;
  }
`
