import { defineMessages } from 'react-intl'

export const scope = 'app.components.AddGlobalZoneTable'

/*
 * AddGlobalZoneTable Messages
 *
 * This contains all the text for the AddGlobalZoneTable component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AddGlobalZoneTable component!',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Agency Zone Name',
  },
})
