import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'

// Components
import { TableHeader } from '../Table'

// Utils, Services & Messages
import messages from './AddGlobalZoneTable.messages'

/**
 * AddGlobalZoneTable
 *
 * This component takes in the agencyZones array data and converts it into the
 * required format before passing to react-data-table-componenet DataTable
 *
 */
const AddGlobalZoneTable = memo(({ agencyZones, clientGlobalZones, handleSelectedRows }) => {
  const clientZoneIds = clientGlobalZones.map(zone => zone.id)

  const dataTableHeaders = [
    {
      selector: 'name',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'zone',
      omit: true,
    },
  ]

  const bodyData = []

  agencyZones.forEach(zone => {
    const zoneRowObject = {
      name: zone.name ? zone.name : 'Not Found',
      zone,
    }
    bodyData.push(zoneRowObject)
  })

  return (
    <StyledDataTable
      noHeader
      border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
      columns={dataTableHeaders}
      data={bodyData}
      striped
      selectableRows
      selectableRowSelected={row => clientZoneIds.includes(row.zone.id)}
      onSelectedRowsChange={e => handleSelectedRows(e.selectedRows)}
      pagination={agencyZones.length > 10}
    />
  )
})

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`

AddGlobalZoneTable.propTypes = {
  agencyZones: PropTypes.array,
  clientGlobalZones: PropTypes.array,
  handleSelectedRows: PropTypes.func,
}

export default AddGlobalZoneTable
