import React from 'react'

// Components
import { LoadingIndicator } from 'components/LoadingIndicator'

// Utils, Services & Messages
import loadable from 'utils/loadable'

/**
 * Asynchronously loads the component for ReportPage
 */
export default loadable(() => import('./ReportPage'), {
  fallback: <LoadingIndicator />,
})
