import { defineMessages } from 'react-intl'

export const scope = 'app.containers.AddClientSchedule'

/*
 * AddClientSchedule Messages
 *
 * This contains all the text for the AddClientSchedule container.
 */
export default defineMessages({
  // main page
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
  randomVCheckHeader: {
    id: `${scope}.randomVCheckHeader`,
    defaultMessage: 'Random VChecks',
  },
  randomVCheckDescription: {
    id: `${scope}.randomVCheckDescription`,
    defaultMessage:
      'Up to 23 Random Vchecks can be set within a 24-hour interval, including overnight.\n A minimum of 1 hour is required per Random VCheck',
  },
  rFreqLabel: {
    id: `${scope}.rFreqLabel`,
    defaultMessage: 'Frequency',
  },
  rStartLabel: {
    id: `${scope}.rStartLabel`,
    defaultMessage: 'Start Time',
  },
  rEndLabel: {
    id: `${scope}.rEndLabel`,
    defaultMessage: 'End Time',
  },

  scheduledVCheckHeader: {
    id: `${scope}.scheduledVCheckHeader`,
    defaultMessage: 'Scheduled VChecks',
  },
  scheduledVCheckDescription: {
    id: `${scope}.scheduledVCheckDescription`,
    defaultMessage: 'Schedule VChecks to occur once, reoccur multiple times, and repeat.',
  },
  addScheduleButtonLabel: {
    id: `${scope}.addScheduleButtonLabel`,
    defaultMessage: 'Add VCheck',
  },

  // side bar
  vcheckNameHeader: {
    id: `${scope}.vcheckNameHeader`,
    defaultMessage: 'VCheck Details',
  },
  vcheckNameLabel: {
    id: `${scope}.vcheckNameLabel`,
    defaultMessage: 'Name',
  },
  scheduleHeader: {
    id: `${scope}.scheduleHeader`,
    defaultMessage: 'Schedule',
  },
  scheduleDetails: {
    id: `${scope}.scheduleDetails`,
    defaultMessage: `Here you can schedule a VCheck to occur once, or repeat. Clients have a 15 minute window to complete VChecks starting from the Begin time. The start date for a VCheck must be after the Client's monitoring start date.`,
  },
  backButtonLabel: {
    id: `${scope}.backButtonLabel`,
    defaultMessage: 'Back',
  },
  continueButtonLabel: {
    id: `${scope}.continueButtonLabel`,
    defaultMessage: 'Save & Continue',
  },
  closeButtonLabel: {
    id: `${scope}.closeButtonLabel`,
    defaultMessage: 'Save & Close',
  },
  scheduleSidebarHeaderAdd: {
    id: `${scope}.scheduleSidebarHeaderAdd`,
    defaultMessage: 'Add Scheduled VCheck',
  },
  scheduleSidebarHeaderEdit: {
    id: `${scope}.scheduleSidebarHeaderEdit`,
    defaultMessage: 'Edit Scheduled VCheck',
  },
})
