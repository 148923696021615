import { defineMessages } from 'react-intl'

export const scope = 'app.components.VCheckScheduler'

/*
 * VCheckScheduler Messages
 *
 * This contains all the text for the VCheckScheduler component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the VCheckScheduler component!',
  },
  startDateLabel: {
    id: `${scope}.startDateLabel`,
    defaultMessage: 'Start',
  },

  startTimeLabel: {
    id: `${scope}.startTimeLabel`,
    defaultMessage: 'Begin',
  },
  endTimeLabel: {
    id: `${scope}.endTimeLabel`,
    defaultMessage: 'End',
  },
  repeatLabel: {
    id: `${scope}.repeatLabel`,
    defaultMessage: 'Repeat',
  },
  repeatEveryLabel: {
    id: `${scope}.repeatEveryLabel`,
    defaultMessage: 'Repeat Every',
  },
  frequencyLabel: {
    id: `${scope}.frequencyLabel`,
    defaultMessage: 'Frequency',
  },
  repeatDaysLabel: {
    id: `${scope}.repeatDaysLabel`,
    defaultMessage: 'Repeat On',
  },
  endsOnLabel: {
    id: `${scope}.endsOnLabel`,
    defaultMessage: 'Ends On',
  },
  endDateLabel: {
    id: `${scope}.endDateLabel`,
    defaultMessage: 'End Date',
  },
  occurrencesLabel: {
    id: `${scope}.occurrencesLabel`,
    defaultMessage: 'Number of Occurrences',
  },
})
