/* eslint-disable react/prop-types */
import React from 'react'
import { Nav } from 'grommet'

import { doc } from './Nav.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 * Nav
 */
function createNav({
  a11yTitle,
  align,
  alignContent,
  alignSelf,
  background,
  basis,
  border,
  children,
  direction,
  elevation,
  fill,
  gridArea,
  height,
  justify,
  margin,
  overflow,
  pad,
  responsive,
  round,
  tag,
  width,
  wrap,
}) {
  return (
    <Nav
      a11yTitle={a11yTitle}
      align={align}
      alignContent={alignContent}
      alignSelf={alignSelf}
      background={background}
      basis={basis}
      border={border}
      direction={direction}
      elevation={elevation}
      fill={fill}
      gridArea={gridArea}
      height={height}
      justify={justify}
      margin={margin}
      overflow={overflow}
      pad={pad}
      responsive={responsive}
      round={round}
      tag={tag}
      width={width}
      wrap={wrap}
    >
      {children}
    </Nav>
  )
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createNav,
})
