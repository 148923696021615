import { useContext } from 'react'
import PropTypes from 'prop-types'

// Stores
/**
 * NOTE: this uses RootStore instead of UserStore in order to avoid a circular import issue. If
 * UserStore is used here, then `user` becomes undefined in the RootStore
 */
import { RootStoreContext } from '../../stores/RootStore'

/**
 * Restricted
 *
 * Component that allows conditional rendering and optional fallback rendering for protected
 * components
 *
 * Note: In some cases you may not need the full Restricted component, such as controlling whether
 * or not a button is disabled. The helper methods used for checking role types are exposed in
 * utils/helpers (isPermitted and isNotPermitted).
 *
 * Note: This component will likely be used along with the user role constants in util/constants.js
 *
 * RESTRICTION TYPES:
 * - Restricting based on OBJECT OWNERSHIP
 *   - Use Case: Only the Agency Admin can edit a Agency they own. In this case, only the user whose
 *   ID matches the owner ID will see the RestrictedThing. Otherwise, nothing is shown.
 *
 *   <Restricted ownerID="abc-123">
 *      <RestrictedThing />
 *   </Restricted>
 *
 * - Restricting based on ROLE TYPE
 *   - Use Case: All Agency Admins can create a new Agent. In this case, all users who are in the
 *   Agency Admin group will shown the RestrictedThing. Otherwise, nothing is shown. Since
 *   permissions are expressed as booleans on the user object (e.g. `is_agent_admin`, `is_superuser`,
 *   this value must map to a key on the user object)
 *
 *   <Restricted allowedRole="is_agent_admin">
 *      <RestrictedThing />
 *   </Restricted>
 *
 * OPTIONAL FALLBACK:
 * In the cases above, the component that is being protected is either rendered, or nothing
 * is rendered. If you wish to display something else as a fallback when the user is not permitted,
 * supply a `fallback`:
 *
 *   const NotAllowedMessage = <Text>Contact the Agency Admin for edit access</Text>
 *   <Restricted allowedRole="is_agent_admin" fallback={NotAllowedMessage}>
 *      <RestrictedThing />
 *   </Restricted>
 */
const Restricted = ({ allowedRole = null, children = null, fallback = null, ownerID = null }) => {
  const rootStore = useContext(RootStoreContext)

  if (ownerID !== null && allowedRole !== null) {
    // eslint-disable-next-line no-console
    console.warn(
      'Only specify either `allowedRole` or `ownerID`. ' +
        'Since both are set, `ownerID` will be used, as it is more restrictive',
    )
  }
  if (ownerID === null && allowedRole === null) {
    // eslint-disable-next-line no-console
    console.warn(
      'Valid permission restrictions were not found. ' +
        'Please specify either `allowedRole` or `ownerID`.',
    )
  }

  if (ownerID !== null && ownerID === rootStore.user.user.uid) return children
  if (
    (allowedRole !== null && rootStore.user.user[allowedRole] === true) ||
    rootStore.user.isDistributor
  )
    return children
  if (fallback) return fallback
  return null
}

Restricted.propTypes = {
  allowedRole: PropTypes.string,
  children: PropTypes.any,
  fallback: PropTypes.any,
  ownerID: PropTypes.string,
}

export default Restricted
