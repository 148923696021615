import { createContext } from 'react'
import { observable, action } from 'mobx'

import { DEFAULT_LOCALE } from '../i18n'
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  locale: DEFAULT_LOCALE,
}

export class LocaleStore extends BaseStore {
  @observable locale

  @action.bound
  changeLocale(locale) {
    this.locale = locale
  }
}

export const locale = new LocaleStore(INITIAL_STATE)
export const LocaleStoreContext = createContext(locale)
