/* @flow */
import React, { useEffect, useState } from 'react'
import { isEmail } from 'validator'

// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Form, FormField } from 'components/Form'
import { Header } from 'components/Header'
import { Link } from 'components/Link'
import { LogoHeader } from 'components/LogoHeader'
import { Message } from 'components/Message'

import { TextInput } from 'components/TextInput'

// Utils and messages
import useFlashMessage from '../../hooks/FlashMessage'
import { forgotPassword } from '../../services/user.service'

/**
 *
 * PasswordResetRequest
 *
 */
const PasswordResetRequest = () => {
  const { message: error, showMessage: showError } = useFlashMessage(null)

  const [loading, setLoading] = useState(false)

  const [success, setSuccess] = useState(false)
  useEffect(() => {
    document.getElementById('reset-password-request-form').reset()
  }, [success])

  /**
   * Helper to determine if the submit button is disabled
   */
  const _isButtonDisabled = () => loading

  return (
    <Box>
      <LogoHeader />

      <Box margin="auto" pad="large" style={{ width: '90vw', maxWidth: 600 }}>
        <Header alignSelf="center" level="3">
          Forgot your password?
        </Header>

        <Link style={{ alignSelf: 'center' }} to="/login">
          or Login
        </Link>

        <Form
          id="reset-password-request-form"
          validate="blur"
          onSubmit={({ value }) => {
            forgotPassword(value, showError, setLoading, setSuccess)
          }}
        >
          <FormField
            label="Email"
            name="email"
            required
            validate={[
              email => {
                if (email && !isEmail(email)) return 'Please enter a valid email address'
                return undefined
              },
            ]}
          >
            <TextInput name="email" type="email" />
          </FormField>

          {/* Submit */}
          <Box direction="row" margin={{ vertical: 'medium' }}>
            <Button type="submit" label="Submit" primary disabled={_isButtonDisabled()} />
          </Box>

          {/* Status Messages */}
          {success && <Message message="Success! Check your email to reset your password." />}
          {error && <Message message={error} isError />}
        </Form>
      </Box>
    </Box>
  )
}

export default PasswordResetRequest
