import { defineMessages } from 'react-intl'

export const scope = 'app.components.ClientGlobalZoneSidebar'

/*
 * ClientGlobalZoneSidebar Messages
 *
 * This contains all the text for the ClientGlobalZoneSidebar component.
 */
export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Agency Zones...',
  },
  globalZonesSidebarHeader: {
    id: `${scope}.globalZonesSidebarHeader`,
    defaultMessage: 'Select Global Zones ',
  },
  globalZonesDetailsHeader: {
    id: `${scope}.globalZonesDetailsHeader`,
    defaultMessage:
      'Select global zones from the list below to add to the client.\nGlobal zones are always considered a Zone of Interest.',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Agency Zone Name',
  },
})
