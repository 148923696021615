import React from 'react'
import { Camera } from 'grommet-icons'
import PropTypes from 'prop-types'

// Components
import { Box } from '../Box'
import colors from '../../utils/colors'

const AddClientImage = ({ onChange }) => {
  return (
    <Box
      focusIndicator={false}
      background={colors.darkerGreyBlue}
      justify="center"
      align="center"
      pad="none"
      round="full"
      height="45px"
      width="45px"
      onClick={onChange}
    >
      <Camera size="medium" color="white" />
    </Box>
  )
}

AddClientImage.propTypes = {
  onChange: PropTypes.func,

}

export default AddClientImage
