import React, { useState, useEffect, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

// Components
import { Box } from 'components/Box'
import { Header } from 'components/Header'
import { Message } from 'components/Message'
import { Tabs, Tab } from 'components/Tabs'

// Containers
import { AddClientInfo } from '../AddClientInfo'
import { AddClientSchedule } from '../AddClientSchedule'
import { AddClientZone } from '../AddClientZone'
import { VerifyClient } from '../VerifyClient'

// Stores
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'

// Styles
import { Section } from './ClientWizard.styles'

// Utils, Services & Messages
import messages from './ClientWizard.messages'

/**
 *
 * ClientWizard
 *
 * This container will hold the steps for Add/Edit Client flows
 * - Client: AddClientInfo component
 * - Zones: AddClientZone component
 * - Schedule: AddClientSchedule component
 * - Verify: VerifyClient component (only be visible until a client is verified)
 *
 * The active tab is first determined by the url param "step", and defaults to 0 (Client)
 * if no param is provided. This allows navigation between tabs by clicking on the
 * tabs themselves or calling the goToStep function, which can be passed into each
 * step's component.
 *
 */
const ClientWizard = observer(({ location, clientInitialState }) => {
  const { getClientById, distributorClientSelectedAgencyId } = useContext(ClientWizardStoreContext)
  const { user, isDistributor } = useContext(UserStoreContext)

  const [pageLoadError, showPageLoadError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [agencyId, setAgencyId] = useState(!isDistributor ? user.agencyId : '')

  const [index, setIndex] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const [clientId, setClientId] = useState()
  const [clientIsVerified, setClientIsVerified] = useState(false)

  useEffect(() => {
    async function setDefaultData() {
      const urlParams = new URLSearchParams(location.search)
      const stepParam = urlParams.get('step')
      if (urlParams.has('id')) {
        setIsEditing(true)
        setClientId(urlParams.get('id'))
        const currentClient = await getClientById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setLoading,
        )
        if (currentClient) {
          setClientIsVerified(currentClient.is_agent_verified)
        }
      }
      setIndex(parseInt(stepParam, 10) || 0)
    }

    if (agencyId !== '') {
      setDefaultData()
    } else {
      setAgencyId(distributorClientSelectedAgencyId)
    }
  }, [agencyId])

  const onActive = nextIndex => {
    goToStep(nextIndex)
  }

  // set url params for step and id
  // initial client add is only call that sends id param
  const goToStep = (nextIndex, id) => {
    const urlParams = new URLSearchParams(location.search)

    // set id param
    if (id) {
      urlParams.set('id', id)
      setClientId(id)
    } else {
      urlParams.set('id', clientId)
    }

    // set step param
    urlParams.set('step', nextIndex)

    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`)
    setIndex(nextIndex)
  }

  if (loading || pageLoadError) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }

  return (
    <>
      <Section>
        <Header level="2">
          {isEditing ? (
            <FormattedMessage {...messages.headerEdit} />
          ) : (
            <FormattedMessage {...messages.headerAdd} />
          )}
        </Header>

        <Tabs flex justify="start" activeIndex={index} onActive={onActive}>
          <Tab plain title="Client">
            <Box>
              <AddClientInfo clientInitialState={clientInitialState} goToStep={goToStep} />
            </Box>
          </Tab>

          <Tab plain title="Zones" disabled={!clientId}>
            <AddClientZone goToStep={goToStep} />
          </Tab>

          <Tab plain title="Schedule" disabled={!clientId}>
            <AddClientSchedule goToStep={goToStep} clientIsVerified={clientIsVerified} />
          </Tab>

          {!clientIsVerified && (
            <Tab plain title="Verify" disabled={!clientId}>
              <VerifyClient goToStep={goToStep} isEditing={isEditing}/>
            </Tab>
          )}
        </Tabs>
      </Section>
    </>
  )
})

ClientWizard.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  clientInitialState: PropTypes.any, // used for testing
}

export default ClientWizard
