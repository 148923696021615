import { defineMessages } from 'react-intl'

export const scope = 'app.components.WizardSidebar'

/*
 * WizardSidebar Messages
 *
 * This contains all the text for the WizardSidebar component.
 */
export default defineMessages({
  saveButton: {
    id: `${scope}.saveButton`,
    defaultMessage: 'Save',
  },
  deleteButton: {
    id: `${scope}.deleteButton`,
    defaultMessage: 'Delete',
  },
})
