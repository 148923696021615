import React, { useEffect, useContext, useReducer, useState, useRef } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import moment from 'moment'
import { orderBy } from 'lodash'
import { Add, Close, Down, SettingsOption, StreetView } from 'grommet-icons'

// Components
import { Button } from 'components/Button'
import { Box } from 'components/Box'
import { CaseNotesTable } from 'components/CaseNotesTable'
import { DropButton } from 'components/DropButton'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Layer } from 'components/Layer'
import { Link } from 'components/Link'
import { Message } from 'components/Message'
import { Notification } from 'components/Notification'
import { Text } from 'components/Text'
import { TextArea } from 'components/TextArea'
import { TextBlock } from 'components/TextBlock'
import { WizardSidebar } from 'components/WizardSidebar'
import { ClientAvatar } from 'components/ClientAvatar'
import { Stack } from 'components/Stack'
import { AddClientImage } from 'components/AddClientImage'
// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'
import { DistributorStoreContext } from '../../stores/DistributorStore'

// Utils, Services & Messages
import messages from './ClientSummaryPage.messages'
import useFlashMessage from '../../hooks/FlashMessage'

// Styles
import colors from '../../utils/colors'

// import { date } from 'mobx-sync'

const sidebarInitialState = {
  timestamp: '',
  author: '',
  content: null,
}

export const getDateDifference = (date1, date2) => {
  const diffTime = Math.abs(new Date(date2) - new Date(date1))
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (new Date(date1) > new Date(date2)) {
    return 0
  }
  if (date1 === date2) {
    return 1
  }
  return diffDays
}

/**
 *
 * ClientSummaryPage
 *
 * This container holds all components needed for the Client Summary Page
 * The user navigates to this page when they click on a client in the client list
 *
 * The user navigates from this page to the Client Wizard by clicking on the
 * settings cog and choosing the "Edit Client" option
 *
 * The user navigates from this page to the Client VChecks Page by clicking on the
 * VChecks button and choosing the "All VChecks" option
 *
 * The user can resend install links from the Settings cog, and send on demand
 * vchecks from the VChecks button
 *
 */
const ClientSummaryPage = observer(({ intl }) => {
  const {
    sendInstallLinkToClient,
    sendOnDemandVCheckToClient,
    deactivateClient,
    reactivateClient,
    getClientStatasticDetailByAgencyId,
  } = useContext(AgencyStoreContext)
  const {
    distributorClientSelectedAgencyId,
    getClientById,
    getClientAvatarUrl,
    getClientCaseNotesById,
    updateClientAvatarUrl,
    postCaseNoteToClient,
    postCaseNoteClientByDistributorId,
    setUpdatedClientImage,
    postSelectedAgent,
    getClientActiveDays,
    clientNotes,
  } = useContext(ClientWizardStoreContext)
  const { user, isDistributor, distributorId } = useContext(UserStoreContext)
  const { getClientStatusDetailByDistributorId } = useContext(DistributorStoreContext)
  const { getAgentsByAgency } = useContext(AgencyStoreContext)
  const orderedNotes = orderBy(clientNotes, ['created'], ['asc'])

  // we are referencing the file input
  const imageRef = useRef()
  const [pageLoadError, showPageLoadError] = useState(null)
  const [pageLoading, setPageLoading] = useState()
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [loading, setLoading] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const { message: activationError, showMessage: showActivationError } = useFlashMessage(null)
  const { message: successMessage, showMessage: showSuccessMessage } = useFlashMessage(null)
  const { message: fileError, showMessage: showFileErrorMessage } = useFlashMessage(null)

  const [clientId, setClientId] = useState()
  const [client, setClient] = useReducer(
    (state, updatedField) => ({ ...state, ...updatedField }),
    {},
  )
  const [primaryAgent, setPrimaryAgent] = useState()
  const [clientCaseNotes, setClientCaseNotes] = useState(orderedNotes)
  const [clientVCheckCount, setClientVCheckCount] = useState(0)
  const [clientAvatarUrl, setClientAvatarUrl] = useState(null)
  const [agentUnreadChatMessageCount, setAgentUnreatChatMessageCount] = useState(0)
  const [inactiveFlag, setInactiveFlag] = useState(false)
  const [monitoringEndDate, setMonitoringEndDate] = useState('Not Set')

  // boolean for showing sidebar & modal
  const [showConfirmModal, setShowConfirmModal] = useState()
  const [showSidebar, setShowSidebar] = useState()
  const [sidebarState, dispatch] = useReducer(sidebarReducer, sidebarInitialState)
  const [daysActive, setDaysActive] = useState(0)

  const agencyId = isDistributor ? distributorClientSelectedAgencyId : user.agencyId

  function sidebarReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }

    return sidebarInitialState
  }

  useEffect(() => {
    async function setDefaultData() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('id')) {
        setClientId(urlParams.get('id'))
        const caseNotes = await getClientCaseNotesById(
          agencyId,
          urlParams.get('id'),
          showError,
          setLoading,
        )

        const currentClient = await getClientById(
          agencyId,
          urlParams.get('id'),
          showPageLoadError,
          setPageLoading,
        )

        await getClientAvatarUrl(
          agencyId,
          urlParams.get('id'),
          '',
          showPageLoadError,
          setPageLoading,
        )
          .then(clavt => {
            setClientAvatarUrl(clavt)
          })
          .catch(() => {
            showPageLoadError(false)
            setClientAvatarUrl(null)
          })
        if (currentClient) {
          setInactiveFlag(!currentClient.is_active)
          setClient(currentClient)
          setMonitoringEndDate(
            currentClient.monitoring_end
              ? moment.utc(currentClient.monitoring_end).format('MMMM Do, YYYY')
              : 'Not Set',
          )
          if (currentClient.unread_chat_messages) {
            setAgentUnreatChatMessageCount(currentClient.unread_chat_messages.count)
          }
          let daysActive = 0
          try {
            const activeDays = await getClientActiveDays(
              agencyId,
              currentClient.id,
              showError,
              setPageLoading,
            )
            daysActive = activeDays.total_active_monitoring_days_v || 0
          } catch (err) {
            console.log(' get client days error: ', err)
          }
          setDaysActive(daysActive)
        }

        setClientCaseNotes(caseNotes)

        // find agent name
        const response = await getAgentsByAgency(agencyId, showPageLoadError, setPageLoading)
        const agents = response.results
        agents.forEach(agent => {
          if (agent.id === currentClient.primary_agent_id) {
            if (agent.user.first_name && agent.user.last_name) {
              setPrimaryAgent(agent.user.first_name.concat(' ', agent.user.last_name))
            } else {
              setPrimaryAgent('Not Found')
            }
            if (isDistributor) {
              postSelectedAgent(agent)
            }
          }
        })
      }
    }
    setDefaultData()
  }, [])

  const renderFeatureCount = results => {
    const filteredClient = results.find(e => {
      if (Number(e.client_id) === client.id) {
        return true
      }
      return false
    })
    if (filteredClient) {
      setClientVCheckCount(filteredClient.number_of_completedvchecks || 0)
    }
  }

  const getCountFromStats = async () => {
    if (isDistributor) {
      await getClientStatusDetailByDistributorId(
        distributorId,
        agencyId,
        showPageLoadError,
        setPageLoading,
      ).then(results => {
        renderFeatureCount(results)
      })
    } else {
      await getClientStatasticDetailByAgencyId(agencyId, showPageLoadError, setPageLoading).then(
        results => {
          renderFeatureCount(results)
        },
      )
    }
  }

  useEffect(() => {
    if (client && client.id) {
      getCountFromStats(client)
    }
  }, [client])

  // vcheck button and settings cog dropdown states and actions
  const [vcheckMenuOpen, setVCheckMenuOpen] = useState(false)
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false)

  function sendVCheck() {
    sendOnDemandVCheckToClient(agencyId, client.id, setLoading, showError, showSuccessMessage)
    setVCheckMenuOpen(false)
  }

  function sendInstallLink() {
    sendInstallLinkToClient(agencyId, client.id, setLoading, showError, true, showSuccessMessage)
    setSettingsMenuOpen(false)
  }

  // Confirmation Modal functions
  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }
  const openConfirmModal = () => {
    setSettingsMenuOpen(false)
    setShowConfirmModal(true)
  }

  const postClientAvatarUrl = async (payLoad, file) => {
    const response = await updateClientAvatarUrl(
      agencyId,
      clientId,
      payLoad,
      showFileErrorMessage,
      setImageLoading,
    )
    if (response) {
      const imageDetails = {
        avatar_url: URL.createObjectURL(file),
        type: 'image',
      }
      await setClientAvatarUrl(imageDetails)
      await setUpdatedClientImage(imageDetails)
    }
  }

  const reactivateConfirm = async () => {
    const response = await reactivateClient(agencyId, clientId, setLoading, showActivationError)
    if (response) {
      closeConfirmModal()
      setInactiveFlag(false)
      setMonitoringEndDate('Not Set')
    }
  }

  const deactivateConfirm = async () => {
    const response = await deactivateClient(agencyId, clientId, setLoading, showActivationError)
    if (response) {
      closeConfirmModal()
      setInactiveFlag(true)
      setMonitoringEndDate(moment(new Date()).format('MMMM Do, YYYY'))
    }
  }

  // Sidebar functions
  const onClose = () => {
    // clear siderbar form state
    dispatch({ type: 'reset' })
    setShowSidebar(false)
  }
  const openModal = () => {
    setShowSidebar(true)
  }

  const convertToClientTimezone = (time, zone) => time.tz(zone).format('MM/DD/YY hh:mm A z')

  // Function to pass into ClientNotesTable that
  // will open and populate sidebar for viewing
  const viewNoteInSidebar = note => {
    dispatch({
      type: 'update',
      data: {
        timestamp: convertToClientTimezone(moment(note.created), client.timezone),
        author: note.created_by.user.first_name.concat(' ', note.created_by.user.last_name),
        content: note.notes,
      },
    })

    openModal()
  }

  const onSubmit = async () => {
    const noteObject = {
      notes: sidebarState.content,
    }
    let postedNote

    if (!isDistributor) {
      postedNote = await postCaseNoteToClient(
        agencyId,
        clientId,
        client.primary_agent_id,
        noteObject,
        showError,
        setLoading,
      )
    } else {
      postedNote = await postCaseNoteClientByDistributorId(
        distributorId,
        clientId,
        client.primary_agent_id,
        noteObject,
        showError,
        setLoading,
      )
    }

    if (postedNote) {
      setClientCaseNotes(orderBy([...clientCaseNotes, postedNote], ['created'], ['asc']))
      onClose()
    }
  }

  // Build Sidebar header & content
  const sideBarHeader = client.first_name && (
    <Text capitalize>{`Case Note - ${client.last_name.concat(', ', client.first_name)}`}</Text>
  )

  const sidebarContent = (
    <>
      <Box gap="medium">
        {sidebarState.author && (
          <Box gap="small">
            <Text size="small">{`Created: ${sidebarState.timestamp}`}</Text>

            <Text size="small">{`Agent: ${sidebarState.author}`}</Text>
          </Box>
        )}

        <Box direction="column">
          <Header mini level="3" margin={{ top: 'none' }}>
            <FormattedMessage {...messages.notesHeader} />
          </Header>

          {sidebarState.author ? (
            <TextBlock multiLineString={sidebarState.content} />
          ) : (
            <TextArea
              rows="10"
              id="notes"
              value={sidebarState.content}
              onChange={e => dispatch({ fieldName: 'content', data: e.target.value })}
            ></TextArea>
          )}
        </Box>
      </Box>
      <Box>{error && <Message message={error} isError />}</Box>
    </>
  )

  const handleOnImageSelect = async event => {
    const file = event.target.files[0]

    if (file) {
      const formData = new FormData()
      formData.append('client_avatar_image', file, file.name)
      postClientAvatarUrl(formData, file)
    } else {
      showFileErrorMessage('Unable to fetch selected Image')
    }
  }

  const getDateStatus = startDate => {
    const currentDate = moment()
      .tz(client.timezone)
      .format('YYYY-MM-DD')
    const monitoringStartDate = moment(startDate)
      .tz(client.timezone)
      .format('YYYY-MM-DD')

    const formattedCurrentDate = moment(currentDate)
    const formattedMonitoringDate = moment(monitoringStartDate)
    const dateDiff = formattedCurrentDate.diff(formattedMonitoringDate, 'days')
    if (dateDiff >= 0) {
      return true
    }
    return false
  }

  const isFormValid = !!sidebarState.content && !sidebarState.author

  // If we don't have a client id in our data object, display a loading message
  if (!client.id || !primaryAgent || pageLoading || pageLoadError) {
    if (pageLoadError) {
      return (
        <Box fill justify="center" align="center">
          <Message message={pageLoadError} isError />
        </Box>
      )
    }
    return (
      <Header mini level="5" margin="medium">
        <FormattedMessage {...messages.loading} />
      </Header>
    )
  }
  return (
    <>
      <Grid
        responsive
        rows={['mediumPlusVert', 'auto', 'auto']}
        columns={['auto']}
        gap="none"
        areas={[['mapHeader'], ['clientInfo'], ['caseNotes']]}
        pad="none"
      >
        <HeaderLayer
          gridArea="mapHeader"
          background={colors.lightTeal}
          justify="center"
          align="center"
        />

        <Box
          gridArea="mapHeader"
          background="transparent"
          direction="column"
          justify="between"
          pad="medium"
        >
          <Box background="transparent" direction="row-responsive">
            <Box
              background="light-2"
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              direction="row"
              align="center"
              justify="center"
              round
            >
              <Text size="small">
                <Link to="/clients">Client List</Link>
                {` / ${client.first_name.concat(' ', client.last_name)}`}
              </Text>
            </Box>
          </Box>

          <Box background="transparent" direction="row-responsive" justify="between">
            <Box pad="none" round="full" height="160px" width="160px">
              <Stack anchor="bottom-right">
                <Box
                  background="light-2"
                  justify="center"
                  align="center"
                  pad="none"
                  round="full"
                  height="150px"
                  width="150px"
                >
                  <input
                    ref={imageRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleOnImageSelect}
                  />
                  <ClientAvatar
                    file={clientAvatarUrl ? clientAvatarUrl.avatar_url : null}
                    size="large"
                    type={clientAvatarUrl ? clientAvatarUrl.type : ''}
                    loading={imageLoading}
                  />
                </Box>
                <AddClientImage
                  onChange={() => {
                    imageRef.current.click()
                  }}
                />
              </Stack>
            </Box>
            <Box direction="row-responsive" align="end" gap="small">
              <Link to={`/clients/reports?id=${client.id}`}>
                <Button color="light-2" label="Reports" margin={{ bottom: 'none' }} />
              </Link>

              <Link to={`/clients/location-tracking?id=${client.id}`}>
                <Button color="light-2" label="Location Tracking" margin={{ bottom: 'none' }} />
              </Link>

              <Link to={`/clients/message-log?id=${client.id}`}>
                <Button
                  color="light-2"
                  label="Messages"
                  margin={{ top: 'none', bottom: 'none' }}
                  badge={
                    agentUnreadChatMessageCount === 0 ? false : Number(agentUnreadChatMessageCount)
                  }
                />
              </Link>

              <DropButton
                label="VChecks"
                icon={<Down size="small" />}
                margin={{ bottom: 'none' }}
                reverse
                open={vcheckMenuOpen}
                onClose={() => setVCheckMenuOpen(false)}
                onOpen={() => setVCheckMenuOpen(true)}
                dropContent={
                  <Box
                    size="large"
                    pad="small"
                    style={{
                      fontWeight: 'bold',
                      color: colors.primary,
                    }}
                    background="light-1"
                  >
                    <Box justify="end" align="end">
                      <Button
                        style={{ margin: '0', padding: '0' }}
                        color="transparent"
                        icon={<Close size="small" />}
                        onClick={() => setVCheckMenuOpen(false)}
                      />
                    </Box>

                    <Box gap="small" margin={{ bottom: 'small', horizontal: 'small' }}>
                      <Box gap="small">
                        <StyledLink to={`/clients/vchecks?id=${clientId}`}>
                          <Text size="small">All VChecks</Text>
                        </StyledLink>
                      </Box>

                      <DropDivider />

                      <Box onClick={sendVCheck}>
                        <Text size="small">Send VCheck Request</Text>
                      </Box>
                    </Box>
                  </Box>
                }
                dropAlign={{ top: 'bottom', right: 'right' }}
              />
            </Box>
          </Box>
        </Box>

        <Box gridArea="clientInfo" direction="column" pad="small">
          <Box
            direction="row"
            justify="between"
            align="center"
            pad={{ horizontal: 'medium', vertical: 'small' }}
          >
            <Box direction="row" gap="xsmall" align="center" justify="center">
              <StreetView size="medium" color={colors.teal} />

              <Text size="medium">{client.first_name.concat(' ', client.last_name)}</Text>

              {inactiveFlag && (
                <Box
                  background="accent-1"
                  margin={{ left: 'xsmall' }}
                  pad={{ horizontal: 'small' }}
                >
                  <Text size="small" weight="bold" textAlign="center">
                    Inactive
                  </Text>
                </Box>
              )}
            </Box>

            <Box direction="row" gap="xsmall" justify="center">
              {/* <Box align="center" justify="center">
                <a href="#">Last Known Location</a>
              </Box> */}
              <Box>{error && <Message message={error} isError />}</Box>
              <DropButton
                style={{ background: 'transparent' }}
                icon={<SettingsOption size="medium" color="brand" />}
                margin={{ bottom: 'none' }}
                open={settingsMenuOpen}
                onClose={() => setSettingsMenuOpen(false)}
                onOpen={() => setSettingsMenuOpen(true)}
                dropContent={
                  <Box
                    size="large"
                    pad="small"
                    style={{
                      fontWeight: 'bold',
                      color: colors.primary,
                    }}
                    background="light-1"
                  >
                    <Box justify="end" align="end">
                      <Button
                        style={{ margin: '0', padding: '0' }}
                        color="transparent"
                        icon={<Close size="small" />}
                        onClick={() => setSettingsMenuOpen(false)}
                      />
                    </Box>

                    <Box margin={{ bottom: 'small', horizontal: 'small' }} gap="small">
                      <Box>
                        <StyledLink to={`/clients/wizard?id=${clientId}`}>
                          <Text size="small">Edit Client</Text>
                        </StyledLink>
                      </Box>
                      {/* when Flag is removed, remove bottom/vertical/top pad and use gap on outer Box */}
                      {(client.primary_agent_id === user.agentId || isDistributor) &&
                        getDateStatus(client.monitoring_start) && (
                        <>
                          <DropDivider />

                          <Box pad={{ top: 'small' }} onClick={openConfirmModal}>
                            <Text size="small">
                             {inactiveFlag ? 'Reactivate Client' : 'Deactivate Client'}
                            </Text>
                          </Box>
                        </>
                      )}

                      <DropDivider />

                      <Box onClick={sendInstallLink}>
                        <Text size="small">Send Install Link</Text>
                      </Box>
                    </Box>
                  </Box>
                }
                dropAlign={{ top: 'bottom', right: 'right' }}
              />
            </Box>
          </Box>

          <Divider />

          <Box direction="row" justify="between" pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Header mini level="4" margin={{ vertical: 'xsmall' }} color={colors.mediumGrey}>
                Monitoring Start
              </Header>

              <Text size="small">
                {client.monitoring_start
                  ? moment.utc(client.monitoring_start).format('MMMM Do, YYYY')
                  : 'Not Found'}
              </Text>
            </Box>

            <Box>
              <Header mini level="4" margin={{ vertical: 'xsmall' }} color={colors.mediumGrey}>
                Monitoring End
              </Header>

              <Text size="small">{monitoringEndDate}</Text>
            </Box>

            <Box>
              <Header mini level="4" margin={{ vertical: 'xsmall' }} color={colors.mediumGrey}>
                Supervision Type
              </Header>

              <Text size="small">
                {client.supervision_type ? client.supervision_type : 'Not Set'}
              </Text>
            </Box>

            <Box>
              <Header mini level="4" margin={{ vertical: 'xsmall' }} color={colors.mediumGrey}>
                Primary Agent
              </Header>

              <Text size="small">{primaryAgent}</Text>
            </Box>

            <Box>
              <Header mini level="4" margin={{ vertical: 'xsmall' }} color={colors.mediumGrey}>
                VChecks Completed
              </Header>

              <Text size="small" alignSelf="center">
                {clientVCheckCount || 0}
              </Text>
            </Box>

            <Box>
              <Header mini level="4" margin={{ vertical: 'xsmall' }} color={colors.mediumGrey}>
                Days Active
              </Header>

              <Text size="small" alignSelf="center">
                {daysActive}
              </Text>
            </Box>

            <Box>
              <Header mini level="4" margin={{ vertical: 'xsmall' }} color={colors.mediumGrey}>
                Unread Chat Messages
              </Header>

              <Text size="small" alignSelf="center">
                {agentUnreadChatMessageCount || 0}
              </Text>
            </Box>
          </Box>

          <Divider />
        </Box>

        <Box gridArea="caseNotes" pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box direction="row" justify="between" align="center">
            Case Notes
            <Button
              primary
              color="accent-1"
              icon={<Add size="small" />}
              label={intl.formatMessage(messages.addNote)}
              style={{
                borderRadius: 27,
                fontSize: 15,
                fontWeight: 'bold',
                width: 170,
              }}
              onClick={openModal}
            />
          </Box>

          <CaseNotesTable
            notes={clientCaseNotes}
            viewInSidebar={viewNoteInSidebar}
            clientZone={client.timezone || 'UTC'}
          ></CaseNotesTable>
        </Box>
      </Grid>

      {!!showConfirmModal && (
        <Layer position="center" onClickOutside={closeConfirmModal} onEsc={closeConfirmModal}>
          <Box pad="large" width="medium" justify="center" align="center">
            <Text size="medium" weight="bold" textAlign="center">
              <FormattedMessage {...messages.confirmModalHeader} />
            </Text>

            <Box pad={{ vertical: 'medium' }}>
              <Text size="small" textAlign="center">
                {inactiveFlag ? (
                  <FormattedMessage {...messages.reactivateModalMessage} />
                ) : (
                  <FormattedMessage {...messages.deactivateModalMessage} />
                )}
              </Text>
            </Box>

            <Box direction="row" gap="small">
              <Button
                color="status-unknown"
                label={intl.formatMessage(messages.cancelButton)}
                primary={false}
                margin={{ vertical: 'none' }}
                onClick={closeConfirmModal}
              />

              <Button
                color={inactiveFlag ? 'accent-1' : 'status-error'}
                disabled={loading}
                label={
                  inactiveFlag
                    ? intl.formatMessage(messages.reactivateButton)
                    : intl.formatMessage(messages.deactivateButton)
                }
                margin={{ vertical: 'none' }}
                onClick={inactiveFlag ? reactivateConfirm : deactivateConfirm}
              />
            </Box>

            <Box>{activationError && <Message message={activationError} isError />}</Box>
          </Box>
        </Layer>
      )}

      {!!showSidebar && (
        <WizardSidebar
          disabled={loading}
          onClose={onClose}
          isFormValid={isFormValid}
          onSubmit={onSubmit}
          header={sideBarHeader}
          content={sidebarContent}
        ></WizardSidebar>
      )}

      {!!successMessage && (
        <Notification
          position="top-right"
          message={successMessage}
          onClose={() => showSuccessMessage(false)}
        ></Notification>
      )}
      {!!fileError && (
        <Notification
          position="top-right"
          message={fileError}
          onClose={() => showFileErrorMessage(false)}
        ></Notification>
      )}
    </>
  )
})

const HeaderLayer = styled(Box)`
  z-index: -1;
`

const Divider = styled.div`
  margin: 15px 0;
  border: 0.5px solid lightGrey;
  width: 100%;
`

const DropDivider = styled.div`
  margin: 0;
  border: 0.5px solid lightGrey;
  width: 100%;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

ClientSummaryPage.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ClientSummaryPage)
