/* eslint-disable react/prop-types */
import React from 'react'
import { TableHeader } from 'grommet'

import { doc } from './TableFooter.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * TableHeader
 *
 */
function createTableHeader({ children, style = {}, ...props }) {
  return (
    <TableHeader
      style={{
        letterSpacing: '1px',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 'bold',
        ...style,
      }}
      {...props}
    >
      {children}
    </TableHeader>
  )
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createTableHeader,
})
