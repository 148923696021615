import { defineMessages } from 'react-intl'

export const scope = 'app.components.VCheckReviewTable'

/*
 * VCheckReviewTable Messages
 *
 * This contains all the text for the VCheckReviewTable component.
 */
export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Event Data...',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Client Name',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Agency Name',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Event Type',
  },
  tableHeaderFour: {
    id: `${scope}.tableHeaderFour`,
    defaultMessage: 'Created',
  },
  tableHeaderFive: {
    id: `${scope}.tableHeaderFive`,
    defaultMessage: 'Location',
  },
  tableHeaderSix: {
    id: `${scope}.tableHeaderSix`,
    defaultMessage: 'Status',
  },
  tableHeaderSeven: {
    id: `${scope}.tableHeaderSeven`,
    defaultMessage: 'Facial Rec Status',
  },
})
