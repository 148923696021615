import React from 'react'
import { IntlProvider } from 'react-intl'

// Components
import { LoadingIndicator } from 'components/LoadingIndicator'

// Utils, Services & Messages
import loadable from 'utils/loadable'

/**
 * Asynchronously loads the component for ClientPage
 */
export default loadable(() => import('./ClientPage'), {
  fallback: (
    <IntlProvider locale="en">
      <LoadingIndicator />
    </IntlProvider>
  ),
})
