import { defineMessages } from 'react-intl'

export const scope = 'app.components.ClientVCheckTable'

/*
 * ClientVCheckTable Messages
 *
 * This contains all the text for the ClientVCheckTable component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ClientVCheckTable component!',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Scheduled',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Completed',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Type',
  },
  tableHeaderFour: {
    id: `${scope}.tableHeaderFour`,
    defaultMessage: 'Status',
  },
  tableHeaderFive: {
    id: `${scope}.tableHeaderFive`,
    defaultMessage: 'Reviewed On',
  },
  tableHeaderSix: {
    id: `${scope}.tableHeaderSix`,
    defaultMessage: 'Reviewed By',
  },
  tableHeaderSeven: {
    id: `${scope}.tableHeaderSix`,
    defaultMessage: 'Review',
  },
  tableHeaderEight: {
    id: `${scope}.tableHeaderEight`,
    defaultMessage: 'Facial Rec Status',
  },
})
