import { defineMessages } from 'react-intl'

export const scope = 'app.components.ScheduledVCheckTable'

/*
 * ScheduledVCheckTable Messages
 *
 * This contains all the text for the ScheduledVCheckTable component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ScheduledVCheckTable component!',
  },
  tableHeaderOne: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Name',
  },
  tableHeaderTwo: {
    id: `${scope}.tableHeaderOne`,
    defaultMessage: 'Start Date',
  },
  tableHeaderThree: {
    id: `${scope}.tableHeaderTwo`,
    defaultMessage: 'Time',
  },
  tableHeaderFour: {
    id: `${scope}.tableHeaderThree`,
    defaultMessage: 'Schedule Description',
  },
})
