import { describe, PropTypes } from 'react-desc'

import { genericProps } from '../../utils/propTypes'

export const doc = Stack => {
  const DocumentedSelect = describe(Stack)
    .description('A control to select a value, with optional search.')
    .usage(
      `import { Select } from 'grommet';
<Select />`,
    )
  // We don't include svg due to a collision on the values property
  // .intrinsicElement('select');

  DocumentedSelect.propTypes = {
    ...genericProps,
    children: PropTypes.func.description(
      `Function that will be called when each option is rendered.
      It will be passed (option, index, options, state) where option
      is the option to render, index is the index of that option in the
      options array, and state is an object with
      { active, disabled, selected } keys indicating the current state
      of the option.`,
    ),

    anchor: PropTypes.oneOfType([
      PropTypes.oneOf([
        'center',
        'top',
        'left',
        'bottom',
        'right',
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ]),
      PropTypes.string,
    ]).description(
      `Where to anchor children from. If not specified, children fill the guiding child's area`,
    ),
    fill: PropTypes.oneOfType([
      PropTypes.oneOf(['horizontal', 'vertical']),
      PropTypes.bool,
    ]).description('Whether the width and/or height should fill the container.'),
    guidingChild: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf(['first', 'last']),
      PropTypes.string,
    ]).description(
      `Which child to guide layout from. All other children will be positioned within that area. Defaults to 'first'`,
    ),
    interactiveChild: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf(['first', 'last']),
      PropTypes.string,
    ]).description(
      `Which child to restrict user interaction to. All other children will have user interaction disabled`,
    ),
  }

  return DocumentedSelect
}

export const themeDoc = {
  'stack.extend': {
    description: 'Any additional style for the control of the Stack component.',
    type: 'string | (props) => {}',
    defaultValue: undefined,
  },
}
