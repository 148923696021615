/* eslint-disable indent */
/* global google */
import React, { useEffect, useContext, useState, useReducer, useRef, useMemo } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Add, Edit } from 'grommet-icons'
import { RadioButton, RangeInput } from 'grommet'
import moment from 'moment'
import { findIndex } from 'lodash'
import styled from 'styled-components'

// Components
import { Button } from 'components/Button'
import { ClientGlobalZoneSidebar } from 'components/ClientGlobalZoneSidebar'
import { Box } from 'components/Box'
import { Checkbox } from 'components/Checkbox'
import { FormField } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { LocationsTable } from 'components/LocationsTable'
import { Message } from 'components/Message'
import { Text } from 'components/Text'
import { TextInput } from 'components/TextInput'
import { VCheckScheduler } from 'components/VCheckScheduler'
import { WizardSidebar } from 'components/WizardSidebar'
import { GoogleMaps } from 'components/Maps'

// Stores
import { AgencyStoreContext } from '../../stores/AgencyStore'
import { ClientWizardStoreContext } from '../../stores/ClientWizardStore'
import { UserStoreContext } from '../../stores/UserStore'
import { ZoneStoreContext } from '../../stores/ZoneStore'

// Utils, Services & Messages
import messages from './AddClientZone.messages'
import { getCoordinateObject } from '../../utils/helpers'
import placesAddressParser from '../../utils/placesAddressParser'
import rruleBuilder from '../../utils/rruleBuilder'
import useFlashMessage from '../../hooks/FlashMessage'

// Styles
import colors from '../../utils/colors'

const GEOFENCE_TYPE = {
  circle: 'circle',
  polygon: 'polygon',
  none: 'none',
}
const minimumGeofenceDiameter = 300
const minimumGeofenceArea = 10765

const defaultPageNumber = 1
const defaultPageCount = -1

/**
 *
 * AddClientZone
 *
 * This container holds all components needed to build the second step
 * in the Add Client flow - Add Zones
 *
 * User can add a new Location by clicking on the "Add Location" button
 * This will open a sidebar form that takes in the following details:
 *
 * - Location Name
 * - Location Address (uses autocomplete from google api)
 * - Whether or not the location is a "Zone"
 *
 * If the user chooses to make the location a zone, they can define:
 * - Geofence radius (controlled by slider input)
 * - Zone Type (Inclusion/Zone Of Interest)
 *
 * If the use chooses Inclusion Zone, a VCheck can be scheduled using
 * the VCheckScheduler component used across the Client Wizard
 *
 * Lastly, the user can decide if they want this location to trigger
 * a VCheck, which defaults to selected
 *
 */
const AddClientZone = observer(({ intl, goToStep }) => {
  const { user, isDistributor } = useContext(UserStoreContext)
  const {
    clientBasicInfo,
    clientLocations,
    clientGlobalZones,
    distributorClientSelectedAgencyId,
    getClientLocationsById,
    postClientLocationsById,
    updateClientLocationById,
    deleteLocationById,
    getClientGlobalZonesById,
    postGlobalZonesToClient,
  } = useContext(ClientWizardStoreContext)

  const zoneStroe = useContext(ZoneStoreContext)

  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [pageLoadError, showPageLoadError] = useState(null)
  const [loading, setLoading] = useState(false)

  // Retreive the client ID from URL parameters
  const urlParams = new URLSearchParams(window.location.search)
  const clientIdParam = urlParams.get('id')
  const [clientId, setClientId] = useState()
  const [agencyId, setAgencyId] = useState(
    isDistributor ? distributorClientSelectedAgencyId : user.agencyId,
  )

  // set client state id to param id
  useEffect(() => {
    async function setDefaultData() {
      if (clientIdParam) {
        setClientId(clientIdParam)
        // Load locations from API instead of getting the locations from the store
        const locations = await getClientLocationsById(
          agencyId,
          clientIdParam,
          showPageLoadError,
          setLoading,
        )
        setCreatedZones(locations)

        await getClientGlobalZonesById(agencyId, clientIdParam, showPageLoadError, setLoading)
        await zoneStroe.getAgencyZones(
          agencyId,
          setLoading,
          showError,
          defaultPageNumber,
          defaultPageCount,
        )
      }
    }

    setDefaultData()
  }, [clientLocations.length])

  // map data
  const mapRef = useRef(null)
  const geofenceType = useRef(GEOFENCE_TYPE.none)
  const geofenceCircle = useRef(null)
  const geofencePolygon = useRef(null)
  const geofencePolygonCoordiantes = useRef([])
  const currentMarker = useRef(null)
  const diameterSlideControl = useRef()
  const polygonAreaLabel = useRef()

  // list of all zones formatted to post to client
  const [createdZones, setCreatedZones] = useState()

  function handleSelection(selection) {
    // geocode to get coordinates
    dispatch({ fieldName: 'display_address', data: selection.description })
    geocodeByAddress(selection.description).then(results => {
      const addressFields = placesAddressParser.buildAddressField(results[0])
      getLatLng(results[0]).then(({ lat, lng }) => {
        dispatch({
          fieldName: 'point',
          data: {
            type: 'Point',
            coordinates: [lng, lat],
          },
        })
        // set address and places fields in zoneFormState
        dispatch({ fieldName: 'address', data: addressFields })
        dispatch({ fieldName: 'formatted_address', data: results[0].formatted_address })
        dispatch({ fieldName: 'google_places_data', data: results[0] })
      })
    })

    // set places id in zoneFormState
    dispatch({ fieldName: 'place_id', data: selection.place_id })
  }

  // radio buttons for zone type
  const radioOptionOne = (
    <Box direction="column" width={{ max: '400px' }}>
      <Header mini level="5" margin="none">
        <FormattedMessage {...messages.inclusionHeader} />
      </Header>

      <Text size="xsmall">
        <FormattedMessage {...messages.inclusionDescription} />
      </Text>
    </Box>
  )

  const radioOptionTwo = (
    <Box direction="column" width={{ max: '400px' }}>
      <Header mini level="5" margin="none">
        <FormattedMessage {...messages.ZOIHeader} />
      </Header>

      <Text size="xsmall">
        <FormattedMessage {...messages.ZOIDescription} />
      </Text>
    </Box>
  )

  function zoneFormReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }
    return zoneFormInitialState
  }

  const zoneFormInitialState = {
    name: '',
    address: '',
    radius: 3000,
    type: undefined, // boolean field: false=inclusion, undefined=none
    geofence_type: GEOFENCE_TYPE.none,
    polygon: undefined,

    // vcheck schedule fields
    startDate: moment()
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    startTime: '08:00',
    endTime: '20:00',
    repeat: 'Does Not Repeat',
    repeatEvery: '1',
    frequency: 'Week(s)',
    endsOn: 'Never',
    endDate: undefined,
    occurrences: '',
    weekdays: [],
  }

  // Client Zone form data and functions
  const [zoneFormState, dispatch] = useReducer(zoneFormReducer, zoneFormInitialState)
  const [showLocationSidebar, setShowLocationSidebar] = useState(false)
  const [showSidebarZoneContent, setShowSidebarZoneContent] = useState(false)
  const [diameterSlide, setDiameterSlide] = useState(minimumGeofenceDiameter)
  const [geofenceArea, setgeofenceArea] = useState(0)
  const [showSidebarScheduleContent, setShowSidebarScheduleContent] = useState(false)
  const [radioSelect, setRadioSelect] = useState() // null, inclusion, or exclusion
  const [triggerVCheck, setTriggerVCheck] = useState(true)

  const onClose = () => {
    setShowLocationSidebar(false)
    setShowSidebarZoneContent(false)
    setDiameterSlide(minimumGeofenceDiameter)
    setShowSidebarScheduleContent(false)
    setTriggerVCheck(true)
    setRadioSelect(null)
  }
  const openModal = () => {
    setShowLocationSidebar(true)
  }

  const setRadioChoice = choice => {
    setRadioSelect(choice)
    dispatch({ fieldName: 'type', data: choice })
  }

  const onSubmit = async () => {
    let requestResponse
    let rrule
    let timeDiff
    let pointLat
    let pointLng
    if (zoneFormState.type === 'inclusion' && showSidebarScheduleContent) {
      rrule = rruleBuilder.rruleToString(zoneFormState)
      const startTime = moment(zoneFormState.startTime, 'HH:mm')
      const endTime = moment(zoneFormState.endTime, 'HH:mm')
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day')
      }
      timeDiff = endTime.diff(startTime, 'seconds')
    }

    // Update our local state and reflect the changes in the API

    if (geofenceType.current === GEOFENCE_TYPE.circle) {
      pointLat = geofenceCircle.current.center.lat()
      pointLng = geofenceCircle.current.center.lng()
    } else {
      const bounds = new google.maps.LatLngBounds()
      for (let i = 0; i < geofencePolygon.current.getPath().length; i += 1) {
        bounds.extend(geofencePolygon.current.getPath().getAt(i))
      }
      pointLat = bounds.getCenter().lat()
      pointLng = bounds.getCenter().lng()
    }

    if (zoneFormState.id) {
      // Handle setting data based on whether custom zones/schedules are enabled
      let updatedZone = {}
      if (showSidebarZoneContent === true) {
        // Add all data, including custom zone/schedules
        updatedZone = {
          // Add all form data
          ...zoneFormState,
          // Custom formatted data
          radius:
            geofenceType.current === GEOFENCE_TYPE.circle
              ? (diameterSlide / 6.6).toFixed(2)
              : minimumGeofenceDiameter,
          geofence_type: geofenceType.current,
          point: {
            type: 'Point',
            coordinates: [pointLng, pointLat],
          },
          polygon: {
            type: 'Polygon',
            coordinates: [geofencePolygonCoordiantes.current],
          },
          schedule: rrule,
          duration: timeDiff,
          trigger_vcheck: triggerVCheck,
        }
        // Clear out schedule data if toggle is off
        if (!showSidebarScheduleContent) {
          updatedZone = {
            ...updatedZone,
            schedule: null,
            duration: null,
            endDate: null,
            endTime: null,
            endsOn: null,
            frequency: null,
            occurrences: null,
            repeat: null,
            repeatEvery: null,
            startDate: null,
            startTime: null,
            weekdays: null,
          }
        }
      } else {
        // Remove custom zone/schedules
        updatedZone = {
          // Add default data to ensure that zone/schedule information is removed if unset
          ...zoneFormInitialState,
          id: zoneFormState.id,
          client: clientId,
          name: zoneFormState.name,
          address: zoneFormState.address,
          formatted_address: zoneFormState.formatted_address,
          type: null,
          radius: null, // radius defaults to 3000, so we must reset it manually
          trigger_vcheck: triggerVCheck,
        }
      }

      // Update the zone in the API
      requestResponse = await updateClientLocationById(
        agencyId,
        clientId,
        updatedZone.id,
        updatedZone,
        setLoading,
        showError,
      )

      if (requestResponse) {
        // Replace the current value of the zone with the updated version in our store
        const index = findIndex(createdZones, prevZone => prevZone.id === updatedZone.id)
        setCreatedZones(Object.assign([], createdZones, { [index]: requestResponse }))
      }
    } else {
      const newZone = {
        ...zoneFormState,
        id: zoneFormState.id,
        radius:
          geofenceType.current === GEOFENCE_TYPE.circle
            ? (diameterSlide / 6.6).toFixed(2)
            : minimumGeofenceDiameter,
        geofence_type: geofenceType.current,
        point: {
          type: 'Point',
          coordinates: [pointLng, pointLat],
        },
        polygon: {
          type: 'Polygon',
          coordinates: [geofencePolygonCoordiantes.current],
        },
        client: clientId,
        schedule: rrule,
        duration: timeDiff,
        type: zoneFormState.type,
        trigger_vcheck: triggerVCheck,
      }
      // new zone, add to list and POST
      requestResponse = await postClientLocationsById(
        agencyId,
        clientId,
        newZone,
        setLoading,
        showError,
      )

      // Use the API response as the new VCheck's data since it includes the ID
      if (requestResponse) {
        setCreatedZones([...createdZones, requestResponse])
      }
    }

    if (requestResponse) {
      // clear sidebar form and set toggles back to default
      dispatch({ type: 'reset' })
      onClose()
    }
  }
  const createGeofenceFromLocalStore = () => {
    if (zoneFormState) {
      const lat = parseFloat(zoneFormState.point.coordinates[1])
      const lng = parseFloat(zoneFormState.point.coordinates[0])
      let geofence = null
      if (zoneFormState.geofence_type === GEOFENCE_TYPE.circle) {
        geofence = new google.maps.Circle({
          center: { lat, lng },
          radius: zoneFormState.radius,
        })
      } else if (zoneFormState.geofence_type === GEOFENCE_TYPE.polygon) {
        geofencePolygonCoordiantes.current = []
        const paths = zoneFormState.polygon.coordinates[0]
        paths.forEach(element => {
          geofencePolygonCoordiantes.current.push({
            lat: element[1],
            lng: element[0],
          })
        })
        geofence = new google.maps.Polygon({
          paths: geofencePolygonCoordiantes.current,
        })
      }
      if (geofence) renderGeofence(zoneFormState.geofence_type, geofence)
      else if (zoneFormState.point)
        renderMarker(zoneFormState.point.coordinates[1], zoneFormState.point.coordinates[0])
    }
  }

  const editInSidebar = location => {
    dispatch({
      type: 'update',
      data: {
        id: location.id,
        exists: true,
        name: location.name,
        address: location.address,
        display_address: location.formatted_address,
        formatted_address: location.formatted_address,
        point: location.point,
        geofence_type: location.geofence_type,
        polygon: location.polygon,
        google_places_data: location.google_places_data,
        radius: location.radius,
        type: location.type,
        trigger_vcheck: location.trigger_vcheck,

        startDate: location.startDate || zoneFormInitialState.startDate,
        startTime: location.startTime || zoneFormInitialState.startTime,
        endTime: location.endTime || zoneFormInitialState.endTime,
        repeat: location.repeat || zoneFormInitialState.repeat,
        repeatEvery: location.repeatEvery || zoneFormInitialState.repeatEvery,
        frequency: location.frequency || zoneFormInitialState.frequency,
        endsOn: location.endsOn || zoneFormInitialState.endsOn,
        endDate: location.endDate,
        occurrences: location.occurrences,
        weekdays: location.weekdays,
      },
    })

    // set "show" fields as needed
    setShowSidebarZoneContent(!!location.type)
    setDiameterSlide(location.radius ? (location.radius * 6.6).toFixed(0) : 3300) // radius m to diameter ft
    setShowSidebarScheduleContent(!!location.startDate)
    setTriggerVCheck(location.trigger_vcheck)
    setRadioChoice(location.type)

    // open modal
    openModal()
  }

  const onDelete = async () => {
    let requestSuccess = true

    // if it has been previously saved, send request to delete endpoint
    if (zoneFormState.id) {
      requestSuccess = await deleteLocationById(
        agencyId,
        clientId,
        zoneFormState.id,
        setLoading,
        showError,
      )
    }

    if (requestSuccess) {
      // remove from scheduledVCheck list (use id if available, otherwise name)
      const filteredZones = createdZones.filter(zone =>
        zone.id ? zone.id !== zoneFormState.id : zone.name !== zoneFormState.name,
      )
      setCreatedZones(filteredZones)

      // clear sidebar form state
      dispatch({ type: 'reset' })
      onClose()
    }
  }

  // Global Zones sidebar data and functions
  const [showGlobalZonesSidebar, setShowGlobalZonesSidebar] = useState(false)

  const onGlobalZonesSidebarClose = () => {
    setShowGlobalZonesSidebar(false)
  }
  const openGlobalZonesSidebar = () => {
    setShowGlobalZonesSidebar(true)
  }

  const onSubmitGlobalZones = async zones => {
    const requestResponse = await postGlobalZonesToClient(
      agencyId,
      clientId,
      zones,
      setLoading,
      showError,
    )

    if (requestResponse) {
      onGlobalZonesSidebarClose()
    }
  }

  // Page button functions

  const saveAndBack = () => {
    goToStep(0)
  }

  const saveLocationsAndContinue = () => {
    goToStep(2)
  }
  // Map Functions
  const mapObj = useMemo(
    () => (
      <GoogleMaps
        center={zoneFormState.point ? getCoordinateObject(zoneFormState.point.coordinates) : null}
        enableDrawingmode
        onPolygonCompleted={polygon => {
          polygon.setMap(null)
          renderGeofence(GEOFENCE_TYPE.polygon, polygon)
        }}
        onCircleCompleted={circle => {
          circle.setMap(null)
          renderGeofence(GEOFENCE_TYPE.circle, circle)
        }}
        onMapLoaded={map => {
          if (map) {
            mapRef.current = map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED // # Need to review the reference
            createGeofenceFromLocalStore()
          }
        }}
      />
    ),
    [zoneFormState.point],
  )

  function clearMap() {
    if (geofenceCircle.current) {
      geofenceCircle.current.setMap(null)
      geofenceCircle.current = null
    }
    if (geofencePolygon.current) {
      geofencePolygon.current.setMap(null)
      geofencePolygon.current = null
    }
    if (currentMarker.current) {
      currentMarker.current.setMap(null)
      currentMarker.current = null
    }
  }

  function renderMarker(markerLat, markerLng) {
    currentMarker.current = new google.maps.Marker({
      position: { lat: markerLat, lng: markerLng },
      map: mapRef.current,
    })
  }

  function renderGeofence(type, geofence) {
    clearMap()
    diameterSlideControl.current.hidden = !(
      diameterSlideControl.current && type === GEOFENCE_TYPE.circle
    )
    polygonAreaLabel.current.hidden = !(polygonAreaLabel.current && type === GEOFENCE_TYPE.polygon)
    if (type === GEOFENCE_TYPE.circle) {
      const circle = new google.maps.Circle({
        strokeWeight: 2,
        fillColor: colors.purple,
        fillOpacity: 0.3,
        map: mapRef.current,
        center: { lat: geofence.center.lat(), lng: geofence.center.lng() },
        radius: geofence.radius,
      })
      geofenceType.current = GEOFENCE_TYPE.circle
      geofenceCircle.current = circle
      setDiameterSlide((circle.radius * 6.6).toFixed(0))
      // Drawing the Marker
      renderMarker(geofenceCircle.current.center.lat(), geofenceCircle.current.center.lng())
      // reset the zoom level
      if (mapRef && circle) mapRef.current.fitBounds(circle.getBounds())
    } else if (type === GEOFENCE_TYPE.polygon) {
      const polygon = new google.maps.Polygon({
        paths: geofence.getPath().getArray(),
        strokeWeight: 2,
        fillColor: colors.purple,
        fillOpacity: 0.3,
        map: mapRef.current,
      })
      geofencePolygonCoordiantes.current = []
      geofence
        .getPath()
        .getArray()
        .forEach(element => {
          geofencePolygonCoordiantes.current.push([element.lng(), element.lat()])
        })
      geofencePolygonCoordiantes.current.push(geofencePolygonCoordiantes.current[0])
      geofenceType.current = GEOFENCE_TYPE.polygon
      geofencePolygon.current = polygon
      const geofenceArea = google.maps.geometry.spherical.computeArea(polygon.getPath())
      setgeofenceArea((geofenceArea * 10.76).toFixed(2))
      // Drawing the Marker
      const bounds = new google.maps.LatLngBounds()
      for (let i = 0; i < geofence.getPath().length; i += 1) {
        bounds.extend(geofence.getPath().getAt(i))
      }
      renderMarker(bounds.getCenter().lat(), bounds.getCenter().lng())
      if (mapRef) mapRef.current.fitBounds(bounds)
    }
  }
  // build sidebar header and content for client locations
  const locationSidebarHeader = zoneFormState.exists ? (
    <FormattedMessage {...messages.locationSidebarHeaderEdit} />
  ) : (
    <FormattedMessage {...messages.locationSidebarHeaderAdd} />
  )
  const sidebarContent = (
    <Grid
      columns={['1']}
      rows={['1/6', '1/6', '1/6', '1/6', '1/6', '1/6', '1/6']}
      areas={[['name'], ['address'], ['zoneToggle'], ['map'], ['radioGroup'], ['errorMessage']]}
      gap="small"
    >
      <Box gridArea="name">
        <Header mini level="5" margin={{ top: 'none' }}>
          <FormattedMessage {...messages.locationDetailsHeader} />
        </Header>

        <FormField
          component={TextInput}
          id="location_name"
          name="location_name"
          label={intl.formatMessage(messages.locationNameLabel)}
          required
          onChange={e => dispatch({ fieldName: 'name', data: e.target.value })}
          value={{ value: zoneFormState.name }}
        />
      </Box>

      <Box gridArea="address">
        <FormField
          required
          component={PlacesAutocomplete}
          id="location_address"
          name="location_address"
          label={intl.formatMessage(messages.locationAddressLabel)}
          value={{ value: zoneFormState.display_address }}
          inputStyle={{ width: '100%', fontSize: '14px', border: 'none' }}
          onSelect={handleSelection}
          placeholder={zoneFormState.display_address}
          suggestionsStyles={{
            container: {
              color: colors.secondary,
              fontWeight: 'bold',
            },
            suggestion: {
              fontSize: '14px',
              padding: '5px 10px',
            },
          }}
        />
      </Box>

      <Box gridArea="zoneToggle" direction="row" justify="between" align="center">
        <Box direction="column">
          <Header mini level="5" margin="none">
            <FormattedMessage {...messages.zoneToggleHeader} />
          </Header>

          <Text size="xsmall">
            <FormattedMessage {...messages.zoneToggleDescription} />
          </Text>
        </Box>

        <Checkbox
          id="zone_toggle"
          toggle
          disabled={!zoneFormState.address}
          checked={showSidebarZoneContent === true}
          onChange={e => setShowSidebarZoneContent(e.target.checked)}
        ></Checkbox>
      </Box>

      {!!showSidebarZoneContent && (
        <>
          <Box gridArea="map" height="medium" margin={{ vertical: 'small' }}>
            {zoneFormState.point && <>{mapObj}</>}
            <div ref={diameterSlideControl}>
              <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
                <FormattedMessage {...messages.geofenceDiameterLabel} />
                {diameterSlide > 5200 ? 5280 : diameterSlide}
                {' ft'}
              </Header>

              <RangeInput
                min={minimumGeofenceDiameter}
                max={5300}
                step={100}
                value={diameterSlide}
                onChange={e => {
                  const circle = geofenceCircle.current
                  circle.radius = e.target.value / 6.6
                  renderGeofence(GEOFENCE_TYPE.circle, circle)
                  setDiameterSlide(e.target.value)
                }}
              />
              {geofenceType.current === GEOFENCE_TYPE.circle &&
                diameterSlide < minimumGeofenceDiameter && (
                  <Text size="small" color="status-critical">
                    <FormattedMessage {...messages.geofenceMinDiameterInfo} />
                  </Text>
                )}
            </div>
            <div ref={polygonAreaLabel}>
              <Header mini level="5" margin={{ top: 'medium', bottom: 'xsmall' }}>
                <FormattedMessage {...messages.geofenceAreaLabel} />
                {geofenceArea}
                {' (SQUARE FEET) '}
              </Header>
              {geofenceType.current === GEOFENCE_TYPE.polygon &&
                geofenceArea < minimumGeofenceArea && (
                  <Text size="small" color="status-critical">
                    <FormattedMessage {...messages.geofenceMinAreaInfo} />
                  </Text>
                )}
            </div>
          </Box>

          <Box gridArea="radioGroup">
            <Box align="start">
              <RadioButton
                name="radio"
                label={radioOptionOne}
                checked={radioSelect === 'inclusion'}
                onChange={() => setRadioChoice('inclusion')}
              />
              {radioSelect === 'inclusion' && (
                <Box
                  width="100%"
                  gridArea="inclusion"
                  border={{ color: 'lightGrey', side: 'bottom' }}
                  margin={{ vertical: 'small' }}
                >
                  <Box direction="row-responsive" justify="between" align="center">
                    <Box direction="column">
                      <Header mini level="5" margin="none">
                        <FormattedMessage {...messages.scheduleHeader} />
                      </Header>

                      <Text size="xsmall">
                        <FormattedMessage {...messages.scheduleDescription} />
                      </Text>
                    </Box>

                    <Checkbox
                      id="schedule_toggle"
                      toggle
                      checked={showSidebarScheduleContent}
                      onChange={e => setShowSidebarScheduleContent(e.target.checked)}
                    ></Checkbox>
                  </Box>

                  {!!showSidebarScheduleContent && (
                    <VCheckScheduler
                      state={zoneFormState}
                      dispatch={dispatch}
                      isEndTimeEditable
                      lowerBoundDate={moment
                        .tz(clientBasicInfo.monitoring_start, clientBasicInfo.timezone)
                        .format('YYYY-MM-DD')}
                    ></VCheckScheduler>
                  )}

                  <Box
                    direction="row-responsive"
                    justify="between"
                    align="center"
                    margin={{ vertical: 'small' }}
                  >
                    <Box direction="column">
                      <Header mini level="5" margin="none">
                        <FormattedMessage {...messages.triggerHeader} />
                      </Header>

                      <Text size="xsmall">
                        <FormattedMessage {...messages.triggerDescription} />
                      </Text>
                    </Box>

                    <Checkbox
                      id="trigger_toggle"
                      toggle
                      checked={triggerVCheck}
                      onChange={e => setTriggerVCheck(e.target.checked)}
                    ></Checkbox>
                  </Box>
                </Box>
              )}

              <Box margin={{ vertical: 'small' }}>
                <RadioButton
                  name="radio"
                  label={radioOptionTwo}
                  checked={radioSelect === 'exclusion'}
                  onChange={() => setRadioChoice('exclusion')}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>
    </Grid>
  )

  const isFormValid =
    !!zoneFormState.name &&
    !!zoneFormState.address &&
    !!zoneFormState.type &&
    (geofenceType.current === GEOFENCE_TYPE.circle
      ? diameterSlide >= minimumGeofenceDiameter
      : geofenceArea >= minimumGeofenceArea)

  if (pageLoadError) {
    return (
      <Box fill justify="center" align="center">
        <Message message={pageLoadError} isError />
      </Box>
    )
  }

  return (
    <Box margin={{ bottom: 'large' }} width={{ max: '700px' }}>
      <Grid
        responsive
        rows={['auto', 'auto', 'auto']}
        columns={['auto', 'small']}
        gap="xsmall"
        areas={[
          ['locationHeader', 'empty'],
          ['locationDescription', 'addLocation'],
          ['locationTable', 'locationTable'],
        ]}
        pad={{ bottom: 'small' }}
      >
        <Header mini level="5" gridArea="locationHeader">
          <FormattedMessage {...messages.locationHeader} />
        </Header>

        <Box gridArea="locationDescription" justify="center">
          <Text size="xsmall">
            <FormattedMessage {...messages.locationDescription} />
          </Text>
        </Box>

        <Box gridArea="addLocation">
          <Button
            id="add-location-button"
            color="focus"
            icon={<Add size="small" />}
            label={intl.formatMessage(messages.locationButtonText)}
            onClick={() => {
              dispatch({ type: 'reset' })
              openModal()
            }}
          />
        </Box>

        {createdZones && createdZones.length > 0 && (
          <Box gridArea="locationTable">
            <LocationsTable editable locations={createdZones} editInSidebar={editInSidebar} />
          </Box>
        )}
      </Grid>

      <Divider />

      <Grid
        responsive
        rows={['auto', 'auto', 'auto']}
        columns={['auto', 'small']}
        gap="xsmall"
        areas={[
          ['globalHeader', 'empty'],
          ['globalDescription', 'addGlobal'],
          ['locationTable', 'locationTable'],
        ]}
        pad={{ bottom: 'small' }}
      >
        <Header mini level="5" gridArea="globalHeader">
          <FormattedMessage {...messages.globalZoneHeader} />
        </Header>

        <Box gridArea="globalDescription" justify="center">
          <Text size="xsmall">
            <FormattedMessage {...messages.globalZoneDescription} />
          </Text>
        </Box>

        <Box gridArea="addGlobal">
          <Button
            color="focus"
            icon={clientGlobalZones.length > 0 ? <Edit size="small" /> : <Add size="small" />}
            label={
              clientGlobalZones.length > 0
                ? intl.formatMessage(messages.globalZoneButtonEditText)
                : intl.formatMessage(messages.globalZoneButtonAddText)
            }
            onClick={openGlobalZonesSidebar}
          />
        </Box>

        {clientGlobalZones && clientGlobalZones.length > 0 && (
          <Box gridArea="locationTable">
            <LocationsTable global locations={clientGlobalZones} />
          </Box>
        )}
      </Grid>

      <Box direction="row-responsive" gap="small" justify="between">
        {/* Back */}
        <Button
          color="status-unknown"
          label={intl.formatMessage(messages.backButtonLabel)}
          onClick={saveAndBack}
          primary={false}
        />

        {/* Save & Continue */}
        <Button
          color="accent-1"
          label={intl.formatMessage(messages.continueButtonLabel)}
          onClick={saveLocationsAndContinue}
        />
      </Box>

      {!!showLocationSidebar && (
        <WizardSidebar
          disabled={loading}
          content={sidebarContent}
          header={locationSidebarHeader}
          isAbleToDelete={!!zoneFormState.exists}
          isFormValid={isFormValid}
          onClose={onClose}
          onDelete={onDelete}
          onSubmit={onSubmit}
        ></WizardSidebar>
      )}

      {!!showGlobalZonesSidebar && (
        <ClientGlobalZoneSidebar
          onClose={onGlobalZonesSidebarClose}
          onSubmitGlobalZones={onSubmitGlobalZones}
          agencyZones={zoneStroe.agencyZones}
          clientGlobalZones={clientGlobalZones}
          error={error}
          loading={loading}
        />
      )}
    </Box>
  )
})

const Divider = styled.div`
  margin-top: 20px;
  border: 0.5px solid lightGrey;
  width: 100%;
`

AddClientZone.propTypes = {
  initialTableDataState: PropTypes.array,
}

AddClientZone.defaultProps = {
  initialTableDataState: [],
}

export default injectIntl(AddClientZone)
