/* eslint-disable react/prop-types */
import React from 'react'

// Components
import { MaskedInput as GrommetMaskedInput } from 'grommet'

// Helpers
import { doc } from './MaskedInput.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * MaskedInput
 *
 */
const MaskedInput = ({ children, ...rest }) => (
  <GrommetMaskedInput {...rest}>{children}</GrommetMaskedInput>
)

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: MaskedInput,
})
