import React, { useState, useReducer, useEffect } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { PropTypes } from 'prop-types'

// Components
import { Box } from '../Box'
import { FormField } from '../Form'
import { Message } from '../Message'
import { PasswordInput } from '../PasswordInput'
import { WizardSidebar } from '../WizardSidebar'

// Utils, Services & Messages
import messages from './ChangePasswordSidebar.messages'
import useFlashMessage from '../../hooks/FlashMessage'
import { updateUserPassword } from '../../services/user.service'

const INITIAL_STATE = {
  old_password: '',
  new_password: '',
  confirm_new_password: '',
}

/**
 * ChangePasswordSidebar
 *
 * Sidebar component to display form to change the current user's password
 *
 * Displays form fields required for changing the user's password
 *
 * FIELD VALIDATION:
 * Required fields:
 * - current password
 * - new password
 * - confirm new password
 * Client-side validated fields:
 * - current password (required)
 * - new password (required, >8 characters)
 * - confirm new password (required, >8 characters)
 * Server-side validated fields:
 * - current password (must be correct)
 * - new password (additional requirements such as cannot be too common)
 *
 * BEHAVIOR:
 * - All fields are blank on render
 * - All fields are required
 * - new password and confirm new password must be >8 characters
 * - new password and confirm new password must match
 * - Display API errors
 * - Disable form and submit button while loading
 * - Close form on success, not on error
 */
const ChangePasswordSidebar = ({ onPasswordClose }) => {
  const [passwordSidebarState, passwordDispatch] = useReducer(sidebarReducer, INITIAL_STATE)
  const { message: error, showMessage: showError } = useFlashMessage(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (success === true) {
      onPasswordClose()
    }
  }, [success])

  function sidebarReducer(state, action) {
    if (action.type !== 'reset') {
      if (action.type === 'update') {
        return {
          ...state,
          ...action.data,
        }
      }
      return {
        ...state,
        [action.fieldName]: action.data,
      }
    }

    return INITIAL_STATE
  }

  const isPasswordFormValid =
    !!passwordSidebarState.old_password &&
    !!passwordSidebarState.new_password &&
    !!passwordSidebarState.confirm_new_password

  const onPasswordSubmit = () => {
    updateUserPassword(
      {
        old_password: passwordSidebarState.old_password,
        new_password: passwordSidebarState.new_password,
      },
      showError,
      setLoading,
      setSuccess,
    )
  }

  // Build Sidebar header & content
  const changePasswordSidebarHeader = (
    <FormattedMessage {...messages.changePasswordSidebarHeader} />
  )

  const changePasswordSidebarContent = (
    <Box>
      <FormField
        label="Current Password"
        name="old_password"
        onChange={e => passwordDispatch({ fieldName: 'old_password', data: e.target.value })}
        required
        validate={[
          password => {
            if (password && password.length <= 8) return 'Please enter more than 8 characters'
            return undefined
          },
          password => {
            const confirmPasswordInput = document.getElementsByName('confirm_password')[0]
            if (
              password &&
              confirmPasswordInput instanceof HTMLInputElement &&
              confirmPasswordInput.value !== password
            ) {
              return 'Passwords must match'
            }
            return undefined
          },
        ]}
      >
        <PasswordInput id="old_password" name="old_password" />
      </FormField>

      <FormField
        label="New Password"
        name="new_password"
        onChange={e => passwordDispatch({ fieldName: 'new_password', data: e.target.value })}
        required
        validate={[
          password => {
            if (password && password.length <= 8) return 'Please enter more than 8 characters'
            return undefined
          },
        ]}
      >
        <PasswordInput id="new_password" name="new_password" />
      </FormField>

      <FormField
        label="Re-enter New Password"
        name="confirm_new_password"
        onChange={e =>
          passwordDispatch({ fieldName: 'confirm_new_password', data: e.target.value })
        }
        required
        validate={[
          confirmPassword => {
            if (confirmPassword && confirmPassword.length <= 8)
              return 'Please enter more than 8 characters'
            return undefined
          },
          confirmPassword => {
            const passwordInput = document.getElementsByName('new_password')[0]

            if (
              confirmPassword &&
              passwordInput instanceof HTMLInputElement &&
              passwordInput.value !== confirmPassword
            ) {
              return 'Passwords must match'
            }
            return undefined
          },
        ]}
      >
        <PasswordInput name="confirm_new_password" />
      </FormField>
      <Box>{error && <Message message={error} isError />}</Box>
    </Box>
  )

  return (
    <WizardSidebar
      disabled={loading}
      content={changePasswordSidebarContent}
      header={changePasswordSidebarHeader}
      isFormValid={isPasswordFormValid}
      onClose={onPasswordClose}
      onSubmit={onPasswordSubmit}
      secondary
    />
  )
}

ChangePasswordSidebar.propTypes = {
  onPasswordClose: PropTypes.func,
}

export default injectIntl(ChangePasswordSidebar)
