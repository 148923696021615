import styled from 'styled-components'
import { FormField } from 'grommet'
import colors from '../../utils/colors'

const BORDER_RADIUS = '10px'

export const StyledFormField = styled(FormField)`
  input {
    border-radius: ${BORDER_RADIUS};
    color: ${colors.secondary};
    font-size: 14px;
    font-weight: bold;
    height: 35px;
    padding-left: 16px;
  }
  label + div {
    border-radius: ${BORDER_RADIUS};
    border: solid 1px #d9d9d9;
  }

  // Error message
  span {
    font-size: 12px;
  }

  // Field label
  label {
    margin: 0;
    font-size: 11px;
    font-weight: normal;

    // Field label for nested items, such as Selects
    span {
      font-weight: normal;
      padding-top: 0;
      font-size: 11px;
    }
  }
`
