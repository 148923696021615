/* eslint-disable react/prop-types */
import React from 'react'
import { Image } from 'grommet'

import { doc } from './Image.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * Image
 *
 */
function createImage({ ...rest }) {
  return <Image {...rest} />
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createImage,
})
