import { defineMessages } from 'react-intl'

export const scope = 'app.containers.ClientMonitoringPage'

/*
 * ClientMonitoringPage Messages
 *
 * This contains all the text for the ClientMonitoringPage container.
 */
export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
  datePickerHeader: {
    id: `${scope}.datePickerHeader`,
    defaultMessage: 'Filter Events By Date',
  },
})
