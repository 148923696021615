import { defineMessages } from 'react-intl'

export const scope = 'app.containers.ClientMessageLogPage'

/*
 * ClientMessageLogPage Messages
 *
 * This contains all the text for the ClientMessageLogPage container.
 */
export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
  datePickerHeader: {
    id: `${scope}.datePickerHeader`,
    defaultMessage: 'Filter Messages By Date',
  },
  cannedMessagePickerHeader: {
    id: `${scope}.cannedMessagePickerHeader`,
    defaultMessage: 'Select A Pre-Created Message',
  },
  questionnaireMessagePickerHeader: {
    id: `${scope}.questionnaireMessagePickerHeader`,
    defaultMessage: 'Select A Questionnaire',
  },
  onDemandChatMessagesHeader: {
    id: `${scope}.onDemandChatMessagesHeader`,
    defaultMessage: 'On Demand Chat Message',
  },
  onDemandChatMessagesDescription: {
    id: `${scope}.onDemandChatMessagesDescription`,
    defaultMessage: 'On Demand Chat Messages occur once immediately.',
  },
  scheduledChatMessagesHeader: {
    id: `${scope}.scheduledChatMessagesHeader`,
    defaultMessage: 'Scheduled Chat Messages',
  },
  scheduledChatMessagesDescription: {
    id: `${scope}.scheduledChatMessagesDescription`,
    defaultMessage: 'Schedule Chat Messages to occur once, reoccur multiple times, and repeat.',
  },
  sendChatMessageButtonLabel: {
    id: `${scope}.sendChatMessageButtonLabel`,
    defaultMessage: 'Send Chat Message',
  },
  scheduleChatMessageButtonLabel: {
    id: `${scope}.scheduleChatMessageButtonLabel`,
    defaultMessage: 'Schedule Chat Message',
  },
  scheduleSidebarHeaderAdd: {
    id: `${scope}.scheduleSidebarHeaderAdd`,
    defaultMessage: 'Add Scheduled Chat Message',
  },
  scheduleSidebarHeaderEdit: {
    id: `${scope}.scheduleSidebarHeaderEdit`,
    defaultMessage: 'Edit Scheduled Chat Message',
  },

  // side bar
  chatMessageNameHeader: {
    id: `${scope}.chatMessageNameHeader`,
    defaultMessage: 'Chat Message Details',
  },
  chatMessageNameLabel: {
    id: `${scope}.chatMessageNameLabel`,
    defaultMessage: 'Name',
  },
  chatMessageContentLabel: {
    id: `${scope}.chatMessageContentLabel`,
    defaultMessage: 'Message',
  },
  scheduleHeader: {
    id: `${scope}.scheduleHeader`,
    defaultMessage: 'Schedule',
  },
  scheduleDetails: {
    id: `${scope}.scheduleDetails`,
    defaultMessage: `Here you can schedule a Chat Message to occur once, or repeat. The start date for a Chat Message must be after the Client's monitoring start date.`,
  },
  backButtonLabel: {
    id: `${scope}.backButtonLabel`,
    defaultMessage: 'Back',
  },
  continueButtonLabel: {
    id: `${scope}.continueButtonLabel`,
    defaultMessage: 'Save & Continue',
  },
  closeButtonLabel: {
    id: `${scope}.closeButtonLabel`,
    defaultMessage: 'Save & Close',
  },
})
