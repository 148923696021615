import { defineMessages } from 'react-intl'

export const scope = 'app.containers.ClientWizard'

/*
 * ClientWizard Messages
 *
 * This contains all the text for the ClientWizard container.
 */
export default defineMessages({
  headerAdd: {
    id: `${scope}.headerAdd`,
    defaultMessage: 'Client / Add New',
  },
  headerEdit: {
    id: `${scope}.headerEdit`,
    defaultMessage: 'Client / Edit',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
})
