import { defineMessages } from 'react-intl'

export const scope = 'app.components.ChangePasswordSidebar'

/*
 * ChangePasswordSidebar Messages
 *
 * This contains all the text for the ChangePasswordSidebar component.
 */
export default defineMessages({
  changePasswordSidebarHeader: {
    id: `${scope}.changePasswordSidebarHeader`,
    defaultMessage: 'Change Your Password',
  },
})
