import { defineMessages } from 'react-intl'

export const scope = 'app.components.NavBar'

/*
 * NavBar Messages
 *
 * This contains all the text for the NavBar component.
 */
export default defineMessages({
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Dashboard',
  },
  vcheckreview: {
    id: `${scope}.vcheckreview`,
    defaultMessage: 'VCheck Review',
  },
  agencyinfo: {
    id: `${scope}.agencyinfo`,
    defaultMessage: 'Agency Info',
  },
  agents: {
    id: `${scope}.agents`,
    defaultMessage: 'Agents',
  },
  clients: {
    id: `${scope}.clients`,
    defaultMessage: 'Clients',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'Reports',
  },
  zones: {
    id: `${scope}.zones`,
    defaultMessage: 'Global Zones ',
  },
})
