/* eslint-disable react/prop-types */
import React from 'react'
import { TextArea } from 'grommet'
import { doc } from './TextArea.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * TextArea
 *
 * Displays a control to input multiple lines of text
 *
 * - When ..., it ...
 *
 */
const createTextArea = ({
  fill,
  focusIndicator,
  id,
  name,
  onChange,
  placeholder,
  plain,
  resize,
  size,
  value,
  ...rest
}) => (
  <TextArea
    fill={fill}
    focusIndicator={focusIndicator}
    id={id}
    name={name}
    onChange={onChange}
    placeholder={placeholder}
    plain={plain}
    resize={resize}
    size={size}
    value={value}
    {...rest}
  />
)

export default createWithDoc({
  docFunction: doc,
  component: createTextArea,
})
