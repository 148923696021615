import { describe, PropTypes } from 'react-desc'

import { genericProps } from '../../utils/propTypes'

const PAD_SIZES = ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge']
export const OVERFLOW_VALUES = ['auto', 'hidden', 'scroll', 'visible']

const overflowPropType = PropTypes.oneOfType([
  PropTypes.oneOf(OVERFLOW_VALUES),
  PropTypes.shape({
    horizontal: PropTypes.oneOf(OVERFLOW_VALUES),
    vertical: PropTypes.oneOf(OVERFLOW_VALUES),
  }),
  PropTypes.string,
])

export const doc = Nav => {
  const DocumentedNav = describe(Nav)
    .description('A Nav')
    .usage(
      `import { Nav } from 'components/Nav';
<Nav />`,
    )

  DocumentedNav.propTypes = {
    ...genericProps,
    align: PropTypes.oneOf(['start', 'center', 'end', 'baseline', 'stretch']).description(
      'How to align the contents along the cross axis.',
    ),
    alignContent: PropTypes.oneOf(['start', 'center', 'end', 'between', 'around', 'stretch'])
      .description(
        `How to align the contents when there is extra space in
          the cross axis.`,
      )
      .defaultValue('stretch'),
    background: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        color: PropTypes.string,
        dark: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        image: PropTypes.string,
        position: PropTypes.string,
        opacity: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.number,
          PropTypes.oneOf(['weak', 'medium', 'strong']),
        ]),
        repeat: PropTypes.oneOfType([PropTypes.oneOf(['no-repeat', 'repeat']), PropTypes.string]),
        size: PropTypes.oneOfType([PropTypes.oneOf(['cover', 'contain']), PropTypes.string]),
        light: PropTypes.string,
      }),
    ]).description(`Either a color identifier to use for the background
          color. For example: 'neutral-1'. Or, a 'url()' for an image. Dark
          is not needed if color is provided.`),
    basis: PropTypes.oneOfType([
      PropTypes.oneOf([
        'xxsmall',
        'xsmall',
        'small',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
        'full',
        '1/2',
        '1/3',
        '2/3',
        '1/4',
        '2/4',
        '3/4',
        'auto',
      ]),
      PropTypes.string,
    ]).description("A fixed or relative size along its container's main axis."),
    border: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['top', 'left', 'bottom', 'right', 'horizontal', 'vertical', 'all']),
      PropTypes.shape({
        color: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            dark: PropTypes.string,
            light: PropTypes.string,
          }),
        ]),
        side: PropTypes.oneOf(['top', 'left', 'bottom', 'right', 'horizontal', 'vertical', 'all']),
        size: PropTypes.oneOfType([
          PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
          PropTypes.string,
        ]),
        style: PropTypes.oneOf([
          'solid',
          'dashed',
          'dotted',
          'double',
          'groove',
          'ridge',
          'inset',
          'outset',
          'hidden',
        ]).defaultValue('solid'),
      }),
    ]).description('Include a border.'),
    direction: PropTypes.oneOf([
      'row',
      'column',
      'row-responsive',
      'row-reverse',
      'column-reverse',
    ])
      .description('The orientation to layout the child components in.')
      .defaultValue('column'),
    elevation: PropTypes.oneOfType([
      PropTypes.oneOf(['none', 'xsmall', 'small', 'medium', 'large', 'xlarge']),
      PropTypes.string,
    ])
      .description(
        `Elevated height above the underlying context, indicated
          via a drop shadow.`,
      )
      .defaultValue('none'),
    fill: PropTypes.oneOfType([
      PropTypes.oneOf(['horizontal', 'vertical']),
      PropTypes.bool,
    ]).description('Whether the width and/or height should fill the container.'),
    height: PropTypes.oneOfType([
      PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge']),
      PropTypes.string,
    ]).description('A fixed height.'),
    justify: PropTypes.oneOf(['around', 'between', 'center', 'end', 'evenly', 'start', 'stretch'])
      .description('How to align the contents along the main axis.')
      .defaultValue('stretch'),
    overflow: overflowPropType.description('box overflow.'),
    pad: PropTypes.oneOfType([
      PropTypes.oneOf(['none', ...PAD_SIZES]),
      PropTypes.shape({
        bottom: PropTypes.oneOfType([PropTypes.oneOf(PAD_SIZES), PropTypes.string]),
        horizontal: PropTypes.oneOfType([PropTypes.oneOf(PAD_SIZES), PropTypes.string]),
        left: PropTypes.oneOfType([PropTypes.oneOf(PAD_SIZES), PropTypes.string]),
        right: PropTypes.oneOfType([PropTypes.oneOf(PAD_SIZES), PropTypes.string]),
        top: PropTypes.oneOfType([PropTypes.oneOf(PAD_SIZES), PropTypes.string]),
        vertical: PropTypes.oneOfType([PropTypes.oneOf(PAD_SIZES), PropTypes.string]),
      }),
      PropTypes.string,
    ])
      .description(
        `The amount of padding around the box contents. An
          object can be specified to distinguish horizontal padding, vertical
          padding, and padding on a particular side of the box`,
      )
      .defaultValue('none'),
    responsive: PropTypes.bool
      .description(
        `Whether margin, pad, and border
        sizes should be scaled for mobile environments.`,
      )
      .defaultValue(true),
    round: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'full']),
      PropTypes.string,
      PropTypes.shape({
        corner: PropTypes.oneOf([
          'top',
          'left',
          'bottom',
          'right',
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right',
        ]),
        size: PropTypes.oneOfType([
          PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
          PropTypes.string,
        ]),
      }),
    ])
      .description('How much to round the corners.')
      .defaultValue(false),
    tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).description(
      `The DOM tag to use for the element. NOTE: This is deprecated in favor
  of indicating the DOM tag via the 'as' property.`,
    ),
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
      .description('The DOM tag or react component to use for the element.')
      .defaultValue('div'),
    width: PropTypes.oneOfType([
      PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge']),
      PropTypes.string,
    ]).description('A fixed width.'),
    wrap: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['reverse'])])
      .description(`Whether children can wrap if they can't all fit.`)
      .defaultValue(false),
  }

  return DocumentedNav
}
