import { defineMessages } from 'react-intl'

export const scope = 'app.containers.AddClientInfo'

/*
 * AddClientInfo Messages
 *
 * This contains all the text for the AddClientInfo container.
 */
export default defineMessages({
  nameDobHeader: {
    id: `${scope}.nameDobHeader`,
    defaultMessage: 'Name and DOB',
  },
  loading: {
    id: `${scope}.header`,
    defaultMessage: 'Loading client data..',
  },
  agencyNameLabel: {
    id: `${scope}.agencyNameLabel`,
    defaultMessage: 'Agency Name',
  },
  firstNameLabel: {
    id: `${scope}.firstNameLabel`,
    defaultMessage: 'First Name',
  },
  middleNameLabel: {
    id: `${scope}.middleNameLabel`,
    defaultMessage: 'Middle Name',
  },
  lastNameLabel: {
    id: `${scope}.lastNameLabel`,
    defaultMessage: 'Last Name',
  },
  dobLabel: {
    id: `${scope}.dobLabel`,
    defaultMessage: 'Date of Birth',
  },
  contactHeader: {
    id: `${scope}.contactHeader`,
    defaultMessage: 'Contact Information',
  },
  phoneLabel: {
    id: `${scope}.phoneLabel`,
    defaultMessage: 'Phone Number',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'Email Address',
  },
  monitoringHeader: {
    id: `${scope}.monitoringHeader`,
    defaultMessage: 'Monitoring Dates',
  },
  monitoringDescription: {
    id: `${scope}.monitoringDescription`,
    defaultMessage:
      'Monitoring start date cannot be changed once set. If set in the future, the client will not be activated before this date.',
  },
  startDateLabel: {
    id: `${scope}.startDateLabel`,
    defaultMessage: 'Start Date',
  },
  endDateLabel: {
    id: `${scope}.endDateLabel`,
    defaultMessage: 'End Date',
  },
  timezoneLabel: {
    id: `${scope}.timezoneLabel`,
    defaultMessage: 'Timezone',
  },
  caseSupervisionHeader: {
    id: `${scope}.caseSupervisionHeader`,
    defaultMessage: 'Case and Supervision',
  },
  caseSupervisionDescription: {
    id: `${scope}.caseSupervisionDescription`,
    defaultMessage: 'Please set the primary agent and any backup agents.',
  },
  backupAgentHelp: {
    id: `${scope}.backupAgentHelp`,
    defaultMessage: 'Add up to 3 backup agents.',
  },
  primaryAgentLabel: {
    id: `${scope}.primaryAgentLabel`,
    defaultMessage: 'Primary Agent',
  },
  backupAgentsLabel: {
    id: `${scope}.backupAgentsLabel`,
    defaultMessage: 'Backup Agents',
  },
  caseNumberLabel: {
    id: `${scope}.caseNumberLabel`,
    defaultMessage: 'Case Number',
  },
  supervisionLabel: {
    id: `${scope}.supervisionLabel`,
    defaultMessage: 'Supervision Type',
  },
  noActivityAlertHeader: {
    id: `${scope}.noActivityAlertHeader`,
    defaultMessage: 'Customized No GPS Alerts',
  },
  noActivityAlertLabel: {
    id: `${scope}.noActivityAlertLabel`,
    defaultMessage: 'Send alert after no GPS for',
  },
  noActivityAlertAndroidMessage: {
    id: `${scope}.noActivityAlertAndroidMessage`,
    defaultMessage:
      'Note: Due to the inherent battery saving features in most Android phones, we recommend setting the No GPS alerts to 6 hours or more for Android users.',
  },
  addAgentButtonLabel: {
    id: `${scope}.addAgentButtonLabel`,
    defaultMessage: 'Add Backup Agent',
  },
  backButtonLabel: {
    id: `${scope}.backButtonLabel`,
    defaultMessage: 'Back',
  },
  continueButtonLabel: {
    id: `${scope}.continueButtonLabel`,
    defaultMessage: 'Save & Continue',
  },
  autoReportHeader: {
    id: `${scope}.autoReportHeader`,
    defaultMessage: 'AUTO REPORTING',
  },
  activityReportSubHeader: {
    id: `${scope}.activityReportSubHeader`,
    defaultMessage: 'Client Activity Report',
  },
  alarmReportSubHeader: {
    id: `${scope}.alarmReportSubHeader`,
    defaultMessage: 'Client Alarm Summary Report',
  },
  noActivityReport: {
    id: `${scope}.noActivityReport`,
    defaultMessage: 'I would prefer not to receive activity reports for this client.',
  },
  dailyActivityReport: {
    id: `${scope}.dailyActivityReport`,
    defaultMessage: 'I would like to receive daily activity reports for this client.',
  },
  weeklyActivityReport: {
    id: `${scope}.weeklyActivityReport`,
    defaultMessage: 'I would like to receive weekly activity reports for this client.',
  },
  activityReportAdditionalEmail: {
    id: `${scope}.activityReportAdditionalEmail`,
    defaultMessage:
      'I would like the following email addresses to receive the activity report for this client  at the same frequency as the Primary Agent.',
  },
  alaramReportAdditionalEmail: {
    id: `${scope}.alaramReportAdditionalEmail`,
    defaultMessage:
      'I would like the following email addresses to receive the alarm summary reports for this client at the same frequency as \n \u00A0 \u00A0 the Primary Agent.',
  },
  dailyAlarmSummaryReport: {
    id: `${scope}.dailyAlarmSummaryReport`,
    defaultMessage: 'I would like to receive daily alarm summary reports for this client.',
  },
  weeklyAlarmSummaryReport: {
    id: `${scope}.weeklyAlaramSummaryReport`,
    defaultMessage: 'I would like to receive weekly alarm summary reports for this client.',
  },
  noAlarmReport: {
    id: `${scope}.noAlarmReport`,
    defaultMessage: 'I would prefer not to receive alarm reports for this client.',
  },
  reactivateOnClearingMonitoringEndDateModalMessage: {
    id: `${scope}.reactivateOnClearingMonitoringEndDateModalMessage`,
    defaultMessage: 'Clearing the monitoring end will reactivate the client. Press Ok to proceed',
  },
  cancelButton: {
    id: `${scope}.cancelButton`,
    defaultMessage: 'Cancel',
  },
  confirmModalHeader: {
    id: `${scope}.confirmModalHeader`,
    defaultMessage: 'Ok',
  },
  addEmailLabel: {
    id: `${scope}.addEmailLabel`,
    defaultMessage: 'Add Recipient',
  },
  enterEmailLabel: {
    id: `${scope}.addEmailLabel`,
    defaultMessage: 'Add additional email',
  },
  backupEmailHelp: {
    id: `${scope}.backupEmailHelp`,
    defaultMessage: 'Add up to three additional recipients for the reports.',
  },
  addLabel: {
    id: `${scope}.addLabel`,
    defaultMessage: 'Add',
  },
  notificatilemailLabel: {
    id: `${scope}.notificatilemailLabel`,
    defaultMessage: 'Notification Email List',
  },
})
