import React from 'react'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager'

// Styles
import colors from '../../utils/colors'

const defaultProps = {
  center: {
    lat: 40.73,
    lng: -73.93,
  },
  zoom: 14,
}
const GoogleMaps = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap ref={props.onMapLoaded} center={props.center} defaultZoom={defaultProps.zoom}>
    {props.enableDrawingmode && (
      <DrawingManager
        defaultDrawingMode={window.google.maps.drawing.OverlayType.CIRCLE}
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_TOP,
            drawingModes: [
              window.google.maps.drawing.OverlayType.CIRCLE,
              window.google.maps.drawing.OverlayType.POLYGON,
            ],
          },
          circleOptions: {
            fillColor: colors.primary,
            fillOpacity: 0.3,
            strokeWeight: 2,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
        onPolygonComplete={polygon => {
          props.onPolygonCompleted(polygon)
        }}
        onCircleComplete={circle => {
          props.onCircleCompleted(circle)
        }}
      />
    )}
  </GoogleMap>
))
export default GoogleMaps
