import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

// Components
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Header } from 'components/Header'
import { Text } from 'components/Text'

// Utils, Services & Messages
import { confirmAccountOrEmail } from '../../services/user.service'

// Stores
import { RootStoreContext } from '../../stores/RootStore'

const getURLParams = location => {
  const params = location && location.search ? location.search.replace('?token=', '') : ''
  let confirmToken = ''
  let uid = ''
  if (params.length > 0) {
    const paramsList = params.split('&uid=')
    ;[confirmToken, uid] = paramsList
  }

  return [confirmToken, uid]
}

/**
 *
 * ConfirmAccount
 *
 */
const ConfirmAccount = observer(({ history, location }) => {
  const [redirect, setRedirect] = useState(false)
  const [error, setError] = useState(null)

  const {
    clearStore,
    user: { isAuthenticated },
  } = useContext(RootStoreContext)

  const [confirmToken, uid] = getURLParams(location)

  /**
   * Allow the user's authenticated status to propogate from the store by running
   * when its value changes
   *
   * - If a user is already logged in, they cannot confirm an account
   * - If the confirmation token and UID are set from the URL params, attempt to
   * confirm the user's account
   */
  useEffect(() => {
    // Do not attempt to confirm if the user is logged in
    if (isAuthenticated) return

    if (confirmToken && uid) {
      confirmAccountOrEmail(confirmToken, uid)
        .then(() => {
          setRedirect(true)
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.message) {
            setError(err.response.data.message)
          } else {
            setError('An unknown error occurred.')
          }
        })
    } else {
      setError('Invalid confirmation URL.')
    }
  }, [isAuthenticated])

  return (
    <Box width={{ max: '500px' }} margin="auto">
      {redirect && <Redirect to={{ pathname: '/login', search: '?confirmed=true' }} />}

      {error && (
        <Box align="center">
          {error && (
            <>
              <Header level="3" color="brand">
                Error confirming account
              </Header>

              <Text>{error}</Text>
            </>
          )}

          <Box gap="small" direction="row" pad="large">
            <Button primary label="Login" onClick={() => history.push('/login')} />
            <Button primary label="Register" onClick={() => history.push('/register')} />
          </Box>
        </Box>
      )}

      {isAuthenticated && (
        <Box align="center">
          <Header level="3" color="brand">
            Cannot Confirm Account
          </Header>

          <Text>You cannot confirm a new account while you are logged in.</Text>
          <Text>Please log out and try again.</Text>

          <Box gap="small" direction="row" pad="medium">
            <Button primary label="Logout" onClick={() => clearStore()} />
          </Box>
        </Box>
      )}
    </Box>
  )
})

ConfirmAccount.propTypes = {
  history: PropTypes.object,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

export default ConfirmAccount
