import { defineMessages } from 'react-intl'

export const scope = 'app.components.DateRangePicker'

/*
 * DateRangePicker Messages
 *
 * This contains all the text for the DateRangePicker component.
 */
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the DateRangePicker component!',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'From:',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'To:',
  },
})
