/* eslint-disable import/no-cycle */
import { observable, action } from 'mobx'
import { createContext } from 'react'

import { BaseStore } from './BaseStore'
import {
  getAgenciesByDistributorId as getAgenciesByDistributorIdService,
  updateAgency,
  postAgency,
  getAgentsByDistributorId as getAgentsByDistributorIdService,
  deleteAgency,
  getClientStatusDetailByDistributorId as getClientStatusDetailByDistributorIdService,
  getClientsByDistributorService as getClientsByDistributorIdService,
  updateUserProfileByDistributorId as updateUserProfileByDistributorIdService,
  getUserDetailsByDistributorId as getUserDetailsByDistributorIdService,
  getDistributorsById as getDistributorListById,
} from '../services/distributor.service'

const INITIAL_STATE = {
  agencies: [],
  distributorZones: [],
  agencyNames: [],
  distributorAgents: [],
  clientStatusDetails: [],
  distributorClients: [],
  userDetails: [],
}

export class DistributorStore extends BaseStore {
  @observable agencies

  @observable distributorZones

  @observable agencyNames

  @observable distributorAgents

  @observable clientStatusDetails

  @observable distributorClients

  @action.bound
  async getAgenciesByDistributorId(
    distributorId,
    setError,
    setLoading,
    currentPage,
    rowsPerPage = -1,
    searchText,
  ) {
    const response = await getAgenciesByDistributorIdService(
      distributorId,
      setError,
      setLoading,
      currentPage,
      rowsPerPage,
      searchText,
    )
    if (response) {
      this.agencies = response.results
    }
    return response
  }

  @action.bound
  async postAgency(distributorId, agency, setError, setLoading) {
    const addedAgency = postAgency(distributorId, agency, setError, setLoading)
    if (addedAgency && addedAgency.message) {
      this.agencies.push(addedAgency)
    }
    return addedAgency
  }

  @action.bound
  async updateAgency(agencyId, agency, setError, setLoading) {
    const updatePatchAgency = { ...agency }
    delete updatePatchAgency.owner
    const addedAgency = updateAgency(agencyId, updatePatchAgency, setError, setLoading)
    if (addedAgency) {
      const updatedItems = this.agencies.map(item =>
        item.id === addedAgency.id ? addedAgency : item,
      )
      this.agencies = updatedItems
    }
    return addedAgency
  }

  @action.bound
  async deleteAgency(distributorId, agencyId, setLoading, setError) {
    const requestSuccess = await deleteAgency(distributorId, agencyId, setLoading, setError)

    if (requestSuccess) {
      const filteredAgencies = this.agencies.filter(item => item.id !== agencyId)
      this.agencies = filteredAgencies
      return false
    }
    return true
  }

  // Agents Page

  @action.bound
  async getAgentsByDistributorId(
    distributorId,
    setError,
    setLoading,
    currentPage,
    rowsPerPage,
    agencyId,
    status,
    queryFilter,
  ) {
    const response = await getAgentsByDistributorIdService(
      distributorId,
      setError,
      setLoading,
      currentPage,
      rowsPerPage,
      agencyId,
      status,
      queryFilter,
    )
    if (response) {
      this.distributorAgents = response.results
    }

    return response
  }

  @action.bound
  async getClientsBydistributorId(
    distributorId,
    setError,
    setLoading,
    currentPage = 1,
    rowsPerPage = -1,
    queryFilter = '',
    status = '',
    agentId,
    agencyId,
    customizeLoading = false,
  ) {
    const response = await getClientsByDistributorIdService(
      distributorId,
      setError,
      setLoading,
      currentPage,
      rowsPerPage,
      queryFilter,
      status,
      agentId,
      agencyId,
      customizeLoading,
    )
    this.distributorClients = response.results
    return response
  }

  @action.bound
  async getClientStatusDetailByDistributorId(
    distributorId,
    agencyId,
    setError,
    setLoading,
    limit = -1,
    currenPage = 1,
    name,
    customizeLoading = false,
  ) {
    const results = await getClientStatusDetailByDistributorIdService(
      distributorId,
      agencyId,
      setError,
      setLoading,
      limit,
      currenPage,
      name,
      customizeLoading,
    )
    this.clientStatusDetails = results
    return results
  }

  // get user profile

  @action.bound
  async getUserDetailsByDistributorId(distributorId, setError, setLoading) {
    const response = await getUserDetailsByDistributorIdService(
      distributorId,
      setError,
      setLoading,
    )
    this.userDetails = response
    return response
  }

  // update user profile

  @action.bound
  async updateUserProfileByDistributorId(distributorId, user, userId, setLoading, setError) {
    const updatedUser = await updateUserProfileByDistributorIdService(
      distributorId,
      user,
      userId,
      setLoading,
      setError,
    )
    if (updatedUser) {
      return false // sets error bool to false
    }
    return true // sets error bool to true if no updatedAgent
  }

  @action.bound
  async getDistributorListByDistributorId(distributorId, setLoading, setError) {
    const distributors = await getDistributorListById(distributorId, setLoading, setError)
  
    if (distributors.results) {
      return distributors.results
    }
    return null
  }
}

export const distributor = new DistributorStore(INITIAL_STATE)
export const DistributorStoreContext = createContext(distributor)
