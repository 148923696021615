import React, { useState, useEffect } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

// Components
import { Box } from '../Box'
import { DateInput } from '../DateInput'
import { FormField } from '../Form'
import { Grid } from '../Grid'
import { Header } from '../Header'
import { Select } from '../Select'
import { TextInput } from '../TextInput'
import { TimeInput } from '../TimeInput'

// Styles
import colors from '../../utils/colors'

// Utils, Services & Messages
import formatters from '../../utils/formatters'
import messages from './VCheckScheduler.messages'

/**
 * VCheckScheduler
 *
 * This is a reusable component for scheduling VChecks.
 *
 * NOTE: This component MUST be wrapped in a parent form! This is meant to be a
 * partial in order to be used more dynamically.
 *
 * User can select a start date and time, with repeat option.
 * Repeat options:
 * - Does Not Repeat
 * - Daily
 * - Custom (ex: every x days, specific days each week, monthly)
 *
 * If repeating, can choose when to end.
 * End options:
 * - Never
 * - Specfic Date
 * - Number of Occurrences
 *
 */
const VCheckScheduler = props => {
  const { intl, state, dispatch, isEndTimeEditable, lowerBoundDate } = props
  const mockRepeatOptions = ['Does Not Repeat', 'Daily', 'Custom']
  const [repeatOptions, setRepeatOptions] = useState(mockRepeatOptions)

  const mockFrequencyOptions = ['Day(s)', 'Week(s)', 'Month(s)']
  const [frequencyOptions, setFrequencyOptions] = useState(mockFrequencyOptions)

  const mockEndOptions = ['Never', 'Specific Date', 'Number of Occurrences']
  const [endsOnOptions, setEndsOnOptions] = useState(mockEndOptions)

  const weekdays = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su']
  const [checkedWeekdays, setCheckedWeekdays] = useState(state.weekdays || [])

  useEffect(() => {
    dispatch({ fieldName: 'weekdays', data: checkedWeekdays })
  }, [checkedWeekdays])

  const onCheck = async value => {
    if (!checkedWeekdays.includes(value)) {
      setCheckedWeekdays([...checkedWeekdays, value])
    } else {
      setCheckedWeekdays(checkedWeekdays.filter(item => item !== value))
    }
  }

  const setTimes = startTimeVal => {
    // set start time
    dispatch({
      fieldName: 'startTime',
      data: moment(startTimeVal, 'HH:mm').format('HH:mm'),
    })
    // if end time is disabled, set to 15 min after start time
    if (!isEndTimeEditable) {
      dispatch({
        fieldName: 'endTime',
        data: moment(startTimeVal, 'HH:mm')
          .add(15, 'minutes')
          .format('HH:mm'),
      })
    }
  }

  const {
    startDateLabel,
    startTimeLabel,
    endTimeLabel,
    repeatLabel,
    repeatEveryLabel,
    frequencyLabel,
    endsOnLabel,
    endDateLabel,
    occurrencesLabel,
  } = messages

  return (
    <Grid
      rows={['1']}
      columns={['3/8', '2/8', '3/8']}
      areas={[
        ['startDate', 'startTime', 'endTime'],
        ['repeat', 'every', 'frequency'],
        ['weekdays', 'weekdays', 'weekdays'],
        ['endsOn', 'endsOn', 'endChoice'],
      ]}
      gap="small"
      pad={{ top: 'small' }}
    >
      <Box gridArea="startDate">
        <FormField
          component={DateInput}
          name="start_date"
          id="start_date"
          min={lowerBoundDate} // no effect if lowerBoundDate not provided
          label={intl.formatMessage(startDateLabel)}
          onChange={e => dispatch({ fieldName: 'startDate', data: e.target.value })}
          onClick={() => {
            props.messagecallback(null)
          }}
          value={{
            value: formatters.formatToDateInputDisplayFormat(moment.utc(state.startDate)),
          }}
          required
        />
      </Box>

      <Box gridArea="startTime">
        <FormField
          component={TimeInput}
          name="start_time"
          id="start_time"
          label={intl.formatMessage(startTimeLabel)}
          onChange={e => setTimes(e.target.value)}
          onClick={() => {
            props.messagecallback(null)
          }}
          value={{ value: state.startTime }}
          required
        />
      </Box>

      <Box gridArea="endTime">
        <FormField name="end_time" label={intl.formatMessage(endTimeLabel)}>
          <TimeInput
            name="end_time"
            id="end_time"
            value={state.endTime}
            disabled={!isEndTimeEditable}
            onChange={e =>
              dispatch({
                fieldName: 'endTime',
                data: moment(e.target.value, 'HH:mm').format('HH:mm'),
              })
            }
          />
        </FormField>
      </Box>

      <Box gridArea="repeat">
        <FormField
          name="repeat"
          label={intl.formatMessage(repeatLabel)}
          value={{ value: state.repeat }}
          required
        >
          <Select
            plain
            size="small"
            name="repeat"
            id="repeat"
            width="small"
            options={repeatOptions}
            onChange={e => {
              dispatch({ fieldName: 'repeat', data: e.option })
            }}
            value={state.repeat}
            onClose={() => setRepeatOptions(mockRepeatOptions)}
            required
          ></Select>
        </FormField>
      </Box>

      {state.repeat && state.repeat === 'Custom' && (
        <>
          <Box gridArea="every">
            <FormField
              name="repeat_every"
              data-testid="repeat_every"
              label={intl.formatMessage(repeatEveryLabel)}
              onChange={e => {
                dispatch({ fieldName: 'repeatEvery', data: e.target.value })
              }}
              value={{ value: state.repeatEvery }}
            >
              <TextInput
                plain
                type="number"
                min="0"
                max="5"
                size="small"
                name="repeat_every"
                id="repeat_every"
                value={{ value: state.repeatEvery }}
              />
            </FormField>
          </Box>

          <Box gridArea="frequency">
            <FormField name="frequency" label={intl.formatMessage(frequencyLabel)}>
              <Select
                plain
                size="small"
                name="frequency"
                id="frequency"
                options={frequencyOptions}
                onChange={e => {
                  dispatch({ fieldName: 'frequency', data: e.option })
                }}
                value={state.frequency}
                onClose={() => setFrequencyOptions(mockFrequencyOptions)}
              ></Select>
            </FormField>
          </Box>
        </>
      )}

      {state.repeat && state.repeat === 'Custom' && state.frequency === 'Week(s)' && (
        <Box direction="column" gridArea="weekdays">
          <RepeatHeader level="4">
            <FormattedMessage {...messages.repeatDaysLabel} />
          </RepeatHeader>

          <Box direction="row">
            {weekdays.map(day => (
              <WeekdaySelect
                className={checkedWeekdays.includes(day) ? 'checked' : 'unchecked'}
                type="button"
                data-testid="weekday"
                key={day}
                onClick={() => onCheck(day)}
              >
                {day}
              </WeekdaySelect>
            ))}
          </Box>
        </Box>
      )}

      {state.repeat && state.repeat !== 'Does Not Repeat' && (
        <>
          <Box gridArea="endsOn">
            <FormField name="ends_on" label={intl.formatMessage(endsOnLabel)}>
              <Select
                plain
                size="small"
                name="ends_on"
                id="ends_on"
                data-testid="ends_on"
                options={endsOnOptions}
                onChange={e => {
                  dispatch({ fieldName: 'endsOn', data: e.option })
                }}
                value={state.endsOn}
                onClose={() => setEndsOnOptions(mockEndOptions)}
              ></Select>
            </FormField>
          </Box>

          <Box gridArea="endChoice">
            {state.endsOn === 'Specific Date' && (
              <FormField
                component={DateInput}
                name="end_date"
                id="end_date"
                label={intl.formatMessage(endDateLabel)}
                onChange={e => {
                  dispatch({ fieldName: 'endDate', data: e.target.value })
                }}
                value={{ value: state.endDate }}
                validate={[
                  () => {
                    const EndDate = state.endDate
                    if (EndDate && moment(EndDate).isBefore(moment().add(1, 'days'), 'day'))
                      return 'End date must be in the future'
                    return undefined
                  },
                ]}
              />
            )}
            {state.endsOn === 'Number of Occurrences' && (
              <FormField
                name="occurrences"
                label={intl.formatMessage(occurrencesLabel)}
                onChange={e => {
                  dispatch({ fieldName: 'occurrences', data: e.target.value })
                }}
                value={{ value: state.occurrences }}
              >
                <TextInput
                  plain
                  type="number"
                  min="0"
                  max="100"
                  name="occurrences"
                  id="occurrences"
                  value={{ value: state.occurrences }}
                />
              </FormField>
            )}
          </Box>
        </>
      )}
    </Grid>
  )
}

const RepeatHeader = styled(Header)`
  font-size: 11px;
  font-weight: normal;
  margin: 0;
  padding: 0;
`

const WeekdaySelect = styled.button`
  background-color: transparent;
  border-radius: 5px;
  border: solid 2px ${colors.secondary};
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  margin-right: 5px;
  padding: 5px;
  width: 30px;
  &.checked {
    background-color: ${colors.secondary};
    color: white;
  }
`

VCheckScheduler.propTypes = {
  intl: intlShape.isRequired,
  state: PropTypes.any,
  dispatch: PropTypes.func,
  isEndTimeEditable: PropTypes.bool,
  lowerBoundDate: PropTypes.string,
  messagecallback: PropTypes.func,
}

export default injectIntl(VCheckScheduler)
