import React, { useState } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Close } from 'grommet-icons'
import { Layer } from 'grommet'
import PropTypes from 'prop-types'

// Components
import { Anchor } from '../Anchor'
import { Box } from '../Box'
import { Button } from '../Button'
import { Form } from '../Form'
import { Header } from '../Header'
import { Text } from '../Text'

// Utils, Services & Messages
import messages from './WizardSidebar.messages'

const { saveButton, deleteButton } = messages

const FOOTER_HEIGHT = 70

/**
 * WizardSidebar
 */
const WizardSidebar = ({
  content,
  disabled = false,
  header,
  intl,
  isAbleToDelete,
  isFormValid,
  onClose,
  onDelete,
  deleteCopy,
  onSubmit,
  secondary,
}) => {
  const [show, setShow] = useState(true)

  const closeSidebar = () => {
    setShow(false)
    onClose()
  }

  return (
    <Box fill align="center" justify="center">
      {show && (
        <Layer
          position="right"
          full="vertical"
          modal
          onEsc={closeSidebar}
          onClickOutside={closeSidebar}
          style={{ width: secondary ? '480px' : 'inherit' }}
        >
          <Box flex={false} direction="row" justify="between" align="center">
            <Header level={4} margin={{ vertical: 'none', horizontal: 'medium' }}>
              {header}
            </Header>

            <Button
              color="transparent"
              id="close-button"
              data-testid="close-button"
              icon={<Close />}
              onClick={closeSidebar}
            />
          </Box>

          <Form disabled={disabled} validate="blur" onSubmit={onSubmit}>
            <Box
              fill="vertical"
              overflow="scroll"
              pad={{ horizontal: 'medium' }}
              // Calculate the height of the scrollable area minus the sidebar header and footer
              style={{ height: `calc(100vh - 96px - ${FOOTER_HEIGHT}px)` }}
              width="mediumPlusHoriz"
            >
              {content}
            </Box>

            <Box
              align="center"
              direction="row-responsive"
              gap="small"
              pad={{ horizontal: 'medium' }}
              height={`${FOOTER_HEIGHT}px`}
              justify="end"
              margin="none"
            >
              {isAbleToDelete && (
                <Anchor id="delete-button" color="status-error" onClick={onDelete}>
                  <Text underline size="small">
                    {deleteCopy
                      ? intl.formatMessage(deleteCopy)
                      : intl.formatMessage(deleteButton)}
                  </Text>
                </Anchor>
              )}
              <Button
                color="accent-1"
                disabled={!isFormValid || disabled}
                id="save-button"
                label={intl.formatMessage(saveButton)}
                width="100px"
                type="submit"
              />
            </Box>
          </Form>
        </Layer>
      )}
    </Box>
  )
}

WizardSidebar.propTypes = {
  content: PropTypes.any,
  disabled: PropTypes.bool,
  header: PropTypes.any,
  intl: intlShape.isRequired,
  isAbleToDelete: PropTypes.bool,
  isFormValid: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  deleteCopy: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
}

WizardSidebar.defaultProps = {
  isFormValid: true,
}

export default injectIntl(WizardSidebar)
