import React from 'react'
import { FormattedMessage } from 'react-intl'
import { orderBy } from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
// Components
import { TableHeader } from '../Table'
// Utils, Services & Messages
import messages from './MessageLogTable.messages'
import { getTablePaginationOptions } from '../../utils/helpers'
import { LoadingIndicator } from '../LoadingIndicator'

/**
 * MessageLogTable
 *
 *  Timezone Notes:
 *  - created datetime is displayed in client timezone because it is client-created
 *
 */
const MessageLogTable = ({
  loading,
  printable,
  clientChatMessages,
  clientTimezone,
  rowCount,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const orderedChatMessages = orderBy(clientChatMessages, ['received'], ['desc'])
  const convertToClientTimezone = time => time.tz(clientTimezone).format('MM/DD/YY hh:mm:ss A z')

  const dataTableHeaders = [
    {
      selector: 'id',
      name: '',
      omit: true,
    },
    {
      selector: 'source',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'received',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      format: row => {
        if (row.time) {
          return convertToClientTimezone(moment(row.time))
        }
        return 'Not Found'
      },
      sortable: false,
      style: { 'font-size': 14 },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'sent_from',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      sortable: false,
      format: row => (
        <span style={{ 'white-space': 'pre-wrap' }}>{row.sent_from.replace(/,[s]*/g, ', ')}</span>
      ),
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'sent_to',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFour} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
      format: row => (
        <span style={{ 'white-space': 'pre-wrap' }}>{row.sent_to.replace(/,[s]*/g, ', ')}</span>
      ),
    },
    {
      selector: 'message_content',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderFive} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: {
        'font-size': 14,
        marginTop: 15,
        marginBottom: 15,
      },
      format: row => <span style={{ 'white-space': 'pre-wrap' }}>{row.message_content}</span>,
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
    {
      selector: 'view_status',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderSix} />
        </StyledTableHeader>
      ),
      format: row => {
        if (row.status) {
          return 'Viewed'
        }
        return 'Not Viewed'
      },
      sortable: false,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
      grow: printable ? 0.4 : 1,
      wrap: true,
    },
  ]

  const bodyData = []

  orderedChatMessages.forEach((chatMessage, index) => {
    const chatMessageRowObject = {
      id: index + 1,
      time: chatMessage.received,
      status: chatMessage.view_status,
      ...chatMessage,
    }
    bodyData.push(chatMessageRowObject)
  })

  const fieldProps = {}

  if (!printable) {
    fieldProps.progressPending = loading
    fieldProps.progressComponent = <LoadingIndicator />
  }

  return (
    <>
      <StyledDataTable
        noHeader
        border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
        columns={dataTableHeaders}
        data={bodyData}
        sortable
        striped
        highlightOnHover
        pointerOnHover
        pagination={!printable}
        paginationServer
        paginationTotalRows={rowCount}
        paginationPerPage={50}
        paginationRowsPerPageOptions={getTablePaginationOptions(rowCount)}
        onChangeRowsPerPage={e => handleRowsPerPageChange(e)}
        onChangePage={e => handlePageChange(e)}
        persistTableHead
        {...fieldProps}
      />
    </>
  )
}

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  nav > div > select {
    padding-right: 24px;
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`

MessageLogTable.propTypes = {
  loading: PropTypes.bool,
  printable: PropTypes.bool,
  clientChatMessages: PropTypes.array,
  clientTimezone: PropTypes.string,
  setCustomCenter: PropTypes.func,
  rowCount: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
}

export default MessageLogTable
