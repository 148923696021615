/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from 'grommet'

import { doc } from './Button.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * Button.js
 *
 * A common button
 */
function createButton({
  a11yTitle,
  active,
  alignSelf,
  as,
  color,
  disabled,
  fill,
  focusIndicator,
  gap,
  gridArea,
  hoverIndicator,
  href,
  icon,
  margin,
  onClick,
  plain,
  primary = true,
  reverse,
  style,
  target,
  title,
  type,
  enableBorder = false,
  ...rest
}) {
  return (
    <Button
      a11yTitle={a11yTitle}
      active={active}
      alignSelf={alignSelf}
      as={as}
      color={color}
      disabled={disabled}
      fill={fill}
      focusIndicator={focusIndicator}
      gap={gap}
      gridArea={gridArea}
      hoverIndicator={hoverIndicator}
      href={href}
      icon={icon}
      label={title}
      margin={{ vertical: 'medium', ...margin }}
      onClick={onClick}
      plain={plain}
      primary={primary}
      reverse={reverse}
      style={{
        fontSize: 14,
        fontWeight: 'bold',
        border: enableBorder ? 'solid 3px #919191' : '',
        ...style,
      }}
      target={target}
      type={type}
      {...rest}
    />
  )
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createButton,
})
