import { defineMessages } from 'react-intl'

export const scope = 'app.containers.VerifyClient'

/*
 * VerifyClient Messages
 *
 * This contains all the text for the VerifyClient container.
 */
export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading Client Data...',
  },
  clientInfoHeader: {
    id: `${scope}.clientInfoHeader`,
    defaultMessage: 'Client',
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: 'Name',
  },
  dobLabel: {
    id: `${scope}.dobLabel`,
    defaultMessage: 'DOB',
  },
  phoneLabel: {
    id: `${scope}.phoneLabel`,
    defaultMessage: 'Phone',
  },
  monitoringStartLabel: {
    id: `${scope}.monitoringStartLabel`,
    defaultMessage: 'Monitoring Start',
  },
  monitoringEndLabel: {
    id: `${scope}.monitoringEndLabel`,
    defaultMessage: 'Monitoring End',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'Email',
  },
  agentLabel: {
    id: `${scope}.agentLabel`,
    defaultMessage: 'Primary Agent',
  },
  supervisionLabel: {
    id: `${scope}.supervisionLabel`,
    defaultMessage: 'Sup. Type',
  },
  timezoneLabel: {
    id: `${scope}.timezoneLabel`,
    defaultMessage: 'Timezone',
  },
  zonesLocationHeader: {
    id: `${scope}.zonesLocationHeader`,
    defaultMessage: 'Zones - Locations',
  },
  locationTableHeaderOne: {
    id: `${scope}.locationTableHeaderOne`,
    defaultMessage: 'Name',
  },
  locationTableHeaderTwo: {
    id: `${scope}.locationTableHeaderTwo`,
    defaultMessage: 'Address',
  },
  locationTableHeaderThree: {
    id: `${scope}.locationTableHeaderThree`,
    defaultMessage: 'Type',
  },
  zonesGlobalHeader: {
    id: `${scope}.zonesGlobalHeader`,
    defaultMessage: 'Zones - Global',
  },
  globalZoneTableHeaderOne: {
    id: `${scope}.globalZoneTableHeaderOne`,
    defaultMessage: 'Name',
  },
  globalZoneTableHeaderSpace: {
    id: `${scope}.globalZoneTableHeaderSpace`,
    defaultMessage: ' ',
  },
  globalZoneTableHeaderTwo: {
    id: `${scope}.globalZoneTableHeaderTwo`,
    defaultMessage: 'Type',
  },
  vCheckHeader: {
    id: `${scope}.vCheckHeader`,
    defaultMessage: 'VCheck Schedule',
  },
  randomVCheckHeader: {
    id: `${scope}.randomVCheckHeader`,
    defaultMessage: 'Random VChecks',
  },
  scheduledVCheckHeader: {
    id: `${scope}.scheduledVCheckHeader`,
    defaultMessage: 'Scheduled VChecks',
  },
  vCheckTableHeaderOne: {
    id: `${scope}.vCheckTableHeaderOne`,
    defaultMessage: 'Dates',
  },
  vCheckTableHeaderTwo: {
    id: `${scope}.vCheckTableHeaderTwo`,
    defaultMessage: 'Description',
  },
  verifyInitiateHeader: {
    id: `${scope}.verifyInitiateHeader`,
    defaultMessage: 'Verify and Initiate Program',
  },
  verifyInitiateDescriptionLineOne: {
    id: `${scope}.verifyInitiateDescriptionLineOne`,
    defaultMessage:
      'Once you’ve reviewed the client’s information and program details, you can save the client to complete enrollment into the program. ',
  },
  verifyInitiateDescriptionLineTwo: {
    id: `${scope}.verifyInitiateDescriptionLineTwo`,
    defaultMessage: 'This client will be activated on ',
  },
  sendLinkCheckboxLabel: {
    id: `${scope}.sendLinkCheckboxLabel`,
    defaultMessage: 'Send application link to Client',
  },
  sendLinkDescription: {
    id: `${scope}.sendLinkDescription`,
    defaultMessage:
      'Client will receive a unique link to download the VCheck mobile app on their smartphone. The link will be sent via text message to ',
  },
  optInOrOptOutDescription: {
    id: `${scope}.optInOrOptOutDescription`,
    defaultMessage: `To adhere to our SMS providers terms and conditions, \u2002we're required to add an opt-out link in the enrollment text message sent to the Client.  We recommend instructing Clients not to opt out.`,
  },
  backButtonLabel: {
    id: `${scope}.backButtonLabel`,
    defaultMessage: 'Back',
  },
  continueButtonLabel: {
    id: `${scope}.continueButtonLabel`,
    defaultMessage: 'Verify & Close',
  },
  locationsNullMessage: {
    id: `${scope}.locationsNullMessage`,
    defaultMessage: 'No locations added.',
  },
  globalZonesNullMessage: {
    id: `${scope}.globalZonesNullMessage`,
    defaultMessage: 'No global zones added.',
  },
  scheduledVCheckNullMessage: {
    id: `${scope}.scheduledVCheckNullMessage`,
    defaultMessage: 'No scheduled VChecks added.',
  },
  randomVCheckNullMessage: {
    id: `${scope}.randomVCheckNullMessage`,
    defaultMessage: 'No random VChecks added.',
  },
  tableTypeInclustion: {
    id: `${scope}.tableTypeInclustion`,
    defaultMessage: 'Inclusion Zone',
  },
  tableTypeExclustion: {
    id: `${scope}.tableTypeExclustion`,
    defaultMessage: 'Zone of Interest',
  },
  tableTypeNone: {
    id: `${scope}.tableTypeNone`,
    defaultMessage: 'NONE',
  },
})
