/* eslint-disable react/prop-types */
import React from 'react'
import { CheckBox } from 'grommet'

import { doc } from './Checkbox.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * Chexbox
 *
 */
function createCheckbox({ ...rest }) {
  return <CheckBox {...rest} />
}

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: createCheckbox,
})
