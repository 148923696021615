/* eslint-disable react/prop-types */
import React from 'react'

// Components
import { Collapsible as GrommetCollapsible } from 'grommet'

// Helpers
import { doc } from './Collapsible.doc'
import { createWithDoc } from '../../utils/helpers'

/**
 *
 * Collapsible
 *
 */
const Collapsible = ({ children, ...rest }) => (
  <GrommetCollapsible {...rest}>{children}</GrommetCollapsible>
)

export default createWithDoc({
  envName: process.env.NODE_ENV,
  docFunction: doc,
  component: Collapsible,
})
