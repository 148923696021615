import { action, computed, observable } from 'mobx'
import { ignore } from 'mobx-sync'
import { createContext } from 'react'

import { BaseStore } from './BaseStore'

export const INITIAL_STATE = {
  accessToken: '',
  error: '',
  isLoading: false,
  message: '',
  refreshToken: '',
  user: {},
  isDistributor: false,
  distributorId: 0,
}

export class UserStore extends BaseStore {
  @observable accessToken = INITIAL_STATE.accessToken

  @ignore
  @observable
  error = INITIAL_STATE.error

  @ignore
  @observable
  isLoading = INITIAL_STATE.isLoading

  @ignore
  @observable
  message = INITIAL_STATE.message

  @observable isDistributor = INITIAL_STATE.isDistributor

  @observable distributorId = INITIAL_STATE.distributorId

  @observable refreshToken = INITIAL_STATE.refreshToken

  @observable user = INITIAL_STATE.user

  @computed
  get isAuthenticated() {
    return this.accessToken !== ''
  }

  @action.bound
  setCurrentUser({ accessToken = '', refreshToken = '', user } = {}) {
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.user = user
    this.isDistributor = user.agencyId === undefined
    this.distributorId = user.distributorId
  }
}

export const user = new UserStore(INITIAL_STATE)
export const UserStoreContext = createContext(user)
