/* istanbul ignore file */
import colors from './colors'

// All fonts are the body style by default
export const BODY_FONT = 'Lato'
// The header font is applied directly in the Header component
export const HEADER_FONT = 'Montserrat'

const theme = {
  global: {
    size: {
      mediumPlusHoriz: '490px',
      mediumPlusVert: '300px',
    },
    font: {
      family: `${BODY_FONT}, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    },
    colors: {
      brand: colors.primary,
      focus: colors.secondary,
      'status-ok': colors.green,
      'status-critical': colors.error,
      'status-error': colors.error,
      'accent-1': colors.gold,
      'accent-2': colors.lightTeal,
      'accent-3': colors.darkerGreyBlue,
      'accent-4': colors.purple,
      selected: colors.secondary,
      placeholder: colors.primaryText,
    },
  },
  table: {
    header: {
      border: null,
    },
  },
  select: {
    options: {
      text: {
        size: '13px',
      },
    },
  },
  textArea: {
    extend: () => `
      font-size: 14px;
      font-weight: normal;
    `,
  },
}

export default theme
