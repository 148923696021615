import React from 'react'
import { User } from 'grommet-icons'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Image } from 'grommet'
import { Box } from '../Box'
import { LoadingIndicator } from '../LoadingIndicator'

const VideoMask = styled.div`
   {
    border-radius: 50%;
    height: ${props => props.size}px;
    overflow: hidden;
    width: ${props => props.size}px;
    position: relative;
  }
`

const VideoAvatar = styled.video`
   {
    pointer-events: none;
    width: ${props => props.size}px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`
const ImageAvatar = styled.image`
   {
    pointer-events: none;
    width: ${props => props.size}px;
    position: absolute;
    resize: both;
  }
`

/**
 * ClientAvatar
 */
const ClientAvatar = ({ file, size = 'large', type, loading }) => {
  let avatarSize
  switch (size) {
    case 'small':
      avatarSize = 40
      break
    case 'medium':
      avatarSize = 80
      break
    default:
      avatarSize = 150
  }

  if (loading) {
    return (
      <Box fill justify="center" align="center">
        <LoadingIndicator />
      </Box>
    )
  }

  return file ? (
    <VideoMask size={avatarSize}>
      {/* Need to provide t=5 so that video thumbnail displays in print preview */}
      {type === 'video' ? (
        <VideoAvatar size={avatarSize} preload="metadata">
          <source src={`${file}#t=1`} type="video/mp4" />
        </VideoAvatar>
      ) : (
        <ImageAvatar size={avatarSize} preload="metadata">
          <Image
            style={{
              height: '150px',
              width: '150px',
              borderRadius: '75px',
            }}
            fit="cover"
            src={`${file}`}
          />
        </ImageAvatar>
      )}
    </VideoMask>
  ) : (
    <User size={size} />
  )
}

ClientAvatar.propTypes = {
  file: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default ClientAvatar
