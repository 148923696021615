import createFlags from 'flag'

/**
 * Global Feature Flag Settings
 *
 * Note: If you are permanently turning on a section, remove the setting from here
 * and remove all flag usage for that section. Please don't simply turn the flag to
 * `true` and call it a day!
 *
 * Note: Prefer using one of Flag, useFlag, useFlags before using a value from this object directly
 */
export const flags = {
  pages: {
    reports: false,
    home: false,
  },
  features: {
    globalZones: false,
    caseNotes: false,
  },
}

export const { FlagsProvider, Flag, useFlag, useFlags } = createFlags(flags)
