import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '../Text'

/**
 * TextBlock
 */
const TextBlock = ({ multiLineString, fontSize }) => {
  if (multiLineString.includes('\n')) {
    const splitString = multiLineString.split('\n')
    return (
      <>
        {splitString.map((newLine, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text size={fontSize || 'small'} key={index}>
            {newLine}
          </Text>
        ))}
      </>
    )
  }
  return <Text size={fontSize || 'small'}>{multiLineString}</Text>
}

TextBlock.propTypes = {
  multiLineString: PropTypes.string,
  fontSize: PropTypes.string,
}

export default TextBlock
