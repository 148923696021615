import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import DataTable from 'react-data-table-component'

// Components
import { TableHeader } from '../Table'

// Utils, Services & Messages
import formatters from '../../utils/formatters'
import messages from './CaseNotesTable.messages'

/**
 * CaseNotesTable
 *
 * This component takes in the client's case notes array data and converts it into the
 * required format before passing to reusable PopulatedTable component
 *
 */
const CaseNotesTable = ({ notes = [], viewInSidebar, clientZone }) => {
  const dataTableHeaders = [
    {
      selector: 'timestamp',
      format: row => {
        if (row.timestamp) {
          return moment(row.timestamp)
            .tz(clientZone)
            .format('MM/DD/YY hh:mm A z')
        }
        return 'N/A'
      },
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderOne} />
        </StyledTableHeader>
      ),
      sortable: true,
      style: { 'font-size': 14 },
    },
    {
      selector: 'agent',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderTwo} />
        </StyledTableHeader>
      ),
      sortable: true,
      style: {
        'text-transform': 'capitalize',
        'font-size': 14,
      },
    },
    {
      selector: 'notes',
      name: (
        <StyledTableHeader>
          <FormattedMessage {...messages.tableHeaderThree} />
        </StyledTableHeader>
      ),
      sortable: false,
      style: { 'font-size': 14 },
      grow: 4,
    },
    {
      selector: 'note',
      omit: true,
    },
  ]

  const bodyData = []
  notes.forEach(note => {
    const noteRowObject = {
      timestamp: note.created,
      agent: note.created_by.user.first_name.concat(' ', note.created_by.user.last_name),
      notes: note.notes ? formatters.truncateString(note.notes, 150) : null,
      note,
    }
    bodyData.push(noteRowObject)
  })

  return (
    <StyledDataTable
      noHeader
      border={{ body: { side: 'horizontal', color: 'lightGrey' } }}
      columns={dataTableHeaders}
      data={bodyData}
      sortable
      striped
      highlightOnHover
      pointerOnHover
      onRowClicked={e => viewInSidebar(e.note)}
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[10, 25, 50]}
    />
  )
}

const StyledDataTable = styled(DataTable)`
  tbody {
    border: 1px solid #d9d9d9;
    div,
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
`
const StyledTableHeader = styled(TableHeader)`
  color: #666666;
  text-transform: capitalize;
`
CaseNotesTable.propTypes = {
  notes: PropTypes.array,
  viewInSidebar: PropTypes.func,
  pageCount: PropTypes.number,
  handlePageChange: PropTypes.func,
}

export default CaseNotesTable
