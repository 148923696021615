import React from 'react'
import { FormClose, StatusGood } from 'grommet-icons'
import PropTypes from 'prop-types'

// Components
import { Box } from '../Box'
import { Button } from '../Button'
import { Layer } from '../Layer'
import { Text } from '../Text'

const Notification = ({ position = 'top', message, onClose }) => (
  <Box fill align="center" justify="center">
    <Layer
      position={position}
      modal={false}
      margin={{ vertical: 'medium', horizontal: 'small' }}
      onEsc={onClose}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="large"
        justify="between"
        round="medium"
        elevation="medium"
        pad={{ vertical: 'small', horizontal: 'medium' }}
        background="accent-2"
      >
        <Box align="start" direction="row" gap="small">
          <StatusGood color="white" />
          <Text color="white">{message}</Text>
        </Box>

        <Button
          icon={<FormClose color="white" />}
          onClick={onClose}
          primary={false}
          plain
          margin={{ vertical: 'none' }}
        />
      </Box>
    </Layer>
  </Box>
)

Notification.propTypes = {
  position: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
}

export default Notification
