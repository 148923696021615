import React, { useState } from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'

// Components
import { AddGlobalZoneTable } from '../AddGlobalZoneTable'
import { Box } from '../Box'
import { Grid } from '../Grid'
import { Message } from '../Message'
import { TextBlock } from '../TextBlock'
import { WizardSidebar } from '../WizardSidebar'

// Utils, Services & Messages
import messages from './ClientGlobalZoneSidebar.messages'

/**
 * ClientGlobalZoneSidebar
 *
 * Displays agency zones is selectable table, keeps track of which are selected and
 * saves those to the store on submit
 *
 */
const ClientGlobalZoneSidebar = observer(
  ({ intl, onClose, onSubmitGlobalZones, agencyZones, clientGlobalZones, error, loading }) => {
    const [selectedZones, setSelectedZones] = useState([])

    const handleRowSelected = React.useCallback(selectedRows => {
      setSelectedZones(selectedRows)
    }, [])

    // Submit function
    const onSubmit = async () => {
      onSubmitGlobalZones(selectedZones)
    }

    // build sidebar header and content for global zones
    const globalZonesSidebarHeader = <FormattedMessage {...messages.globalZonesSidebarHeader} />
    const globalZonesSidebarContent = (
      <Grid
        columns={['1']}
        rows={['auto', 'auto']}
        areas={[['selectTable'], ['errorMessage']]}
        gap="small"
      >
        <Box gridArea="selectTable">
          <TextBlock
            fontSize="xsmall"
            multiLineString={intl.formatMessage(messages.globalZonesDetailsHeader)}
          ></TextBlock>

          <AddGlobalZoneTable
            agencyZones={agencyZones}
            clientGlobalZones={clientGlobalZones}
            handleSelectedRows={handleRowSelected}
          ></AddGlobalZoneTable>
        </Box>

        <Box gridArea="errorMessage">{error && <Message message={error} isError />}</Box>
      </Grid>
    )

    return (
      <WizardSidebar
        disabled={loading}
        onClose={onClose}
        onSubmit={onSubmit}
        header={globalZonesSidebarHeader}
        content={globalZonesSidebarContent}
      />
    )
  },
)

ClientGlobalZoneSidebar.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ClientGlobalZoneSidebar)
