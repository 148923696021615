import { defineMessages } from 'react-intl'

export const scope = 'app.containers.ClientSummaryPage'

/*
 * ClientSummaryPage Messages
 *
 * This contains all the text for the ClientSummaryPage container.
 */
export default defineMessages({
  loading: {
    id: `${scope}.header`,
    defaultMessage: 'Loading Client Data..',
  },
  addNote: {
    id: `${scope}.addNote`,
    defaultMessage: 'Add Note',
  },
  notesHeader: {
    id: `${scope}.notesHeader`,
    defaultMessage: 'Notes:',
  },
  confirmModalHeader: {
    id: `${scope}.confirmModalHeader`,
    defaultMessage: 'Are you sure?',
  },
  deactivateModalMessage: {
    id: `${scope}.deactivateModalMessage`,
    defaultMessage: 'Deactivating the client will stop all location tracking and VChecks.',
  },
  reactivateModalMessage: {
    id: `${scope}.reactivateModalMessage`,
    defaultMessage:
      'Reactivating the client will restart location tracking and VChecks and incur charges for each day the client remains active.',
  },
  cancelButton: {
    id: `${scope}.cancelButton`,
    defaultMessage: 'Cancel',
  },
  deactivateButton: {
    id: `${scope}.deactivateButton`,
    defaultMessage: 'Deactivate',
  },
  reactivateButton: {
    id: `${scope}.reactivateButton`,
    defaultMessage: 'Reactivate',
  },
})
