import React from 'react'
import { FormattedMessage } from 'react-intl'

// Components
import { Box } from 'components/Box'
import { Header } from 'components/Header'
import { Link } from 'components/Link'
import { Text } from 'components/Text'

// Messages
import messages from './NotFoundPage.messages'

/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */
export default function NotFound() {
  return (
    <Box align="center" justify="center">
      <Header level="3">
        <FormattedMessage {...messages.header} />
      </Header>

      <Link to="/">
        <Text size="small" weight="bold">
          Go back Home
        </Text>
      </Link>
    </Box>
  )
}
