import { isArray, isEqual, isObject, transform } from 'lodash'

const ERROR_DEFAULT = 'An error occurred. Please try again.'
const ERROR_NO_INTERNET = 'Request could not be made. Please check your internet connection.'

export const createWithDoc = ({ envName = '', docFunction = () => {}, component = '' }) => {
  let createComponentWithDoc
  if (envName !== 'production') {
    createComponentWithDoc = docFunction(component) // eslint-disable-line global-require
  }
  return createComponentWithDoc || component
}

export const initialPaginationConstants = Object.freeze({
  rows: 25,
  pageNumber: 1,
})

export const roleTypeOption = Object.freeze({
  superAdminUser: 1,
  distributorUser: 2,
  agencyUser: 3,
})

export const getErrorMessage = err => {
  // - Axios does not return a response object if the service cannot be reached
  if (!err.response) {
    return ERROR_NO_INTERNET
  }

  /**
   * - If we do have a response and data object, use the full error object
   * -- Django error messages have a standard format for field errors:
   *
   * For example, both are possible error message formats:
   * {
   *     "invited_artists": [
   *         {
   *             "email": [
   *                 "artist with this email already exists."
   *             ]
   *         }
   *     ]
   * }
   *
   * Or:
   * {
   *     "invited_artists": [
   *         "artist with this email already exists."
   *     ]
   * }
   */
  if (err.response && err.response.data.owner && isObject(err.response.data.owner)) {
    try {
      const errorStringOrObject = Object.values(err.response.data.owner)
      if (isObject(errorStringOrObject)) {
        const childError = errorStringOrObject[0]
        if (isObject(childError)) {
          return Object.values(childError)[0]
        }
        if (childError) {
          return childError
        }
        return Object.values(errorStringOrObject)[0][0]
      }
      return errorStringOrObject
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not infer error message from error response object')
      return 'An unknown error occurred'
    }
  }

  if (err.response && err.response.data && isObject(err.response.data)) {
    try {
      const errorStringOrObject = Object.values(err.response.data)[0]
      if (isObject(errorStringOrObject)) {
        const childError = errorStringOrObject[0]
        if (isObject(childError)) {
          return Object.values(childError)[0][0]
        }
        if (childError) {
          return childError
        }
        return Object.values(errorStringOrObject)[0][0]
      }
      return errorStringOrObject
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not infer error message from error response object')
      return 'An unknown error occurred'
    }
  }

  // If a message has not been set, fallback to our default message
  return ERROR_DEFAULT
}

export const getCurrentDateWithOutMilliSeconds = () => {
  const date = new Date()
  date.setMilliseconds(0)
  const dateISOString = date.toISOString().replace('.000Z', 'Z')
  return dateISOString
}

/**
 * Deep diff between two objects, using lodash
 *
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object that represent the diff
 */
export const difference = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value
    }
  })

/**
 * Serializes a point
 *
 * @param {array} point Array of the form [lng, lat]
 * @return {Object}     Return a new object of the form { lat, lng }
 */
export const getCoordinateObject = point => {
  if (!isArray(point)) return undefined

  const [lng, lat] = point
  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  }
}

export const getDateDifference = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const getTablePaginationOptions = rowCount => {
  const paginationOptions = []
  if (rowCount >= 25) {
    paginationOptions.push(25)
  }
  if (rowCount >= 50) {
    paginationOptions.push(50)
  }
  if (rowCount >= 100) {
    paginationOptions.push(100)
  }
  if (rowCount >= 250) {
    paginationOptions.push(250)
  }
  if (rowCount >= 1000) {
    paginationOptions.push(1000)
  }
  if (rowCount < 1000) paginationOptions.push(rowCount)
  // paginationOptions.push(rowCount) // VCK-695 confirmation required
  return paginationOptions
}
