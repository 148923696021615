import { defineMessages } from 'react-intl'

export const scope = 'app.components.PopulatedTable'

/*
 * PopulatedTable Messages
 *
 * This contains all the text for the PopulatedTable component.
 */
export default defineMessages({
  noDataMessage: {
    id: `${scope}.noDataMessage`,
    defaultMessage: 'No Data Available',
  },
})
