import { describe, PropTypes } from 'react-desc'

export const doc = MaskedInput => {
  const DocumentedMaskedInput = describe(MaskedInput)
    .description('A MaskedInput')
    .usage(
      `import { MaskedInput } from 'components/MaskedInput';
<MaskedInput />`,
    )

  DocumentedMaskedInput.propTypes = {
    id: PropTypes.string
      .description('The DOM id attribute value to use for the element.')
      .defaultValue(false),
  }

  return DocumentedMaskedInput
}
